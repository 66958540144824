import { sendRecordRequest } from "../utils/messaging"
import { Box, Button, SxProps, Theme } from "@mui/material"
import { ProjectMenu } from "../projects/ProjectMenu"
import { useSelector } from "react-redux"
import { appSelector, recorderActive } from "../store/slices/app-slice"
import { useHistory } from "react-router-dom"
import { Project } from "../projects/project"
import { useAppDispatch } from "../store/store"
import { type History } from "history"
import RoutePaths from "../constants/Routes"
import { PlusCircle } from "@phosphor-icons/react"

const containerStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "trustiinGrey.300",
    height: "75px",
    ml: "100px",
}

const menuContainerStyle: SxProps<Theme> = {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
}

function addTest(history: History<unknown>, selectedProject: Project) {
    sendRecordRequest(selectedProject)
    history.push(RoutePaths.TEST_DETAILS)
}

export const Header = () => {
    const { selectedProject, recorderRunning, recorderEnabled } = useSelector(appSelector)
    const history = useHistory()
    const dispatch = useAppDispatch()

    const createNewTest = () => {
        if (!recorderRunning && selectedProject) {
            dispatch(recorderActive(true))
            addTest(history, selectedProject)
        }
    }

    return (
        <Box
            sx={{
                ...containerStyle,
                height: "95px",
                width: "calc(100vw - 100px)",
                bgcolor: "trustiinGrey.200",
                border: 0,
                flexGrow: 1,
                padding: "1rem",
                position: "fixed",
                zIndex: 1,
                top: "5px",
                paddingTop: "20px",
                mb: 2,
            }}
        >
            <Box
                sx={{
                    ...containerStyle,
                    marginLeft: 0,
                    flex: 1,
                    borderRadius: 2,
                    background: "white",
                }}
            >
                <Box sx={menuContainerStyle}>
                    <Box sx={{ flexGrow: 1, py: 2 }}>
                        <ProjectMenu />
                    </Box>
                    <Button
                        disabled={!recorderEnabled || recorderRunning}
                        onClick={() => createNewTest()}
                        variant="contained"
                        sx={{ marginRight: "2rem", whiteSpace: "nowrap" }}
                        startIcon={<PlusCircle size={22} />}
                    >
                        New Test
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
