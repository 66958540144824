import { useLocation } from "react-router-dom"
import queryString from "query-string"

export const useQueryParams = () => {
    const location = useLocation()

    const parseQuerySearch = () => {
        const parsedQuery = queryString.parse(location.search) as {
            [key: string]: string | string[]
        }

        const defaultValues = {
            browsers: [],
            environments: [],
            status: [],
        }

        const outputObject = {
            ...defaultValues,
            ...Object.keys(parsedQuery).reduce((acc, key) => {
                const value = parsedQuery[key]

                if (typeof value === "string") {
                    acc[key] = value.split(",").map((item) => item.trim())
                } else if (Array.isArray(value)) {
                    acc[key] = value.map((item) => (typeof item === "string" ? item.trim() : item))
                } else {
                    acc[key] = value
                }

                return acc
            }, {} as { [key: string]: string | string[] }),
        }
        return outputObject
    }

    return parseQuerySearch()
}

interface Params {
    [key: string]: string | undefined | null
}

export const useUrlString = () => {
    const location = useLocation()
    const updateUrlString = (params: Params) => {
        return queryString
            .stringifyUrl(
                {
                    url: location.pathname,
                    query: { ...queryString.parse(location.search), ...params },
                },
                { arrayFormat: "comma" },
            )
            .replace(/%2C/g, ",")
            .replace(/&?\w+=(?:&|$)/g, "")
    }

    return updateUrlString
}
