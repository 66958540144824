import { Box, Typography, Button } from "@mui/material"

interface NoPlansContentProps {
    handleOpen: () => void
}

const NoPlansContent = ({ handleOpen }: NoPlansContentProps) => {
    return (
        <Box display="flex" alignItems="center" sx={{ height: "70vh" }}>
            <Box>
                <Box>
                    <svg width="335" height="158" viewBox="0 0 335 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M264.036 138.12C234.486 138.12 234.486 146.12 204.926 146.12C175.366 146.12 175.366 138.12 145.816 138.12C116.266 138.12 116.256 146.12 86.7059 146.12L91.0359 128.01L115.376 111.68L135.046 65.1796L162.706 55.0096L192.706 28.3396L227.036 59.3396L235.036 92.3396L259.376 124.34L264.036 138.12Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.7"
                            d="M264.036 138.12C234.486 138.12 234.486 146.12 204.926 146.12C175.366 146.12 175.366 138.12 145.816 138.12C116.266 138.12 116.256 146.12 86.7059 146.12L91.0359 128.01L115.376 111.68L135.046 65.1796L162.706 55.0096L192.706 28.3396L227.036 59.3396L235.036 92.3396L259.376 124.34L264.036 138.12Z"
                            fill="white"
                        />
                        <path
                            opacity="0.8"
                            d="M135.046 65.1796L137.376 96.5096L160.376 70.3396L162.706 55.0096L135.046 65.1796Z"
                            fill="white"
                        />
                        <path
                            opacity="0.5"
                            d="M162.706 111.68L137.376 96.5096L160.376 70.3396H180.706L162.706 111.68Z"
                            fill="white"
                        />
                        <path
                            opacity="0.7"
                            d="M162.706 111.68L137.376 96.5096L126.376 107.01L118.876 127.18L162.706 111.68Z"
                            fill="white"
                        />
                        <path
                            opacity="0.4"
                            d="M162.706 111.68L184.836 111.03L165.266 131.23L118.876 127.18L162.706 111.68Z"
                            fill="white"
                        />
                        <path
                            opacity="0.2"
                            d="M206.486 122.92L184.836 111.03L170.566 125.76L199.706 135.9L206.486 122.92Z"
                            fill="white"
                        />
                        <path
                            opacity="0.6"
                            d="M162.706 111.68L196.156 96.5096L192.706 28.3396L180.706 70.3396L162.706 111.68Z"
                            fill="white"
                        />
                        <path
                            d="M162.706 111.68L196.156 96.5096L226.296 121.34L197.046 110.68L162.706 111.68Z"
                            fill="white"
                        />
                        <path
                            opacity="0.4"
                            d="M184.836 111.03L230.106 135.9L226.296 121.34L197.046 110.68L184.836 111.03Z"
                            fill="white"
                        />
                        <path
                            opacity="0.8"
                            d="M217.546 66.1796L194.186 57.6496L192.706 28.3396L227.036 59.3396L217.546 66.1796Z"
                            fill="white"
                        />
                        <path
                            d="M217.546 66.1796L194.186 57.6496L195.316 80.0096L230.106 84.9696L217.546 66.1796Z"
                            fill="white"
                        />
                        <path
                            opacity="0.3"
                            d="M226.296 121.34L196.156 96.5096L195.316 80.0096L230.106 84.9696L226.296 121.34Z"
                            fill="white"
                        />
                        <path
                            opacity="0.6"
                            d="M226.986 114.68L259.376 124.34L235.036 92.3396L230.106 84.9696L226.986 114.68Z"
                            fill="white"
                        />
                        <path
                            opacity="0.6"
                            d="M227.046 59.3396L217.546 66.1796L235.046 92.3396L227.046 59.3396Z"
                            fill="white"
                        />
                        <path
                            opacity="0.3"
                            d="M192.706 28.3396L162.706 55.0096L160.376 70.3396H180.706L192.706 28.3396Z"
                            fill="white"
                        />
                        <path
                            opacity="0.5"
                            d="M135.046 65.1796L115.376 111.68L118.876 127.18L126.376 107.01L137.376 96.5096L135.046 65.1796Z"
                            fill="white"
                        />
                        <path
                            opacity="0.8"
                            d="M91.0359 128.01L118.876 127.18L115.376 111.68L91.0359 128.01Z"
                            fill="white"
                        />
                        <path
                            d="M80.7859 122.41V122.16C85.4359 122.16 97.1359 117.72 97.1359 107.54H97.3759C97.3759 117.22 86.5859 122.37 80.7859 122.41Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M87.9158 127.61C87.7469 127.61 87.5796 127.576 87.4237 127.511C87.2677 127.446 87.1262 127.351 87.0072 127.231C86.8882 127.111 86.7941 126.969 86.7304 126.813C86.6667 126.656 86.6345 126.489 86.6358 126.32V124.32H78.0458V127.61H77.0958V124.47H76.2958C75.9643 124.47 75.6464 124.601 75.412 124.836C75.1775 125.07 75.0458 125.388 75.0458 125.72V128.72C75.0458 128.883 75.0782 129.045 75.1412 129.196C75.2041 129.348 75.2963 129.485 75.4126 129.6C75.5288 129.715 75.6666 129.806 75.8182 129.868C75.9698 129.93 76.1322 129.961 76.2958 129.96H76.4358V130.15H78.0458L86.6358 137.91H90.3758V129.43C90.3758 129.199 90.3303 128.971 90.2417 128.758C90.1531 128.545 90.0233 128.351 89.8597 128.189C89.6962 128.026 89.5021 127.897 89.2886 127.81C89.0751 127.723 88.8465 127.678 88.6158 127.68L87.9158 127.61Z"
                            fill="#263238"
                        />
                        <path
                            d="M70.2458 122.29V139.41H89.8259V122.29H70.2458ZM88.7859 128.55C88.7859 128.857 88.6636 129.152 88.4461 129.37C88.2286 129.587 87.9335 129.71 87.6259 129.71H76.3759C76.0682 129.71 75.7731 129.587 75.5556 129.37C75.3381 129.152 75.2159 128.857 75.2159 128.55V125.8C75.2159 125.492 75.3381 125.197 75.5556 124.979C75.7731 124.762 76.0682 124.64 76.3759 124.64H87.5859C87.8935 124.64 88.1886 124.762 88.4061 124.979C88.6236 125.197 88.7458 125.492 88.7458 125.8L88.7859 128.55Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M97.2658 124.87C97.0178 124.882 96.7832 124.986 96.6076 125.161C96.432 125.337 96.328 125.572 96.3158 125.82V140.75C96.3145 140.875 96.3382 140.999 96.3854 141.115C96.4327 141.231 96.5026 141.336 96.5911 141.424C96.6796 141.513 96.7849 141.583 96.9007 141.63C97.0166 141.677 97.1407 141.701 97.2658 141.7C97.5226 141.7 97.7697 141.601 97.9558 141.425C98.142 141.248 98.253 141.006 98.2658 140.75V125.82C98.253 125.563 98.142 125.321 97.9558 125.145C97.7697 124.968 97.5226 124.869 97.2658 124.87Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M97.2658 105.97C97.1757 105.97 97.0892 106.005 97.0254 106.069C96.9617 106.133 96.9258 106.219 96.9258 106.31V131.61C96.9258 131.7 96.9617 131.786 97.0254 131.85C97.0892 131.914 97.1757 131.95 97.2658 131.95C97.356 131.95 97.4425 131.914 97.5063 131.85C97.57 131.786 97.6058 131.7 97.6058 131.61V106.31C97.6058 106.219 97.57 106.133 97.5063 106.069C97.4425 106.005 97.356 105.97 97.2658 105.97Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M64.9059 121.83C64.8612 121.83 64.817 121.838 64.7757 121.855C64.7345 121.873 64.697 121.898 64.6654 121.929C64.6339 121.961 64.6088 121.998 64.5917 122.04C64.5746 122.081 64.5659 122.125 64.5659 122.17V139.53C64.5659 139.62 64.6017 139.706 64.6654 139.77C64.7292 139.834 64.8157 139.87 64.9059 139.87C64.996 139.87 65.0825 139.834 65.1463 139.77C65.21 139.706 65.2458 139.62 65.2458 139.53V122.17C65.2458 122.079 65.21 121.993 65.1463 121.929C65.0825 121.865 64.996 121.83 64.9059 121.83Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M84.3059 133.47C84.7568 133.47 85.1976 133.603 85.5726 133.854C85.9475 134.104 86.2397 134.46 86.4123 134.877C86.5849 135.294 86.63 135.752 86.5421 136.194C86.4541 136.637 86.2369 137.043 85.9181 137.362C85.5992 137.681 85.193 137.898 84.7507 137.986C84.3084 138.074 83.85 138.029 83.4334 137.856C83.0167 137.683 82.6607 137.391 82.4101 137.016C82.1596 136.641 82.0259 136.201 82.0259 135.75C82.0285 135.146 82.2696 134.567 82.6966 134.14C83.1236 133.713 83.702 133.472 84.3059 133.47ZM84.3059 132.47C83.6571 132.47 83.023 132.662 82.4836 133.022C81.9442 133.383 81.5238 133.895 81.2756 134.494C81.0273 135.094 80.9623 135.753 81.0889 136.39C81.2155 137.026 81.5278 137.61 81.9866 138.069C82.4453 138.528 83.0297 138.84 83.666 138.967C84.3022 139.093 84.9617 139.028 85.5611 138.78C86.1604 138.532 86.6727 138.111 87.0331 137.572C87.3935 137.032 87.5859 136.398 87.5859 135.75C87.5859 134.88 87.2403 134.045 86.6252 133.43C86.0101 132.815 85.1758 132.47 84.3059 132.47Z"
                            fill="white"
                        />
                        <path
                            opacity="0.4"
                            d="M87.6258 124.64C87.9335 124.64 88.2285 124.762 88.4461 124.979C88.6636 125.197 88.7858 125.492 88.7858 125.8V128.55C88.7858 128.857 88.6636 129.152 88.4461 129.37C88.2285 129.587 87.9335 129.71 87.6258 129.71H76.3758C76.0682 129.71 75.7731 129.587 75.5556 129.37C75.338 129.152 75.2158 128.857 75.2158 128.55V125.8C75.2158 125.492 75.338 125.197 75.5556 124.979C75.7731 124.762 76.0682 124.64 76.3758 124.64H87.5858M87.5858 124.14H76.3758C75.9356 124.14 75.5133 124.315 75.202 124.626C74.8907 124.937 74.7158 125.359 74.7158 125.8V128.55C74.7158 128.99 74.8907 129.412 75.202 129.723C75.5133 130.035 75.9356 130.21 76.3758 130.21H87.5858C88.0261 130.21 88.4483 130.035 88.7596 129.723C89.0709 129.412 89.2458 128.99 89.2458 128.55V125.8C89.2458 125.359 89.0709 124.937 88.7596 124.626C88.4483 124.315 88.0261 124.14 87.5858 124.14Z"
                            fill="white"
                        />
                        <path opacity="0.4" d="M74.2159 122.29H70.2458V139.41H74.2159V122.29Z" fill="white" />
                        <path
                            d="M54.0458 133.23C60.9358 143.45 68.5758 146.12 90.0458 146.12C99.4958 146.12 113.716 143.45 113.716 133.23C109.776 136.01 102.426 139.06 88.7158 139.06C73.8258 139.06 66.4958 130.47 54.0458 133.23Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.4"
                            d="M54.0458 133.23C60.9358 143.45 68.5758 146.12 90.0458 146.12C99.4958 146.12 113.716 143.45 113.716 133.23C109.776 136.01 102.426 139.06 88.7158 139.06C73.8258 139.06 66.4958 130.47 54.0458 133.23Z"
                            fill="white"
                        />
                        <path
                            d="M88.7659 140.06C81.7059 140.06 76.2059 138.16 71.3659 136.49C65.6359 134.49 60.6859 132.8 54.2659 134.21C54.0073 134.266 53.7369 134.218 53.5138 134.076C53.2908 133.933 53.1333 133.708 53.0759 133.45C53.0464 133.321 53.0427 133.188 53.065 133.057C53.0873 132.927 53.1351 132.803 53.2058 132.691C53.2764 132.58 53.3685 132.483 53.4766 132.407C53.5848 132.332 53.7069 132.278 53.8359 132.25C60.8359 130.73 66.2559 132.61 72.0159 134.6C76.9459 136.3 82.0159 138.06 88.7659 138.06C102.286 138.06 109.486 134.99 113.136 132.41C113.244 132.333 113.366 132.279 113.495 132.251C113.624 132.222 113.758 132.219 113.888 132.242C114.018 132.266 114.143 132.314 114.254 132.386C114.365 132.458 114.461 132.551 114.536 132.66C114.688 132.876 114.748 133.144 114.703 133.404C114.658 133.665 114.511 133.897 114.296 134.05C108.626 138.04 100.036 140.06 88.7659 140.06Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M90.0658 122.83H70.0058C69.8626 122.83 69.7253 122.773 69.624 122.671C69.5227 122.57 69.4658 122.433 69.4658 122.29C69.4658 122.146 69.5227 122.009 69.624 121.908C69.7253 121.807 69.8626 121.75 70.0058 121.75H90.0658C90.209 121.75 90.3464 121.807 90.4477 121.908C90.5489 122.009 90.6058 122.146 90.6058 122.29C90.6058 122.433 90.5489 122.57 90.4477 122.671C90.3464 122.773 90.209 122.83 90.0658 122.83Z"
                            fill="#263238"
                        />
                        <path
                            d="M98.6859 107.12H95.8459C95.8232 107.121 95.8004 107.118 95.7791 107.11C95.7578 107.102 95.7385 107.089 95.7224 107.073C95.7063 107.057 95.6938 107.038 95.6858 107.016C95.6778 106.995 95.6744 106.972 95.6759 106.95C95.6758 106.928 95.6803 106.906 95.6889 106.886C95.6976 106.866 95.7103 106.848 95.7262 106.833C95.7421 106.818 95.761 106.806 95.7816 106.799C95.8022 106.791 95.824 106.788 95.8459 106.79H98.6859C98.7293 106.79 98.7711 106.806 98.8027 106.836C98.8343 106.866 98.8533 106.906 98.8559 106.95C98.8559 106.995 98.838 107.038 98.8061 107.07C98.7742 107.102 98.731 107.12 98.6859 107.12Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M106.516 135.99C104.472 135.942 102.512 135.17 100.986 133.81C98.6159 131.81 97.1359 128.81 97.1359 127.17H97.3759C97.3759 128.74 98.8159 131.65 101.136 133.62C102.022 134.42 103.074 135.015 104.217 135.36C105.36 135.706 106.565 135.795 107.746 135.62V135.87C107.339 135.941 106.928 135.981 106.516 135.99Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M323.156 146.62C313.113 146.818 303.099 145.468 293.466 142.62C283.919 139.785 273.993 138.436 264.036 138.62C254.082 138.437 244.159 139.786 234.616 142.62C224.983 145.468 214.969 146.817 204.926 146.62C194.883 146.817 184.869 145.468 175.236 142.62C165.692 139.786 155.77 138.437 145.816 138.62C135.859 138.438 125.933 139.787 116.386 142.62C106.756 145.469 96.7458 146.818 86.7058 146.62C76.6658 146.818 66.6552 145.469 57.0258 142.62C47.4786 139.787 37.5528 138.438 27.5958 138.62C27.4632 138.62 27.336 138.567 27.2423 138.473C27.1485 138.379 27.0958 138.252 27.0958 138.12C27.0958 137.987 27.1485 137.86 27.2423 137.766C27.336 137.672 27.4632 137.62 27.5958 137.62C37.6391 137.422 47.653 138.771 57.2858 141.62C66.8294 144.453 76.7521 145.803 86.7058 145.62C96.6596 145.803 106.582 144.453 116.126 141.62C125.759 138.771 135.773 137.422 145.816 137.62C155.856 137.421 165.866 138.771 175.496 141.62C185.043 144.452 194.969 145.801 204.926 145.62C214.883 145.801 224.809 144.452 234.356 141.62C243.985 138.771 253.996 137.421 264.036 137.62C274.079 137.422 284.093 138.771 293.726 141.62C303.273 144.452 313.199 145.801 323.156 145.62C323.288 145.62 323.416 145.672 323.509 145.766C323.603 145.86 323.656 145.987 323.656 146.12C323.656 146.252 323.603 146.379 323.509 146.473C323.416 146.567 323.288 146.62 323.156 146.62Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M245.496 154.68C243.7 154.711 241.909 154.468 240.186 153.96C236.882 153.04 233.39 153.04 230.086 153.96C226.607 154.919 222.934 154.919 219.456 153.96C217.818 153.47 216.115 153.237 214.406 153.27C214.273 153.27 214.146 153.217 214.052 153.123C213.959 153.029 213.906 152.902 213.906 152.77C213.906 152.637 213.959 152.51 214.052 152.416C214.146 152.322 214.273 152.27 214.406 152.27C216.202 152.239 217.993 152.481 219.716 152.99C223.02 153.909 226.512 153.909 229.816 152.99C233.294 152.031 236.967 152.031 240.446 152.99C242.083 153.479 243.787 153.712 245.496 153.68C245.628 153.68 245.756 153.732 245.849 153.826C245.943 153.92 245.996 154.047 245.996 154.18C245.996 154.312 245.943 154.439 245.849 154.533C245.756 154.627 245.628 154.68 245.496 154.68Z"
                            fill="white"
                        />
                        <path
                            d="M122.426 157.13C121.506 157.148 120.589 157.027 119.706 156.77C118.094 156.316 116.388 156.316 114.776 156.77C113.893 157.028 112.976 157.149 112.056 157.13C111.136 157.148 110.219 157.027 109.336 156.77C108.539 156.529 107.709 156.414 106.876 156.43C106.743 156.43 106.616 156.377 106.522 156.283C106.429 156.189 106.376 156.062 106.376 155.93C106.376 155.797 106.429 155.67 106.522 155.576C106.616 155.482 106.743 155.43 106.876 155.43C107.796 155.413 108.713 155.538 109.596 155.8C111.206 156.24 112.905 156.24 114.516 155.8C116.299 155.306 118.183 155.306 119.966 155.8C120.764 156.036 121.594 156.147 122.426 156.13C122.558 156.132 122.683 156.186 122.777 156.279C122.87 156.372 122.923 156.498 122.926 156.63C122.926 156.762 122.873 156.889 122.779 156.983C122.686 157.077 122.558 157.13 122.426 157.13Z"
                            fill="white"
                        />
                        <path
                            d="M154.836 148.38C153.036 148.411 151.242 148.169 149.516 147.66C147.894 147.175 146.208 146.942 144.516 146.97C144.383 146.97 144.256 146.917 144.162 146.823C144.069 146.729 144.016 146.602 144.016 146.47C144.016 146.337 144.069 146.21 144.162 146.116C144.256 146.022 144.383 145.97 144.516 145.97C146.312 145.937 148.103 146.18 149.826 146.69C153.133 147.61 156.629 147.61 159.936 146.69C161.659 146.18 163.449 145.937 165.246 145.97C165.378 145.97 165.506 146.022 165.599 146.116C165.693 146.21 165.746 146.337 165.746 146.47C165.746 146.602 165.693 146.729 165.599 146.823C165.506 146.917 165.378 146.97 165.246 146.97C163.554 146.942 161.867 147.175 160.246 147.66C158.491 148.178 156.666 148.421 154.836 148.38Z"
                            fill="white"
                        />
                        <path
                            opacity="0.05"
                            d="M115.916 40.1396C124.808 40.1396 132.016 32.9314 132.016 24.0396C132.016 15.1478 124.808 7.93962 115.916 7.93962C107.024 7.93962 99.8159 15.1478 99.8159 24.0396C99.8159 32.9314 107.024 40.1396 115.916 40.1396Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.05"
                            d="M115.916 45.4996C127.768 45.4996 137.376 35.8917 137.376 24.0396C137.376 12.1876 127.768 2.57962 115.916 2.57962C104.064 2.57962 94.4559 12.1876 94.4559 24.0396C94.4559 35.8917 104.064 45.4996 115.916 45.4996Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M115.916 34.6996C121.803 34.6996 126.576 29.927 126.576 24.0396C126.576 18.1523 121.803 13.3796 115.916 13.3796C110.028 13.3796 105.256 18.1523 105.256 24.0396C105.256 29.927 110.028 34.6996 115.916 34.6996Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.8"
                            d="M115.916 34.6996C121.803 34.6996 126.576 29.927 126.576 24.0396C126.576 18.1523 121.803 13.3796 115.916 13.3796C110.028 13.3796 105.256 18.1523 105.256 24.0396C105.256 29.927 110.028 34.6996 115.916 34.6996Z"
                            fill="white"
                        />
                        <path
                            d="M88.3759 65.4696C88.0937 63.7686 87.319 62.1876 86.1478 60.9221C84.9765 59.6567 83.46 58.7623 81.7859 58.3496C81.2645 56.8942 80.4568 55.558 79.4104 54.42C78.3639 53.2819 77.1001 52.3652 75.6934 51.7238C74.2867 51.0825 72.7657 50.7296 71.2204 50.6859C69.675 50.6422 68.1365 50.9087 66.6959 51.4696C65.4494 48.7613 63.4607 46.4618 60.9605 44.8377C58.4602 43.2137 55.5508 42.3317 52.5696 42.294C49.5884 42.2563 46.6576 43.0645 44.1171 44.6248C41.5766 46.1851 39.5304 48.4337 38.2159 51.1096C35.507 50.3276 32.6059 50.5616 30.0574 51.7677C27.5089 52.9738 25.4886 55.069 24.3759 57.6596C23.0468 56.7858 21.506 56.2881 19.917 56.2193C18.3279 56.1505 16.7498 56.5132 15.3503 57.2688C13.9507 58.0245 12.7818 59.145 11.9676 60.5114C11.1534 61.8778 10.7243 63.4391 10.7259 65.0296C10.7256 66.1071 10.9256 67.1753 11.3159 68.1796C4.06588 70.0496 -2.20412 73.4696 0.745882 76.4696H97.4359C101.376 68.9496 94.5059 66.4696 88.3759 65.4696Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.7"
                            d="M88.3759 65.4696C88.0937 63.7686 87.319 62.1876 86.1478 60.9221C84.9765 59.6567 83.46 58.7623 81.7859 58.3496C81.2645 56.8942 80.4568 55.558 79.4104 54.42C78.3639 53.2819 77.1001 52.3652 75.6934 51.7238C74.2867 51.0825 72.7657 50.7296 71.2204 50.6859C69.675 50.6422 68.1365 50.9087 66.6959 51.4696C65.4494 48.7613 63.4607 46.4618 60.9605 44.8377C58.4602 43.2137 55.5508 42.3317 52.5696 42.294C49.5884 42.2563 46.6576 43.0645 44.1171 44.6248C41.5766 46.1851 39.5304 48.4337 38.2159 51.1096C35.507 50.3276 32.6059 50.5616 30.0574 51.7677C27.5089 52.9738 25.4886 55.069 24.3759 57.6596C23.0468 56.7858 21.506 56.2881 19.917 56.2193C18.3279 56.1505 16.7498 56.5132 15.3503 57.2688C13.9507 58.0245 12.7818 59.145 11.9676 60.5114C11.1534 61.8778 10.7243 63.4391 10.7259 65.0296C10.7256 66.1071 10.9256 67.1753 11.3159 68.1796C4.06588 70.0496 -2.20412 73.4696 0.745882 76.4696H97.4359C101.376 68.9496 94.5059 66.4696 88.3759 65.4696Z"
                            fill="white"
                        />
                        <path
                            d="M259.376 31.3296C259.463 30.8053 259.698 30.3165 260.052 29.92C260.406 29.5236 260.865 29.2356 261.376 29.0896C261.541 28.6315 261.797 28.2111 262.128 27.8532C262.458 27.4954 262.857 27.2073 263.3 27.006C263.744 26.8047 264.223 26.6942 264.71 26.6811C265.197 26.668 265.682 26.7525 266.136 26.9296C266.535 26.0791 267.165 25.3583 267.955 24.8496C268.745 24.3409 269.662 24.0651 270.601 24.0536C271.541 24.0421 272.464 24.2955 273.266 24.7847C274.068 25.2739 274.716 25.9791 275.136 26.8196C275.461 26.7253 275.798 26.6781 276.136 26.6796C276.849 26.6791 277.547 26.8883 278.143 27.2812C278.739 27.6741 279.206 28.2334 279.486 28.8896C279.905 28.6161 280.39 28.4606 280.89 28.4397C281.389 28.4188 281.886 28.5331 282.326 28.7707C282.766 29.0082 283.134 29.3602 283.391 29.7894C283.648 30.2187 283.785 30.7093 283.786 31.2096C283.785 31.5509 283.724 31.8895 283.606 32.2096C285.896 32.7996 287.876 33.8896 286.946 34.8096H256.486C255.246 32.4696 257.376 31.6596 259.376 31.3296Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.7"
                            d="M259.376 31.3296C259.463 30.8053 259.698 30.3165 260.052 29.92C260.406 29.5236 260.865 29.2356 261.376 29.0896C261.541 28.6315 261.797 28.2111 262.128 27.8532C262.458 27.4954 262.857 27.2073 263.3 27.006C263.744 26.8047 264.223 26.6942 264.71 26.6811C265.197 26.668 265.682 26.7525 266.136 26.9296C266.535 26.0791 267.165 25.3583 267.955 24.8496C268.745 24.3409 269.662 24.0651 270.601 24.0536C271.541 24.0421 272.464 24.2955 273.266 24.7847C274.068 25.2739 274.716 25.9791 275.136 26.8196C275.461 26.7253 275.798 26.6781 276.136 26.6796C276.849 26.6791 277.547 26.8883 278.143 27.2812C278.739 27.6741 279.206 28.2334 279.486 28.8896C279.905 28.6161 280.39 28.4606 280.89 28.4397C281.389 28.4188 281.886 28.5331 282.326 28.7707C282.766 29.0082 283.134 29.3602 283.391 29.7894C283.648 30.2187 283.785 30.7093 283.786 31.2096C283.785 31.5509 283.724 31.8895 283.606 32.2096C285.896 32.7996 287.876 33.8896 286.946 34.8096H256.486C255.246 32.4696 257.376 31.6596 259.376 31.3296Z"
                            fill="white"
                        />
                        <path
                            d="M326.596 88.5296C326.829 87.6511 326.818 86.7256 326.565 85.8527C326.311 84.9799 325.825 84.1926 325.157 83.5755C324.49 82.9585 323.667 82.5351 322.777 82.3508C321.887 82.1665 320.963 82.2283 320.106 82.5296C319.688 81.6365 319.079 80.8456 318.323 80.2126C317.566 79.5797 316.681 79.1198 315.728 78.8654C314.775 78.611 313.778 78.5681 312.807 78.7399C311.836 78.9117 310.914 79.2938 310.106 79.8596C309.149 78.5038 307.784 77.4882 306.211 76.9602C304.638 76.4321 302.937 76.4189 301.356 76.9226C299.774 77.4264 298.394 78.4207 297.416 79.7616C296.438 81.1024 295.913 82.72 295.916 84.3796C295.916 84.4696 295.916 84.5496 295.916 84.6396H295.716C294.713 84.6396 293.752 85.0379 293.043 85.7468C292.334 86.4556 291.936 87.4171 291.936 88.4196C291.938 89.0668 292.103 89.7029 292.416 90.2696C290.076 91.0996 288.416 92.2696 289.496 93.3196H331.006C332.806 89.9096 329.316 88.9196 326.596 88.5296Z"
                            fill="#9BB76A"
                        />
                        <path
                            opacity="0.7"
                            d="M326.596 88.5296C326.829 87.6511 326.818 86.7256 326.565 85.8527C326.311 84.9799 325.825 84.1926 325.157 83.5755C324.49 82.9585 323.667 82.5351 322.777 82.3508C321.887 82.1665 320.963 82.2283 320.106 82.5296C319.688 81.6365 319.079 80.8456 318.323 80.2126C317.566 79.5797 316.681 79.1198 315.728 78.8654C314.775 78.611 313.778 78.5681 312.807 78.7399C311.836 78.9117 310.914 79.2938 310.106 79.8596C309.149 78.5038 307.784 77.4882 306.211 76.9602C304.638 76.4321 302.937 76.4189 301.356 76.9226C299.774 77.4264 298.394 78.4207 297.416 79.7616C296.438 81.1024 295.913 82.72 295.916 84.3796C295.916 84.4696 295.916 84.5496 295.916 84.6396H295.716C294.713 84.6396 293.752 85.0379 293.043 85.7468C292.334 86.4556 291.936 87.4171 291.936 88.4196C291.938 89.0668 292.103 89.7029 292.416 90.2696C290.076 91.0996 288.416 92.2696 289.496 93.3196H331.006C332.806 89.9096 329.316 88.9196 326.596 88.5296Z"
                            fill="white"
                        />
                        <path
                            d="M239.026 3.46962C230.516 -2.20038 225.436 0.269617 223.026 2.53962C227.456 2.21962 235.846 2.71962 239.026 4.71962C242.336 3.44962 250.536 4.83962 254.876 6.14962C252.796 3.46962 248.066 -0.160383 239.026 3.46962Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M323.186 57.9196C317.306 53.9996 313.786 55.7096 312.186 57.2796C315.246 57.0596 321.056 57.3996 323.186 58.7896C325.486 57.9096 331.186 58.8696 334.186 59.7896C332.716 57.8596 329.446 55.3796 323.186 57.9196Z"
                            fill="#9BB76A"
                        />
                        <path
                            d="M56.6659 102.93C50.4759 100.37 47.5259 102.6 46.2959 104.36C49.0959 103.59 54.5559 102.84 56.8259 103.73C58.8259 102.5 64.2359 102.35 67.1959 102.64C65.5059 101.12 62.0059 99.4696 56.6659 102.93Z"
                            fill="#9BB76A"
                        />
                    </svg>
                </Box>
                <Typography sx={{ mb: 2 }}>This project has no test plans at the moment</Typography>
                <Button variant="outlined" onClick={handleOpen}>
                    NEW PLAN
                </Button>
            </Box>
        </Box>
    )
}

export default NoPlansContent
