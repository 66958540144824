import { z } from "zod"

// This is a custom error map for zod. Whenever zod validates and detects an error it
// uses this custom error map to determine what the default error message is.
// You usually don't want to use the default messages as they are not very user friendly.
export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (process.env.NODE_ENV !== "production") {
        // Console logs causing performance issues
        console.log(issue)
    }

    if (ctx.data === undefined || ctx.data === null || (typeof ctx.data === "string" && ctx.data.trim().length <= 0)) {
        return { message: "Required field." }
    }

    if (issue.code === "invalid_string" && issue.validation === "url") {
        return { message: "Invalid url." }
    }

    return { message: ctx.defaultError }
}
