const colorPalette = [
    "#D50000", // Red
    "#C2185B", // Pink
    "#7B1FA2", // Purple
    "#512DA8", // Deep Purple
    "#303F9F", // Indigo
    "#1976D2", // Blue
    "#0288D1", // Light Blue
    "#0097A7", // Cyan
    "#00796B", // Teal
    "#388E3C", // Green
    "#689F38", // Light Green
    "#AFB42B", // Lime
    "#FBC02D", // Yellow
    "#FFA000", // Amber
    "#F57C00", // Orange
    "#E64A19", // Deep Orange
    "#5D4037", // Brown
    "#616161", // Grey
    "#455A64", // Blue Grey
]

export const constrainLength = (text: string): string => {
    if (!text) {
        return ""
    }
    if (text.length > 100) {
        return text.substring(0, 100) + "..."
    }

    return text
}

export function stringToColor(string: string) {
    let hash = 0
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }
    const index = Math.abs(hash) % colorPalette.length // Map hash to index of colorPalette
    return colorPalette[index]
}

export function stringAvatar(name: string) {
    if (!name) return ""

    const initials = name
        .split(" ")
        .map((part) => part.charAt(0))
        .join("")

    return initials.toUpperCase()
}
