import Filter from "../../components/Filter"
import { FilterPropType } from "../../types/index"

const EnvironmentFilter = ({ options, projectId, setPage }: FilterPropType) => {
    return (
        <Filter projectId={projectId} options={options as string[]} setPage={setPage} label="Environments" fullWidth />
    )
}

export default EnvironmentFilter
