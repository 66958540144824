import React from "react"
import {
    Box,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Button,
    Typography,
    CircularProgress,
} from "@mui/material"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Grid from "@mui/material/Grid"
import UpsertProjectPage from "../projects/UpsertProjectPage"
import { history } from "../App"
import { Pageable } from "../pageable"
import { TestCase } from "../test-cases/test-case"
import { Project } from "../projects/project"
import { TestRunDTO, TestCaseResultDTO, getChipFromResultStatus } from "../test-results/test-result"
import { useDeleteProjectMutation, useUpdateProjectMutation } from "../store/api"
import BrowserIcon from "./BrowserIcon"
import { date, path, textEllipsis } from "../utils"
import RoutePaths from "../constants/Routes"
import TestDetailsTableLoader from "./TestDetailsTableLoader"
import { CustomSwitch } from "../components/CustomSwitch"
import ConfirmDelete from "../components/ConfirmDelete"
import { v4 as keyGen } from "uuid"
import { appSelector } from "../store/slices/app-slice"
import { useSelector } from "react-redux"
import { setDeletingProjects, getItemFromLocalStorage } from "../utils/accessLocalStorage"

type ComponentName = "TestCasesPage" | "UpdateProjectPage" | "TestPlanResultsListPage" | "TestPlanResultSummaryPage"

const testCaseTableStyle: SxProps<Theme> = {
    borderCollapse: "separate",
    borderSpacing: "0 .5rem",
    "& .MuiTableFooter-root .MuiTableCell-root": {
        borderBottom: "none",
    },
}

const testCaseBorderRadius = 5
const rowStyle: SxProps<Theme> = {
    borderWidth: 1,
    bgcolor: "common.white",
    "& td": {
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "trustiinGrey.300",
        borderLeftWidth: 0,
        borderRightWidth: 0,
        whiteSpace: "nowrap",
        "&:first-of-type p": {
            wordBreak: "break-word",
        },
    },
    "& td:first-of-type": {
        borderTopLeftRadius: testCaseBorderRadius,
        borderBottomLeftRadius: testCaseBorderRadius,
        borderLeftWidth: 1,
    },
    "& td:last-of-type": {
        borderTopRightRadius: testCaseBorderRadius,
        borderBottomRightRadius: testCaseBorderRadius,
        borderRightWidth: 1,
    },
    "&:hover, &:focus": {
        bgcolor: "white!important",
        cursor: "pointer",
        "& td": {
            borderColor: "primary.main",
        },
    },
    "&.noRowStyle:hover": {
        cursor: "default",
        "& td": {
            borderColor: "trustiinGrey.300",
        },
    },
}

const testCaseHeaderStyle: SxProps<Theme> = {
    "& th": {
        border: "none",
        py: 0,
        whiteSpace: "nowrap",
    },
}

const dividedCell: SxProps<Theme> = {
    p: 0,
    "& .MuiBox-root": {
        p: 2,
        height: "60px",
        borderTopStyle: "solid",
        borderTopWidth: "2px",
        borderTopColor: "trustiinGrey.300",
        "&:first-of-type": {
            borderTopColor: "#0000",
        },
    },
}

type ListViewPageProps = {
    testCases?: Pageable<TestCase>
    projects?: Pageable<Project>
    testResults?: Pageable<TestRunDTO>
    testRunResults?: Pageable<TestCaseResultDTO>
    testRuns?: Pageable<TestRunDTO>
    page: number
    setPage: (value: number) => void
    size: number
    setSize: (value: number) => void
    search?: string
    error?: unknown
    refetch?: () => void
    componentName: ComponentName

    isLoading: boolean
    updateProject?: ReturnType<typeof useUpdateProjectMutation>[0]

    open?: boolean

    setOpen?: (value: boolean) => void
    headers?: React.ReactNode[]
    headerWidths?: (string | number | undefined)[]
}

const ListViewPage = ({
    testCases,
    projects,
    testResults,
    testRuns,
    testRunResults,
    page,
    setPage,
    size,
    setSize,
    search,
    componentName,
    isLoading,
    updateProject,
    open,
    error,
    setOpen,
    headers = ["Test Summary", "Created By", "Last Update", "Test Sequence"],
    headerWidths = [undefined, 229, 210, 150],
    refetch,
}: ListViewPageProps) => {
    const [project, setProject] = React.useState<Project>()
    const [indexOfSelectedProject, setIndexOfSelectedProject] = React.useState(0)
    const [deleteProject] = useDeleteProjectMutation()
    const [selectedProjectId, setSelectedProjectId] = React.useState("")
    const [selectedRow, setSelectedRow] = React.useState("")
    const { selectedProject } = useSelector(appSelector)

    const handleDeleteTest = (project: Project) => {
        setDeletingProjects(`${project.id}_${project.name}`)
        setSelectedProjectId(project.id as string)
        deleteProject?.({ id: project.id }).unwrap()
    }

    const isDeleting = (projectId: string) => {
        const delItems = getItemFromLocalStorage("deletingProjects")
        return !!(delItems?.length && JSON.parse(delItems).find((project: string) => project.match(projectId)))
    }

    const handleOpenDialogue = (projectId: string) => {
        setSelectedRow(projectId)
    }
    const handleCloseDialogue = () => {
        setSelectedRow("")
    }
    const handleClick = (id: string) => {
        history.push(`${RoutePaths.TEST_DETAILS}/${id}`)
    }
    const totalSize =
        testCases?.totalSize ??
        projects?.totalSize ??
        testResults?.totalSize ??
        testRuns?.totalSize ??
        testRunResults?.totalSize ??
        0
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage + 1)
        event?.stopPropagation()
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSize(parseInt(event.target.value, 10))
        setPage(1)
    }

    const openEditProject =
        (project: Project, index: number): React.MouseEventHandler =>
        () => {
            setProject(project)
            setOpen!(true)
            setIndexOfSelectedProject(index)
        }

    const headerWidthsStyle = Object.fromEntries(
        headerWidths
            .map((width, index) => (width === undefined ? undefined : [`& th:nth-of-type(${index + 1})`, { width }]))
            .filter((entry): entry is [] => entry !== undefined),
    )

    const NoResults: React.FC<{ noResults: React.ReactNode; noSearchResults?: React.ReactNode }> = ({
        noResults,
        noSearchResults,
    }) => (
        <TableRow sx={rowStyle} className="noRowStyle">
            <TableCell align="center" colSpan={headers.length} sx={{ paddingBottom: "11px" }}>
                <Grid container justifyContent="center" spacing={0.5}>
                    <Grid item>
                        <InfoOutlinedIcon fontSize="small" color="primary" />
                    </Grid>
                    <Grid item>
                        {noSearchResults === undefined || !search || search.trim().length === 0 ? (
                            <Typography variant="body2">{noResults}</Typography>
                        ) : (
                            <Typography variant="body2">{noSearchResults}</Typography>
                        )}
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    )

    const getTestCasesPage = () =>
        !testCases || testCases.content.length === 0 ? (
            <NoResults
                noResults={
                    <>
                        This project has no test cases. Use the "
                        <Box component="span" fontWeight="fontWeightBold" color="primary.main">
                            NEW TEST
                        </Box>
                        " button to create one.
                    </>
                }
                noSearchResults={
                    <>
                        No test cases were found for the search "
                        <Box component="span" fontWeight="fontWeightBold" color="primary.main">
                            {search}
                        </Box>
                        "
                    </>
                }
            />
        ) : (
            testCases.content.map((testCase, index) => (
                <TableRow
                    key={keyGen()}
                    sx={rowStyle}
                    onClick={() => testCase?.id && handleClick(testCase.id)}
                    onKeyPress={(e) => e.key === "Enter" && handleClick(testCase.id)}
                >
                    <TableCell title={testCase.testSummary} sx={{ ...textEllipsis, maxWidth: "200px" }}>
                        {testCase.testSummary}
                    </TableCell>
                    <TableCell>{testCase.creator}</TableCell>
                    <TableCell>{date.stringify(testCase.lastEditTimestamp)}</TableCell>
                    <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CustomSwitch checked={testCase.isTestSequence} />
                        </Box>
                    </TableCell>
                </TableRow>
            ))
        )

    const getSettingsPage = () => (
        <>
            {!projects || projects.content.length === 0 ? (
                <NoResults
                    noResults={
                        <>
                            This project has no test plans. Use the "
                            <Box component="span" color="primary.main">
                                NEW PROJECTS
                            </Box>
                            " button to create one.
                        </>
                    }
                />
            ) : (
                projects.content.map((project, index) => (
                    <TableRow
                        key={project.id}
                        sx={
                            selectedProjectId === project.id
                                ? { ...rowStyle, "pointer-events": "none", cursor: "not-allowed" }
                                : selectedRow === project.id
                                ? { ...rowStyle, bgcolor: "trustiinGrey.300" }
                                : rowStyle
                        }
                    >
                        <TableCell
                            title={project.name}
                            onClick={openEditProject(project, index)}
                            sx={{ wordBreak: "break-word" }}
                        >
                            {project.name}
                        </TableCell>
                        <TableCell>{project.creator}</TableCell>
                        <TableCell>{date.stringify(project.lastEditTimestamp)}</TableCell>
                        <TableCell sx={{ justifyContent: "flex-end", display: "flex", width: 160 }}>
                            {selectedProjectId === project.id || isDeleting(project.id as string) ? (
                                <Button
                                    variant="outlined"
                                    disabled
                                    style={{ minWidth: "auto", whiteSpace: "nowrap" }}
                                    color="primary"
                                    size="small"
                                    startIcon={<CircularProgress color="inherit" size={13} />}
                                >
                                    DELETING...
                                </Button>
                            ) : (
                                <ConfirmDelete
                                    disabled={selectedProject?.id === project.id}
                                    name={project.name}
                                    onClose={handleCloseDialogue}
                                    onOpen={() => handleOpenDialogue(project.id as string)}
                                    handleDelete={() => handleDeleteTest(project as Project)}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                ))
            )}
            {open && (
                <UpsertProjectPage
                    firstProject={false}
                    open={open}
                    indexOfSelectedProject={indexOfSelectedProject}
                    updateProject={updateProject}
                    projectData={project}
                    componentName="UpdateProjectPage"
                    dialogSetOpen={setOpen!}
                    projects={projects?.content}
                />
            )}
        </>
    )

    const getTestPlanResultsPage = () =>
        !testResults || testResults.content.length === 0 ? (
            <NoResults noResults="This project has no any test results yet. Check back later." />
        ) : (
            testResults.content.map(({ testRunId, testPlanName, testPlanId, environmentName, testResult }) => (
                <TableRow
                    key={keyGen()}
                    sx={rowStyle}
                    onClick={() => history.push(`results/${testPlanId}/${testRunId}`)}
                    onKeyPress={(e) => e.key === "Enter" && history.push(`results/${testPlanId}/${testRunId}`)}
                >
                    <TableCell>
                        <Box title={testPlanName} sx={{ ...textEllipsis, maxWidth: "650px" }}>
                            {testPlanName}
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box title={environmentName}>{environmentName}</Box>
                    </TableCell>
                    <TableCell sx={{ ...dividedCell, textAlign: "center" }}>
                        {testResult.map(({ startTime, resultStatus }) => (
                            <Box key={keyGen()}>{getChipFromResultStatus(resultStatus)}</Box>
                        ))}
                    </TableCell>
                    <TableCell sx={{ ...dividedCell, textAlign: "center" }}>
                        {testResult.map(({ browser, startTime }) => (
                            <Box key={keyGen()}>
                                <BrowserIcon icon={browser} sx={{ height: "25px" }} />
                            </Box>
                        ))}
                    </TableCell>
                    <TableCell sx={dividedCell}>
                        {testResult.map(({ startTime }) => (
                            <Box key={keyGen()}>{date.stringify(startTime)}</Box>
                        ))}
                    </TableCell>
                    <TableCell sx={dividedCell}>
                        {testResult.map(({ runTime, startTime }) => (
                            <Box key={keyGen()}>
                                {runTime === undefined ? undefined : date.getDateFromSeconds(runTime)}
                            </Box>
                        ))}
                    </TableCell>
                </TableRow>
            ))
        )

    const getTestPlanResultsDetailsPage = () =>
        !testRunResults || testRunResults.content.length === 0 ? (
            <NoResults
                noResults="This plan has not produced any test results yet. Check back later."
                noSearchResults={`No test results were found with the search ${search}`}
            />
        ) : (
            testRunResults.content.map(({ testCaseId, testCaseName, status, browser, startTime, runTime }) => (
                <TableRow
                    key={keyGen()}
                    sx={rowStyle}
                    onClick={() => history.push(`/${path.append(testCaseId, browser)}`)}
                    onKeyPress={(e) => e.key === "Enter" && history.push(`/${path.append(testCaseId, browser)}`)}
                >
                    <TableCell>
                        <Box title={testCaseName} sx={{ ...textEllipsis, maxWidth: "600px" }}>
                            {testCaseName}
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box>{getChipFromResultStatus(status)}</Box>
                    </TableCell>
                    <TableCell>
                        <Box sx={{ display: "flex" }}>
                            <BrowserIcon icon={browser} sx={{ height: "25px" }} />
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box>{date.stringify(startTime)}</Box>
                    </TableCell>
                    <TableCell>
                        <Box>{runTime === undefined ? undefined : date.getDateFromSeconds(runTime)}</Box>
                    </TableCell>
                </TableRow>
            ))
        )

    const getPage = (): React.ReactNode => {
        switch (componentName) {
            case "TestCasesPage":
                return getTestCasesPage()
            case "UpdateProjectPage":
                return getSettingsPage()
            case "TestPlanResultsListPage":
                return getTestPlanResultsPage()
            case "TestPlanResultSummaryPage":
                return getTestPlanResultsDetailsPage()
        }
    }

    return (
        <>
            <TableContainer sx={{ minWidth: 1000, paddingBottom: 5, marginBottom: "1rem" }}>
                <Table sx={testCaseTableStyle}>
                    <TableHead
                        sx={{
                            "& th": {
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                                minWidth: "130px",
                            },
                        }}
                    >
                        <TableRow sx={{ ...testCaseHeaderStyle, ...headerWidthsStyle }}>
                            {headers.map((text) => (
                                <TableCell key={keyGen()}>{text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TestDetailsTableLoader rowStyle={rowStyle} componentName={componentName as string} />
                    ) : (
                        <TableBody>{getPage()}</TableBody>
                    )}

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                colSpan={headers.length}
                                count={totalSize}
                                rowsPerPage={size}
                                page={page - 1}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

export default ListViewPage
