import { MessageType, TestCase } from "../test-cases/test-case"
import { store } from "../store/store"
import { testResourceApi } from "../store/api"
import { recorderEnabled, recorderActive } from "../store/slices/app-slice"
import { Project } from "../projects/project"
import { history } from "../App"
import RoutePaths from "../constants/Routes"

const CONTENT_SCRIPT = "TrustiinContentScript"
const WEB_APP = "TrustiinWebApp"

let extensionVersion: string | undefined
let incognitoAllowed: boolean | undefined

export function isRecorderEnabled(): boolean {
    const state = store.getState()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isIncognitoProject = state?.app?.selectedProject?.incognito || false

    // Current decision is to ONLY allow incognito;
    // The recorder is only enabled if it installed AND is allowed incognito
    return extensionVersion !== undefined
}

/**
 * Gets the version of the extension the browser has installed.
 *
 * @returns The extension version or undefined if the extension is not detected (not installed).
 */
export function getExtensionVersion() {
    return extensionVersion
}

/**
 * Indicate whether incognito is allowed for recording or not
 */
export function isIncognitoAllowed(): boolean {
    return incognitoAllowed || false
}

/**
 * Sends a message to the extension.
 *
 * @param messageName The name of the message to be executed.
 * @param message The message content.
 */
export function sendMessage(messageName: string, message: unknown) {
    window.postMessage(
        {
            sender: WEB_APP,
            messageName,
            message,
        },
        "*",
    )
}

window.addEventListener("message", function (event) {
    if (event.source === window && event.data.sender === CONTENT_SCRIPT) {
        // Message came from content script and can be executed.
        handleMessage(event.data.messageName, event.data.message)
    }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function handleMessage(messageName: string, message: any) {
    // TODO: Lets see how many messages need to be handled.
    // If the list starts getting large, we can look into
    // adding a handler map or something similar rather
    // than a massive switch statement.

    switch (messageName) {
        case "TRUSTIIN_ERROR":
            store.dispatch(recorderEnabled(false))
            break
        case "recorderAnnouncement":
            const messageData = message as MessageType
            extensionVersion = messageData.extensionVersion
            incognitoAllowed = messageData.incognitoAllowed || false
            store.dispatch(recorderEnabled(!!messageData.extensionVersion))
            break
        case "saveTestCase":
            const result = store.dispatch(testResourceApi.endpoints.addTestCase.initiate(message as TestCase))
            result.unsubscribe()
            store.dispatch(recorderActive(false))
            break
        case "updateTestCase":
            const updateResultPromise = store.dispatch(
                testResourceApi.endpoints.updateTestCase.initiate(message as TestCase),
            )

            const newTestDetailsPromise = store.dispatch(
                testResourceApi.endpoints.getTestCaseById.initiate(message?.id as string),
            )
            try {
                await Promise.all([updateResultPromise, newTestDetailsPromise])
            } catch (error) {
                console.error("Error updating test case:", error)
            }
            updateResultPromise.unsubscribe()
            newTestDetailsPromise.unsubscribe()
            store.dispatch(recorderActive(false))
            break
        case "closeTestCase":
            store.dispatch(recorderActive(false))
            if (window.location.pathname.endsWith("test-details")) {
                history.push(RoutePaths.TEST_CASES)
            }
            break
        case "GetTestSequence":
            const state = store.getState()
            const testSequences = await store.dispatch(
                testResourceApi.endpoints.getTestSequencesByProjectId.initiate({
                    projectId: state?.app?.selectedProject?.id || "",
                }),
            )
            sendMessage("testSequences", { testSequences: testSequences.data || [] })
            break
        case "GetTestCaseById":
            const testCase = await store.dispatch(
                testResourceApi.endpoints.getTestCaseById.initiate(message?.testCaseId as string),
            )
            sendMessage("testCaseDetails", { testSequence: testCase.data })
            break
    }
}

export function sendSaveStatus(successful: boolean, message: string = "", status?: number) {
    sendMessage("saveStatus", {
        successful,
        message,
        status,
    })
}

export function sendRecordRequest(project?: Project, testCase?: TestCase, autoplay?: boolean) {
    if (!isRecorderEnabled()) {
        // Extension not installed
        // TODO: For now just logging to console.error... at some point should find out how to
        // display the same modal that is shown on load if the extension isn't installed
        // Probably need to store the recorder version in redux
        console.error("extension is not installed")
    } else if (project === undefined) {
        console.error("Project is undefined")
    } else {
        // Extension is installed. Send message to extension so it can start recording...
        sendMessage("startRecording", { project, testCase, autoplay })
    }
}
