import { InputAdornment, TextField, type TextFieldProps } from "@mui/material"
import React from "react"
import SearchIcon from "@mui/icons-material/Search"

export type SearchInputProps =
    | (TextFieldProps & {
          isLoading?: boolean
      })
    | {
          label: string
          disabled?: boolean
          isLoading?: boolean
          onChange: React.ChangeEventHandler<HTMLInputElement>
          value: string | undefined
      }

export const SearchInput: React.FunctionComponent<SearchInputProps> = ({ isLoading = false, ...props }) => {
    return (
        <TextField
            {...props}
            type="search"
            variant="standard"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    )
}
