/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import RoutePaths from "../constants/Routes"
import { useAuth0 } from "@auth0/auth0-react"

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>
    componentProps?: any
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, componentProps, ...rest }) => {
    const { isAuthenticated } = useAuth0()

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? <Component {...props} {...componentProps} /> : <Redirect to={RoutePaths.LOGIN} />
            }
        />
    )
}

export default PrivateRoute
