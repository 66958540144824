import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material"
import * as Formik from "formik"

export type CheckboxInputProps = JSX.IntrinsicElements["input"] &
    Formik.FieldConfig<boolean> &
    CheckboxProps & {
        /**
         * Omit feedback text, but still turn red if error
         * @default false
         */
        noFeedback?: boolean
        label?: string
    }

export const CheckboxInput = ({ noFeedback = false, label = "", ...props }: CheckboxInputProps): JSX.Element => {
    const [field, meta] = Formik.useField(props)
    const errorText = meta.error && meta.touched ? meta.error : undefined

    return (
        <FormControlLabel
            label={label}
            control={
                <Formik.Field
                    {...field}
                    {...props}
                    as={Checkbox}
                    checked={field.value} // DO NOT TRUST field.checked
                    error={noFeedback ? undefined : errorText}
                />
            }
        />
    )
}

export default CheckboxInput
