import React from "react"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { SxProps, Theme } from "@mui/material"

interface CustomDataGridProps {
    columns: GridColDef[]
    rows?: {}[]
    loading?: boolean
}

export const CustomDataGrid: React.FC<CustomDataGridProps> = ({ columns, rows = [], loading }) => {
    const [pageSize, setPageSize] = React.useState<number>(25)

    return (
        <DataGrid
            sx={columnHeaderStyle}
            rows={rows}
            rowsPerPageOptions={[25, 50, 100]}
            columns={columns}
            pageSize={pageSize}
            checkboxSelection
            autoHeight
            disableColumnMenu
            loading={loading}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
    )
}

const columnHeaderStyle: SxProps<Theme> = {
    border: "none",
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-root": {
        border: "none",
    },
}
