import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react"
import { Project } from "../projects/project"
import { Action, TestCase, TestStepImageMetadata } from "../test-cases/test-case"
import { initializeSelectedProject, authToken } from "./slices/app-slice"
import { history } from "../App"
import { sendSaveStatus } from "../utils/messaging"
import { Pageable } from "../pageable"
import { PlanCase } from "../plan-cases/plan-case"
import { PusherMetaData } from "../pusher/pusher"
import queryString from "query-string"
import { TestRunDTO, TestCaseResultDTO, ResultStatus } from "../test-results/test-result"
import { object } from "../utils"
import { Browser } from "../constants/browserEnum"
import RoutePaths from "../constants/Routes"

// TODO: add sort
interface PageArgs {
    page?: number
    size?: number
    search?: string
    sortBy?: string
}

interface AddProjectArgs {
    name: string
    description?: string
    environments: { name: string; url: string; incognito?: boolean; attached?: boolean }[]
}

interface GetByProjectIdArgs extends PageArgs {
    projectId: string
    includeTests?: boolean
    referencedInTestPlan?: boolean
}

interface GetTestCasesByProjectIdArgs extends GetByProjectIdArgs {
    onlyTestSequence?: boolean
    referencedInTestPlan?: boolean
}

interface GetTestStepImageMetadataArgs {
    resultId?: string
    testCaseId?: string
    stepNumber: string | number
    browser: Browser
}

interface AddPlanCaseArgs {
    name: string
    projectId: string
    description?: string
    environmentId: string
    schedule?: string[]
    browsers?: string[]
    testCases?: string[]
    enabled?: boolean
    scheduleTime: string | number
}

interface GetTestRunsByFilterArgs extends PageArgs {
    projectId?: string
    testRunId?: string
    environments?: string[]
    browsers?: Browser[]
    statuses?: ResultStatus[]
    startDate?: number
    endDate?: number
}

interface GetTestResultByTestRunIdArgs extends PageArgs {
    testRunId: string
    browsers?: Browser[]
    statuses?: ResultStatus[]
}

interface GetTestResultDetailsArgs {
    testRunId: string
    testCaseId: string
    browser: Browser
}
interface AbortTestResults {
    message?: string
}

interface UpdateSkippedBrowsersArgs {
    testCaseId: string
    planId: string
    browsers: Browser[]
}

interface ResultStatsArgs {
    projectId: string
    startDate: number
    endDate: number
    timezone?: string
    groupBy: string
}

interface StatResults {
    totalExecutedTestCases: number
    totalTestRuns: number
    totalRunTime: number
    testResults: {
        [date: string]: {
            testRunId: string
            testPlanName: string
            startTime: number
            testResult: {
                resultStatus: string
                browser: string
                startTime: number
                runTime: number
            }[]
            testCaseResults: {
                testRunId: string
                testCaseId: string
                testCaseName: string
                startTime: number
                browser: string
                status: string
                runTime: number
                stepResultList: unknown
            }[]
        }[]
    }
}

export type WhoAmIType = {
    displayName: string
    email: string
    oid: string
    tenantId: string
    tenantName: string
}
interface DeleteTestCase {
    id: string
}

interface DeletePlanCase {
    id: string
}
interface DeleteProject {
    id: string
}

interface auth0UserInvite {
    id: string
    invitation_url: string
}

interface auth0UserInviteArgs {
    email: string
    invitationCode: string
    client_id: string
}

export interface TenantMetadataInterface {
    tenantId: string
    tenantName: string
    allowedBrowsers: string[]
    maxACIGroups: number
    noOfUsers: number
    noOfProjects: number
    noOfTestsMinsPerMonth: number
    noOfParallelExecsPerBrowser: number
}

const baseQuery = fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers, { getState }) => {
        //@ts-ignore
        const token = authToken(getState())

        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    },
})

export const testResourceApi = createApi({
    reducerPath: "testResourceApi",
    baseQuery: retry(baseQuery, { maxRetries: 1 }),

    // Tag types allow for cache invalidation.
    tagTypes: ["Projects", "Test Cases", "Plan Cases", "Test Results", "Plan Results", "Test Runs"],

    endpoints: (builder) => ({
        getProjectById: builder.query<Project, string>({
            query: (id) => `projects/${id}`,
        }),

        getProjects: builder.query<Pageable<Project>, PageArgs | void>({
            query: (args) => ({
                params: object.filter({ ...args, sort: args?.sortBy || "name,ASC" }),
                url: "/projects",
            }),
            // Mark this query as providing projects, which allows us to invalidate.
            providesTags: ["Projects"],
            extraOptions: { maxRetries: 7 },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    // When projects are retrieved, check to see if an initial project needs to be set
                    dispatch(initializeSelectedProject(data.content))
                } catch (error) {
                    console.log(error)
                }
            },
        }),

        addProject: builder.mutation<Project, AddProjectArgs>({
            // Invalid projects tag so the new projects will be fetched from the server.
            invalidatesTags: ["Projects"],
            query(body) {
                return {
                    url: "/projects",
                    method: "POST",
                    body,
                }
            },
        }),

        deleteProject: builder.mutation<DeleteProject, Partial<Project>>({
            query(body) {
                return {
                    url: `/projects/${body.id}`,
                    method: "DELETE",
                    body,
                }
            },
        }),

        userSignup: builder.mutation<auth0UserInvite, auth0UserInviteArgs>({
            query(body) {
                return {
                    url: "/auth0/user/signup",
                    method: "POST",
                    body,
                }
            },
        }),

        getTestCasesByProjectId: builder.query<Pageable<TestCase>, GetTestCasesByProjectIdArgs>({
            query: ({ projectId, ...args }) => ({
                url: "/test-cases",
                params: object.filter({
                    "project-id": projectId,
                    ...args,
                }),
            }),
            providesTags: ["Test Cases"],
        }),
        getTestSequencesByProjectId: builder.query<TestCase, GetByProjectIdArgs>({
            query: ({ projectId, ...args }) => ({
                url: "/test-cases/test-sequences",
                params: object.filter({
                    "project-id": projectId,
                    ...args,
                }),
            }),
            providesTags: ["Test Cases"],
        }),

        getTestCaseById: builder.query<TestCase, string>({
            query: (id) => `/test-cases/${id}`,
        }),

        addTestCase: builder.mutation<TestCase, Partial<TestCase>>({
            query(body) {
                return {
                    url: "/test-cases",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: ["Test Cases"],

            async onQueryStarted(_, { queryFulfilled }) {
                // When the recorder sends us a test case to save and saving is finished,
                // the app should now display the saved test case details.
                // It doesn't seem like this is the best spot for doing the routing, but
                // I'm unsure where else this can go at the moment
                try {
                    const { data } = await queryFulfilled
                    sendSaveStatus(true)
                    if (history.location.pathname.toLowerCase() === RoutePaths.TEST_DETAILS) {
                        // The user is only redirected if they are still on the page that indicates they are recording.
                        // This is so we do not interfere with something else the user may be doing if they navigated
                        // away from the recording page.

                        history.push(`${RoutePaths.TEST_DETAILS}/${data.id}`)
                    }
                } catch (error) {
                    console.log(error)
                }
            },
        }),

        updateTestCase: builder.mutation<TestCase, Partial<TestCase>>({
            query(body) {
                return {
                    url: `/test-cases/${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: ["Test Cases"],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    if (data) {
                        sendSaveStatus(true)
                    }
                } catch (error) {
                    console.log(error)
                }
            },
        }),

        deleteTestCase: builder.mutation<DeleteTestCase, Partial<TestCase>>({
            query(body) {
                return {
                    url: `/test-cases/${body.id}`,
                    method: "DELETE",
                    body,
                }
            },
        }),

        addPlanCase: builder.mutation<PlanCase, AddPlanCaseArgs>({
            query(body) {
                return {
                    url: "/plans",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: ["Plan Cases"],

            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    history.push(`${RoutePaths.PLANS}/${data.id}`)
                } catch (error) {
                    console.log(error)
                }
            },
        }),
        abortPlanCase: builder.mutation<AbortTestResults, string[]>({
            query(body) {
                return {
                    url: "/test-runs/exec",
                    method: "DELETE",
                    body,
                }
            },
            invalidatesTags: ["Plan Cases"],
        }),

        getPlanCaseById: builder.query<PlanCase, string>({
            query: (id) => `/plans/${id}`,
        }),

        getTenantMetadata: builder.query<TenantMetadataInterface, {}>({
            query: () => `/configs/tenant`,
        }),

        getPlanCasesByProjectId: builder.query<Pageable<PlanCase>, GetByProjectIdArgs>({
            query: ({ projectId, ...args }) => ({
                url: "/plans",
                params: object.filter({
                    "project-id": projectId,
                    ...args,
                }),
            }),
            providesTags: ["Plan Cases"],
        }),

        updatePlanCase: builder.mutation<PlanCase, AddPlanCaseArgs & { id: string }>({
            invalidatesTags: ["Plan Cases"],
            query: ({ id, ...plan }) => ({
                url: `/plans/${id}`,
                method: "PUT",
                body: plan,
            }),
        }),

        deletePlanCase: builder.mutation<DeletePlanCase, Partial<PlanCase>>({
            query(body) {
                return {
                    url: `/plans/${body.id}`,
                    method: "DELETE",
                    body,
                }
            },
        }),

        executeTestPlan: builder.mutation<string, string>({
            invalidatesTags: ["Test Runs"],
            query(args) {
                return {
                    url: "/plans/exec",
                    method: "POST",
                    params: { "test-plan-id": args },
                }
            },
        }),

        updateProject: builder.mutation<Project, Omit<Partial<Project>, "environments"> & AddProjectArgs>({
            invalidatesTags: ["Projects", "Plan Cases"],
            query(project) {
                return {
                    url: `projects/${project.id}`,
                    method: "PUT",
                    body: project,
                }
            },
        }),

        updateSkippedBrowsers: builder.mutation<void, UpdateSkippedBrowsersArgs>({
            query({ testCaseId, planId, browsers }) {
                return {
                    url: `/test-cases/${testCaseId}/skipBrowsers`,
                    method: "PUT",
                    params: { planId },
                    body: browsers,
                }
            },
        }),

        getTestStepImageMetadata: builder.query<TestStepImageMetadata, GetTestStepImageMetadataArgs>({
            query: (args) =>
                `/test-cases/${args.testCaseId}/results/${args.resultId}/browser/${args.browser}/image/${args.stepNumber}/metadata`,
        }),

        getTestRunsByFilter: builder.query<Pageable<TestRunDTO>, GetTestRunsByFilterArgs>({
            query: ({ projectId, testRunId, environments, browsers, statuses, endDate, ...args }) => {
                return queryString.stringifyUrl({
                    url: "/test-results",
                    query: object.filter(
                        {
                            "project-id": projectId,
                            testrun: testRunId,
                            environment: environments,
                            browser: browsers,
                            status: statuses,
                            endDate: endDate ?? new Date().getTime() * 1000,
                            ...args,
                        },
                        object.removeEmpty,
                    ),
                })
            },
            extraOptions: { maxRetries: 7 },
            providesTags: ["Test Runs"],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    console.log(error)
                }
            },
        }),

        getTestResultByTestRunId: builder.query<Pageable<TestCaseResultDTO>, GetTestResultByTestRunIdArgs>({
            query: ({ testRunId, browsers, statuses, ...args }) => ({
                params: object.filter({ browser: browsers, status: statuses, ...args }, object.removeEmpty),
                url: `/test-results/${testRunId}`,
            }),
            extraOptions: { maxRetries: 7 },
            providesTags: ["Plan Results"],
        }),

        getTestResultDetails: builder.query<TestCaseResultDTO<true>, GetTestResultDetailsArgs>({
            query: ({ testRunId, testCaseId, browser }) => ({
                url: `/test-results/${testRunId}/${testCaseId}/${browser}`,
            }),
        }),

        getTestResultStats: builder.query<StatResults, ResultStatsArgs>({
            query: ({ projectId, ...args }) => ({
                url: `/test-results/stats`,
                params: object.filter({
                    "project-id": projectId,
                    ...args,
                }),
            }),
        }),

        deleteTestResults: builder.mutation<DeleteTestCase, Partial<TestCase>>({
            query(body) {
                return {
                    url: `/test-results/${body.id}`,
                    method: "DELETE",
                    body,
                }
            },
        }),

        getTestResultAutofix: builder.query<Exclude<Action["element"], undefined>, GetTestStepImageMetadataArgs>({
            query: (args) =>
                `/test-cases/${args.testCaseId}/results/${args.resultId}/browser/${args.browser}/image/${args.stepNumber}/autofix`,
        }),

        getPusherMetaData: builder.query<PusherMetaData, {}>({
            query: () => "/pusher/credentials",
        }),
        getWhoAmI: builder.query<WhoAmIType, void>({
            query: () => "/whoami",
        }),
    }),
})

export const authResourceApi = createApi({
    reducerPath: "authResourceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
    }),
    tagTypes: [],
    endpoints: (build) => ({
        userSignup: build.mutation<auth0UserInvite, auth0UserInviteArgs>({
            query(body) {
                return {
                    url: "/auth0/user/signup",
                    method: "POST",
                    body,
                }
            },
        }),
    }),
})

export const {
    useGetProjectByIdQuery,
    useGetProjectsQuery,
    useAddProjectMutation,
    useGetTestCasesByProjectIdQuery,
    useGetTestCaseByIdQuery,
    useAddTestCaseMutation,
    useGetPlanCasesByProjectIdQuery,
    useGetPlanCaseByIdQuery,
    useGetTenantMetadataQuery,
    useAddPlanCaseMutation,
    useAbortPlanCaseMutation,
    useUpdatePlanCaseMutation,
    useDeletePlanCaseMutation,
    useUpdateTestCaseMutation,
    useDeleteTestCaseMutation,
    useUpdateSkippedBrowsersMutation,
    useUpdateProjectMutation,
    useGetTestStepImageMetadataQuery,
    useExecuteTestPlanMutation,
    useGetTestRunsByFilterQuery,
    useGetTestResultByTestRunIdQuery,
    useGetTestResultDetailsQuery,
    useGetTestResultAutofixQuery,
    useGetTestResultStatsQuery,
    useGetPusherMetaDataQuery,
    useGetWhoAmIQuery,
    useDeleteProjectMutation,
    useDeleteTestResultsMutation,
} = testResourceApi

export const { useUserSignupMutation } = authResourceApi
