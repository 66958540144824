import React from "react"
import Filter from "../../components/Filter"
import { FilterPropType } from "../../types/index"
import { ResultStatus } from "../test-result"

const StatusFilter = ({ projectId, options, setPage }: FilterPropType<ResultStatus>) => {
    return <Filter projectId={projectId} options={options} setPage={setPage} label="Status" fullWidth />
}

export default StatusFilter
