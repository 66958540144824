/**
 * Converts array to map with indexes of each element
 *
 * @example
 *
 * ```ts
 * toIndexMap([1, 1, 2, 3, 3, 4]) // {1: [0, 1], 2: [2], 3: [3, 4], 4: 5}
 * ```
 */
export const toIndexMap = <T>(array: T[]): Map<T, number[]> => {
    const indexMap: Map<T, number[]> = new Map()

    for (let index = 0; index < array.length; index++) {
        const existingIndexArray = indexMap.get(array[index])

        if (existingIndexArray) {
            existingIndexArray.push(index)
        } else {
            indexMap.set(array[index], [index])
        }
    }

    return indexMap
}

/**
 * Find duplicate values without O(n^2)
 *
 * @example
 *
 * ```ts
 * getDuplicateIndices([1, 1, 2, 3, 3, 4]) // [0, 1, 3, 4]
 * ```
 *
 * @returns the indices of all duplicate values in an array
 */
export const getDuplicateIndices = <T>(array: T[]): number[] => {
    const indexMap = toIndexMap(array)
    const duplicates: number[] = []

    for (const indices of indexMap.values()) {
        if (indices.length > 1) {
            duplicates.push(...indices)
        }
    }

    return duplicates
}
