import React from "react"
import { SearchInput } from "../../components/SearchInput"

type PlanFilterType = {
    value: string
    onPlanNameChange: React.ChangeEventHandler<HTMLInputElement>
    isLoading?: boolean
}
const PlansFilter = ({ value, onPlanNameChange, isLoading }: PlanFilterType) => {
    return (
        <SearchInput
            value={value}
            label="Search Plan Name..."
            onChange={onPlanNameChange}
            variant="standard"
            isLoading={isLoading}
            fullWidth
        />
    )
}
export default PlansFilter
