import { useEffect, useState } from "react"
import { usePusherNetwork } from "../AppContextProvider"
import { getItemFromLocalStorage, setItemInLocalStorage } from "../utils/accessLocalStorage"
import { ProjectDeleteStatus } from "../pusher/pusher"

const usePusherSubscription = (onSuccess: () => void) => {
    const pusherNetwork = usePusherNetwork()
    const [project, setProject] = useState("")
    useEffect(() => {
        const subscriptionHandler = (event: ProjectDeleteStatus) => {
            if (onSuccess) {
                const delItems: string | null = getItemFromLocalStorage("deletingProjects") ?? null
                if (delItems) {
                    const projectName =
                        delItems?.length &&
                        JSON.parse(delItems)
                            .find((project: string) => project.match(event.project_id))
                            ?.split("_")[1]
                    setItemInLocalStorage(
                        "deletingProjects",
                        JSON.stringify(
                            JSON.parse(delItems).filter((project: string) => !project.match(event.project_id)),
                        ),
                    )
                    projectName && setProject(`${projectName} has been successfully deleted.`)
                }

                onSuccess()
            }
        }

        if (pusherNetwork) {
            window.addEventListener("trustiinDelEvent", subscriptionHandler as unknown as EventListener)
            return () => window.removeEventListener("trustiinDelEvent", subscriptionHandler as unknown as EventListener)
        }
    }, [pusherNetwork, onSuccess])
    return project
}

export default usePusherSubscription
