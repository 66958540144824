import {
    Action,
    combineReducers,
    configureStore,
    isRejectedWithValue,
    Middleware,
    MiddlewareAPI,
    ThunkAction,
} from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { appReducer, setPopupError } from "./slices/app-slice"
import { testResourceApi, authResourceApi } from "./api"
import { sendSaveStatus } from "../utils/messaging"

export const rootReducer = combineReducers({
    app: appReducer,
    [testResourceApi.reducerPath]: testResourceApi.reducer,
    [authResourceApi.reducerPath]: authResourceApi.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const displayApiErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.meta?.arg?.endpointName === "addTestCase") {
            // Something went wrong while saving a test case.
            // In this scenario the recorder should be informed so it can show the error.
            sendSaveStatus(false, action.payload?.data?.message || "", action.payload.status)
        } else {
            // If there is a 500 error from an API call show a generic error message.
            // This may need to be expanded to include other status codes or messages
            // as they are discovered
            sendSaveStatus(false, action.payload?.data?.message || "", action.payload.status)

            if (action?.meta?.baseQueryMeta?.response?.status === 500) {
                api.dispatch(
                    setPopupError(
                        "Something went wrong while processing your request. Please try again.\n If the problem persists, please contact our support team.",
                    ),
                )
                sendSaveStatus(false, "", action.payload.status)
            }
        }
    }

    return next(action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            testResourceApi.middleware,
            authResourceApi.middleware,
            displayApiErrorMiddleware,
        ),
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()
