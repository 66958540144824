import {
    InputLabel,
    MenuItem,
    FormControl,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from "@mui/material"
import { Formik, Form, Field } from "formik"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import React from "react"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useHistory, useLocation } from "react-router-dom"
import { setItemInLocalStorage } from "../../utils/accessLocalStorage"
import { useUrlString } from "../../utils/parseQuerySearch"
import { format } from "date-fns"

const options = ["Today", "Yesterday", "Last 7 days", "Last 30 days", "Custom"] as const

export type Options = (typeof options)[number]

export type DateRange = [Date | null, Date | null]

type StartedTimeFilterProps = {
    startedTime: Options
    projectId: string
    dateRange: DateRange
    setPage: React.Dispatch<React.SetStateAction<number>>
    setDateRange: React.Dispatch<React.SetStateAction<DateRange>>
}

interface DateRangeErrors {
    from?: string
    to?: string
}

const StartedTimeFilter = ({ startedTime, projectId, dateRange, setDateRange, setPage }: StartedTimeFilterProps) => {
    const history = useHistory()
    const location = useLocation()
    const [dialogState, setDialogState] = React.useState<boolean>(false)
    const updateUrlString = useUrlString()

    const handleChange = (event: SelectChangeEvent<string>) => {
        setPage(1)
        if (event?.target?.value === "Custom") {
            setDialogState(true)
        }
        setItemInLocalStorage("query", updateUrlString({ to: "", from: event?.target?.value.replace(/ /g, "_") }))
        history.replace(updateUrlString({ to: "", from: event?.target?.value.replace(/ /g, "_") }) || location.pathname)
    }

    const [error, setError] = React.useState<unknown | null>(null)
    const errorMessage = React.useMemo(() => {
        switch (error) {
            case "maxDate":
            case "minDate": {
                return "This date must be later than 'From' date."
            }
            default: {
                return ""
            }
        }
    }, [error])

    return (
        <FormControl
            variant="standard"
            sx={{
                width: "100%",
                marginRight: "10px",
            }}
        >
            <InputLabel id="multiple-select-label" sx={{ fontSize: "15px" }}>
                Started Time
            </InputLabel>
            <Select
                id={projectId}
                sx={{ maxHeight: "50px" }}
                labelId="multiple-select-label"
                value={startedTime}
                onChange={handleChange}
                renderValue={(selected) => selected}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        sx={{ maxHeight: "40px" }}
                        onClick={() => {
                            if (option === "Custom") {
                                setDialogState(true)
                            }
                        }}
                    >
                        <Radio
                            checked={startedTime === option}
                            onChange={handleChange}
                            value={option}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                        />
                        <label>{option}</label>
                    </MenuItem>
                ))}
            </Select>
            <Dialog
                open={dialogState}
                onClose={() => {
                    setDialogState(false)
                    setError(null)
                }}
                PaperProps={{ sx: { padding: 3, borderRadius: 2 } }}
            >
                <Formik
                    initialValues={{
                        from: dateRange[0],
                        to: dateRange[1],
                    }}
                    onSubmit={(values) => {
                        //@ts-ignore
                        const toDate = new Date(values.to)
                        //@ts-ignore
                        const fromDate = new Date(values.from)

                        toDate.setHours(23, 59, 59)

                        const formattedToDate = format(toDate, "yyyy-MM-dd")
                        const formattedFromDate = format(fromDate, "yyyy-MM-dd")

                        setDateRange([fromDate, toDate])

                        setItemInLocalStorage(
                            "query",
                            updateUrlString({
                                to: formattedToDate,
                                from: formattedFromDate,
                            }),
                        )
                        history.replace(
                            updateUrlString({
                                to: formattedToDate,
                                from: formattedFromDate,
                            }) || location.pathname,
                        )
                        setDialogState(false)
                    }}
                    validate={(values) => {
                        let errors: DateRangeErrors = {}
                        if (!(values.from instanceof Date) || isNaN(values.from.getTime())) errors.from = "Invalid date"
                        if (!(values.to instanceof Date) || isNaN(values.to.getTime())) errors.to = "Invalid date"

                        if (values?.from && values?.to && values?.to?.getTime() < values?.from?.getTime())
                            errors.to = "This date must be later than From."
                        return errors
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <DialogTitle sx={{ textAlign: "center" }}>Select Date Range</DialogTitle>
                            <DialogContent sx={{ width: "500px" }}>
                                <Field name="from">
                                    {() => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                views={["month", "year", "day"]}
                                                inputFormat="MM/dd/yyyy"
                                                mask="__/__/____"
                                                label="From"
                                                minDate={new Date("2023-01-02")}
                                                maxDate={new Date()}
                                                value={values.from}
                                                onChange={(date) => {
                                                    setFieldValue("from", date)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{ marginTop: "20px" }}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={Boolean(error)}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                                <Field name="to">
                                    {() => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="To"
                                                value={values.to}
                                                onChange={(date) => setFieldValue("to", date)}
                                                minDate={values.from}
                                                maxDate={new Date()}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{ marginTop: "20px" }}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={Boolean(error)}
                                                        helperText={errorMessage}
                                                    />
                                                )}
                                                onError={(newError) => setError(newError)}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setDialogState(false)
                                        setError(null)
                                    }}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!values.from || !values.to || values.from! > values.to!}
                                >
                                    SAVE
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </FormControl>
    )
}

export default StartedTimeFilter
