import {
    Box,
    Button,
    CircularProgress,
    Paper,
    type SxProps,
    type Theme,
    Typography,
    Stepper,
    Step,
    StepLabel,
    IconButton,
    TextField,
    Divider,
    InputAdornment,
    Tooltip,
    Badge,
    Skeleton,
    Alert,
    Snackbar,
    Breadcrumbs,
} from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { useGetProjectByIdQuery, useGetTestCaseByIdQuery, useGetTestStepImageMetadataQuery } from "../store/api"
import { TestStepImage } from "../components/TestStepImage"
import { date, testStep as testStepUtils, textEllipsis } from "../utils"
import { sendRecordRequest } from "../utils/messaging"
import { useSelector } from "react-redux"
import { appSelector, recorderActive } from "../store/slices/app-slice"
import { useAppDispatch } from "../store/store"
import { useUpdateTestCaseMutation, useDeleteTestCaseMutation } from "../store/api"
import { Browser } from "../constants/browserEnum"
import { Action, TestSequence, TestCase } from "./test-case"
import { CustomSwitch } from "../components/CustomSwitch"
import StepperLoader from "../components/StepperLoader"
import ConfirmDeleteModal from "../components/ConfirmDeleteModal"
import { useHistory } from "react-router"
import { AppLink } from "../components/AppLink"
import RoutePaths from "../constants/Routes"

interface TestDetailsPageParams {
    id?: string
}
const containerStyle: SxProps<Theme> = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
}

const summaryStyle: SxProps<Theme> = {
    display: "flex",
    border: 1,
    borderColor: "trustiinGrey.300",
    p: 2,
}

const summaryCellStyle: SxProps<Theme> = {
    mr: 5,
}

const testSummaryCellStyle: SxProps<Theme> = {
    ...summaryCellStyle,
    flexGrow: 1,
}

const testStepsContainerStyle: SxProps<Theme> = {
    display: "flex",
    border: 0,
    flexGrow: 1,
}

const buttonStyle: SxProps<Theme> = {
    height: "fit-content",
    paddingTop: "8.5px",
    paddingBottom: "8.5px",
}
const badgeStyle: SxProps<Theme> = {
    marginRight: "1rem",
    "& .MuiBadge-colorInfo": {
        // We need to update the theme with this color, I have realized that theme colors are not being used
        // It should probably be a conversation worth talking about.
        backgroundColor: "#9e9e9e",
    },
}

const parentLabel: SxProps<Theme> = {
    marginBottom: "10px",
    cursor: "pointer",
}

function showRecordingInProgress() {
    return (
        <>
            <Typography textAlign="center" component="h3" variant="body1" sx={{ marginTop: "10rem" }}>
                The test recorder has been opened in another window.
            </Typography>
            <Typography textAlign="center" component="h3" variant="body1">
                Once saved the test details will be displayed in this window.
            </Typography>
            <CircularProgress size="5rem" sx={{ marginLeft: "auto", marginRight: "auto", display: "block", mt: 4 }} />
        </>
    )
}

export const TestDetailsPage = () => {
    const dispatch = useAppDispatch()
    const [updateTestCase] = useUpdateTestCaseMutation()
    const [deleteTestCase] = useDeleteTestCaseMutation()
    const { recorderRunning, recorderEnabled } = useSelector(appSelector)
    const { id } = useParams<TestDetailsPageParams>()
    const testIdInRoute = id !== undefined && id !== null
    const {
        data: testCaseDetails,
        isLoading,
        refetch,
    } = useGetTestCaseByIdQuery(id || "", {
        skip: !testIdInRoute,
        refetchOnMountOrArgChange: true,
    })
    const hasTestCaseDetails = testIdInRoute && testCaseDetails !== null && testCaseDetails !== undefined
    const { data: project } = useGetProjectByIdQuery(testCaseDetails?.testProjectId?.toString() ?? "", {
        skip: !hasTestCaseDetails,
    })

    const [isTestSequence, setIsTestSequence] = React.useState<boolean>(testCaseDetails?.isTestSequence ? true : false)

    const [editingState, setEditingState] = React.useState(false)
    const [localSummary, setLocalSummary] = React.useState<string | undefined>(undefined)
    const [activeStep, setActiveStep] = React.useState<string>("1")
    const [baselineRunId, setBaselineRunId] = React.useState(testCaseDetails?.baselineRunId)

    const handleStep = (step: string) => () => {
        setActiveStep(step)
    }

    const { data: testStepImageMetadata, error: testStepImageMetadataError } = useGetTestStepImageMetadataQuery(
        {
            testCaseId: testCaseDetails?.id,
            resultId: testCaseDetails?.baselineRunId || baselineRunId,
            stepNumber: activeStep,
            browser: Browser.Chrome,
        },
        {
            skip: !hasTestCaseDetails,
        },
    )

    React.useEffect(() => {
        if (hasTestCaseDetails) {
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recorderRunning])

    React.useEffect(() => {
        setBaselineRunId(testCaseDetails?.baselineRunId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTestCaseDetails])

    const [error, setError] = React.useState<null | string>(null)
    const [deleteError, setDeleteError] = React.useState<null | string>(null)
    const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false)
    const [busy, setIsBusy] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const history = useHistory()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.checked && testCaseDetails?.referencedInTestCase) {
            return
        }
        setIsBusy(true)
        updateTestCase?.({ ...testCaseDetails, isTestSequence: event.target.checked })
            .unwrap()
            .then(() => {
                refetch()
                setIsBusy(false)
                setIsTestSequence(event.target.checked)
            })
            .catch(() => {
                setIsBusy(false)
            })
    }

    React.useEffect(() => {
        if (testCaseDetails?.testScript.steps[0]) {
            if (!testCaseDetails?.testScript.steps[0].command) {
                setActiveStep("1.1")
            } else {
                setActiveStep("1")
            }
        }
    }, [testCaseDetails])

    React.useEffect(() => {
        setLocalSummary(testCaseDetails?.testSummary || undefined)
    }, [testCaseDetails?.testSummary])

    React.useEffect(() => {
        setIsTestSequence(testCaseDetails?.isTestSequence ? true : false)
    }, [testCaseDetails?.isTestSequence])

    const onSummaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalSummary(e.target.value)
        setError(null)
    }
    const makeEdits = () => {
        if (editingState) {
            updateTestCase?.({ ...testCaseDetails, testSummary: localSummary })
                .unwrap()
                .then(() => {
                    refetch()
                    setError(null)
                })
                .catch((err) => {
                    setError(err.data.message)
                    setEditingState(true)
                    setTooltipOpen(true)
                })
        }
        setEditingState(!editingState)
    }

    const handleDeleteTest = () => {
        deleteTestCase?.({ id: testCaseDetails?.id })
            .unwrap()
            .then(() => {
                history.push(RoutePaths.TEST_CASES)
            })
            .catch((error) => {
                setIsOpen(true)
                setDeleteError(error?.data?.message || "Failed to delete Test")
            })
    }

    const handleCloseSnackBar = () => {
        setIsOpen(false)
        setDeleteError(null)
    }

    let listTestSteps
    if (hasTestCaseDetails) {
        listTestSteps = (
            <Stepper nonLinear activeStep={Number(activeStep) - 1} orientation="vertical">
                {testCaseDetails?.testScript.steps.map((step, index) =>
                    step.command ? (
                        <Step key={index} onClick={handleStep((index + 1).toString())} sx={parentLabel}>
                            <StepLabel>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: testStepUtils.getFriendlyDescription(step),
                                    }}
                                />
                            </StepLabel>
                        </Step>
                    ) : (
                        <Step
                            active={(index + 1).toString() === activeStep.split(".")[0]}
                            key={index}
                            sx={{ cursor: "pointer" }}
                        >
                            <StepLabel>{(step as TestSequence).testSequence?.testSummary}</StepLabel>
                            <Box ml="30px">
                                <Stepper nonLinear orientation="vertical">
                                    {(step as TestSequence).testSequence.testScript?.steps.map((step, childIndex) => {
                                        const CustomStepIcon = () => {
                                            return (
                                                <Badge
                                                    sx={badgeStyle}
                                                    color={
                                                        `${index + 1}.${childIndex + 1}` === activeStep
                                                            ? "primary"
                                                            : "info"
                                                    }
                                                    badgeContent={`${index + 1}.${childIndex + 1}`}
                                                ></Badge>
                                            )
                                        }
                                        return (
                                            <Step
                                                onClick={handleStep(`${index + 1}.${childIndex + 1}`)}
                                                key={`${index}.${childIndex}`}
                                                active={`${index + 1}.${childIndex + 1}` === activeStep}
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <StepLabel StepIconComponent={CustomStepIcon}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: testStepUtils.getFriendlyDescription(
                                                                step as Action,
                                                            ),
                                                        }}
                                                    />
                                                </StepLabel>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </Box>
                        </Step>
                    ),
                )}
            </Stepper>
        )
    } else {
        if (isLoading) {
            listTestSteps = <StepperLoader />
        } else {
            listTestSteps = showRecordingInProgress()
        }
    }
    const hasTestSequence = testCaseDetails?.testScript?.steps.some((step) => !(step as Action).command)

    const openTestInRecorder = (autoplay?: boolean): void => {
        if (!recorderRunning) {
            dispatch(recorderActive(true))

            const testCaseDetailsObj = {
                ...testCaseDetails,
                testSummary: localSummary,
                isTestSequence: isTestSequence,
            } as TestCase
            sendRecordRequest(project, testCaseDetailsObj, autoplay)
        }
    }

    const imageContainerStyle: SxProps<Theme> = {
        width: "50%",
        m: 2,
        display: "flex",
        flexDirection: "column",
    }
    if (hasTestCaseDetails) {
        imageContainerStyle.border = 0
    }

    // If there is an error getting the image meta data, assume it doesn't yet exist.
    // If a 500 is thrown, this is already handled in RTK Query middleware (store.ts)
    const metadata = testStepImageMetadataError ? undefined : testStepImageMetadata
    return (
        <Box sx={{ ...containerStyle, overflowX: "auto", minWidth: 1000 }}>
            <Box sx={{ display: "flex", flexDirection: "column", py: 2 }}>
                <>
                    {isOpen && (
                        <Snackbar
                            open={isOpen}
                            autoHideDuration={5000}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert severity="error">{deleteError}</Alert>
                        </Snackbar>
                    )}
                </>
                <Breadcrumbs sx={{ mb: 2 }}>
                    mplement new layout{" "}
                    <AppLink underline="hover" to={RoutePaths.TEST_CASES}>
                        Tests
                    </AppLink>
                    <Typography title={localSummary} sx={{ ...textEllipsis, maxWidth: "300px" }}>
                        {localSummary}
                    </Typography>
                </Breadcrumbs>
                <Paper sx={summaryStyle}>
                    <Box sx={testSummaryCellStyle}>
                        <Box style={{ flexWrap: "nowrap", display: "flex" }}>
                            <Typography style={{ whiteSpace: "nowrap" }}>Test Summary</Typography>
                            <IconButton onClick={makeEdits} size="small">
                                {editingState ? (
                                    <CheckIcon fontSize="inherit" />
                                ) : (
                                    <EditOutlinedIcon fontSize="inherit" />
                                )}
                            </IconButton>
                        </Box>
                        {isLoading ? (
                            <Skeleton width={250} variant="text" sx={{ fontSize: "1.33rem" }} />
                        ) : (
                            hasTestCaseDetails && (
                                <>
                                    {editingState ? (
                                        <TextField
                                            sx={{ margin: 0, minWidth: "auto", display: "block" }}
                                            variant="standard"
                                            onFocus={(e) => e.target.select()}
                                            onBlur={makeEdits}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    makeEdits()
                                                }
                                            }}
                                            value={localSummary}
                                            onChange={onSummaryChange}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {error !== null && (
                                                            <Tooltip
                                                                title={`${error}.`}
                                                                open={tooltipOpen}
                                                                onClose={() => setTooltipOpen(false)}
                                                                onOpen={() => setTooltipOpen(true)}
                                                            >
                                                                <CloseIcon color="error" />
                                                            </Tooltip>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            autoFocus
                                        />
                                    ) : (
                                        <Box
                                            title={localSummary}
                                            sx={{ ...textEllipsis, maxWidth: "300px", width: "auto", margin: "4px 0" }}
                                        >
                                            {localSummary}
                                        </Box>
                                    )}
                                </>
                            )
                        )}
                    </Box>
                    <Box sx={summaryCellStyle}>
                        <Typography noWrap>Created By</Typography>
                        {isLoading ? (
                            <Skeleton width={197} variant="text" sx={{ fontSize: "1.33rem" }} />
                        ) : (
                            hasTestCaseDetails && <span>{testCaseDetails?.creator}</span>
                        )}
                    </Box>
                    <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                    <Box sx={summaryCellStyle}>
                        <Typography noWrap>Last Update</Typography>
                        {isLoading ? (
                            <Skeleton width={175} variant="text" sx={{ fontSize: "1.33rem" }} />
                        ) : (
                            hasTestCaseDetails && (
                                <Typography noWrap>{date.stringify(testCaseDetails?.lastEditTimestamp)}</Typography>
                            )
                        )}
                    </Box>
                    <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                    <Box sx={summaryCellStyle}>
                        <Typography noWrap>Test Sequence</Typography>
                        {isLoading ? (
                            <Skeleton width={50} variant="text" sx={{ fontSize: "1.33rem" }} />
                        ) : (
                            hasTestCaseDetails && (
                                <>
                                    {hasTestSequence ? (
                                        <Tooltip title="The test containing Test Sequence cannot be a Test Sequence.">
                                            <div>
                                                <CustomSwitch checked={isTestSequence} />
                                            </div>
                                        </Tooltip>
                                    ) : busy ? (
                                        <CircularProgress size="1.3rem" sx={{ marginLeft: "10px", mt: 1 }} />
                                    ) : testCaseDetails?.referencedInTestCase && isTestSequence ? (
                                        <Tooltip title="Unable to toggle Test Sequence since it is referenced in other test cases.">
                                            <div>
                                                <CustomSwitch checked={isTestSequence} onChange={handleChange} />
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title={
                                                isTestSequence
                                                    ? "Toggle Test Sequence to prevent referencing in other cases.."
                                                    : "Toggle to allow referencing this Test Sequence in other cases."
                                            }
                                        >
                                            <div>
                                                <CustomSwitch checked={isTestSequence} onChange={handleChange} />
                                            </div>
                                        </Tooltip>
                                    )}
                                </>
                            )
                        )}
                    </Box>
                    <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                    <Button
                        variant="outlined"
                        style={{ minWidth: "auto", whiteSpace: "nowrap" }}
                        sx={{ mr: 3, ...buttonStyle }}
                        disabled={!hasTestCaseDetails || !recorderEnabled || recorderRunning || !project}
                        color="primary"
                        startIcon={<RadioButtonCheckedIcon />}
                        onClick={() => openTestInRecorder()}
                    >
                        EDIT & RUN TEST
                    </Button>
                    <ConfirmDeleteModal
                        handleDelete={handleDeleteTest}
                        referencedInTestPlan={testCaseDetails?.referencedInTestPlan || false}
                        referencedInTestCase={testCaseDetails?.referencedInTestCase || false}
                    />
                </Paper>
                <Box sx={testStepsContainerStyle}>
                    <Typography component="h1" variant="body1" sx={{ width: "50%", mt: 2, mb: 1 }}>
                        Steps
                    </Typography>
                    <Typography component="h1" variant="body1" sx={{ width: "50%", mt: 2, mb: 1 }}>
                        Baseline Image
                    </Typography>
                </Box>
                <Paper
                    sx={{
                        ...testStepsContainerStyle,
                        borderColor: "trustiinGrey.300",
                        borderWidth: 1,
                        height: "calc(100vh - 19rem)",
                    }}
                >
                    <Box sx={{ width: "50%", m: 2, overflow: "scroll" }}>{listTestSteps}</Box>
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="middle"
                        sx={{ borderWidth: "inherit", borderColor: "trustiinGrey.300" }}
                    />
                    <Paper sx={imageContainerStyle}>
                        {hasTestCaseDetails ? (
                            <TestStepImage
                                componentName="TestDetails"
                                testCaseId={testCaseDetails?.id}
                                resultId={testCaseDetails?.baselineRunId || baselineRunId}
                                stepNumber={activeStep}
                                metadata={metadata}
                                browser={Browser.Chrome}
                            />
                        ) : (
                            <>
                                <Typography
                                    textAlign="center"
                                    component="h3"
                                    variant="body1"
                                    sx={{ marginTop: "25vh" }}
                                >
                                    All steps in this test are listed to the left.
                                </Typography>
                                <Typography textAlign="center" component="h3" variant="body1">
                                    Please select a step to see a screenshot of it.
                                </Typography>
                            </>
                        )}
                    </Paper>
                </Paper>
            </Box>
        </Box>
    )
}
