import { useEffect } from "react"
import { Box, CircularProgress as Spinner } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"
import RoutePaths from "../../constants/Routes"

const AcceptInvite = () => {
    const { loginWithRedirect } = useAuth0()
    const history = useHistory()

    const loginWithInvitation = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        const invitationCode = urlParams.get("invitation")
        const organizationId = urlParams.get("organization")

        if (!invitationCode || !organizationId) {
            return history.push(RoutePaths.LOGIN)
        }
        try {
            await loginWithRedirect({
                authorizationParams: {
                    organization: organizationId,
                    invitation: invitationCode,
                },
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        loginWithInvitation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <Spinner />
        </Box>
    )
}

export default AcceptInvite
