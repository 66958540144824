import React from "react"
import { useHistory } from "react-router"
import { sendRecordRequest } from "../utils/messaging"
import { useAddProjectMutation, useGetProjectsQuery } from "../store/api"
import { selectProject } from "../store/slices/app-slice"
import { useAppDispatch } from "../store/store"
import UpsertProjectPage from "./UpsertProjectPage"
import RoutePaths from "../constants/Routes"

export const CreateProject: React.FC<{ firstProject: boolean }> = ({ firstProject }) => {
    const [open, setOpen] = React.useState(true)
    const [addProject, { isSuccess, data: createdProject }] = useAddProjectMutation()
    const { data: projects } = useGetProjectsQuery()
    const [startRecordingNow, setStartRecordingNow] = React.useState(true)
    const history = useHistory()
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        if (isSuccess) {
            // The project has been added successfully

            if (startRecordingNow) {
                history.push(RoutePaths.TEST_DETAILS)
                sendRecordRequest(createdProject)
            } else {
                history.push(RoutePaths.TEST_CASES)
            }

            if (createdProject) {
                // Select the newly created project so it is now the active project in the UI.
                dispatch(selectProject(createdProject))
            }
        }
    }, [isSuccess, createdProject, dispatch, history, startRecordingNow])

    return (
        <UpsertProjectPage
            firstProject={firstProject}
            open={open}
            projects={projects?.content}
            addProject={addProject}
            dialogSetOpen={setOpen}
            startRecordingNow={startRecordingNow}
            setStartRecordingNow={setStartRecordingNow}
            componentName="CreateProject"
        />
    )
}
