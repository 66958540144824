import * as dateFns from "date-fns"
const hoursStartingIndex = 11
const secondsEndingIndex = 19
/**
 * Stringify date object or timestamp into something human-readable
 *
 * @see {@link https://date-fns.org/docs/format}
 * @param timestamp - timestamp to stringify
 * @param format - format tokens of date to display. Default "LLL dd, yyyy h:mm:ss a".
 * @returns stringified date
 */
export const stringify = (
    timestamp: number | string | Date | null | undefined,
    format = "LLL dd, yyyy h:mm:ss a",
    options?: Parameters<typeof dateFns.format>[2],
): string => {
    let _timestamp: Exclude<typeof timestamp, string>

    if (typeof timestamp === "string") {
        // If it's a string, try casting it to a number and if that doesn't work, try casting it to the date object
        const temporaryTimestamp = Number(timestamp)

        if (isNaN(temporaryTimestamp)) {
            _timestamp = new Date(timestamp)
        } else {
            _timestamp = temporaryTimestamp
        }
    } else {
        _timestamp = timestamp
    }

    if (
        _timestamp === null ||
        _timestamp === undefined ||
        (typeof _timestamp === "number" && isNaN(_timestamp)) ||
        (typeof _timestamp === "object" && isNaN(_timestamp.getTime()))
    ) {
        return ""
    }

    return dateFns.format(_timestamp, format, options)
}

export const getDateFromSeconds = (runTime: number) => {
    return new Date(runTime * 1000).toISOString().slice(hoursStartingIndex, secondsEndingIndex)
}
