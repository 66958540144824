import { createTheme } from "@mui/material"
import { type OverridesStyleRules } from "@mui/material/styles/overrides"
import { type Theme } from "@mui/system"

const trustiinGrey = {
    200: "#F3F6F9",
    300: "#E5EAF2",
    500: "#ECECEC",
    600: "#CCCCCD",
    601: "#989898",
    700: "#545456",
} as const

const hexToRgb = (hex: string) =>
    hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)!
        .map((x) => parseInt(x, 16))
        .join(", ")

declare module "@mui/material/styles" {
    interface Palette {
        trustiinGrey?: typeof trustiinGrey
    }
    interface PaletteOptions {
        trustiinGrey?: PaletteOptions["primary"]
    }
}

declare module "@mui/material/Chip" {
    interface ChipPropsVariantOverrides {
        ghost: true
    }
}

export const colorTheme = createTheme({
    typography: {
        allVariants: {
            color: "#79797B",
        },
    },
    palette: {
        primary: {
            light: "#9ACA67",
            main: "#81BD41",
            dark: "#618E31",
            contrastText: "#fff",
        },
        secondary: {
            light: "#33C2AD",
            main: "#00B398",
            dark: "#008672",
        },
        error: {
            light: "#DF5557",
            main: "#D72A2D",
            dark: "#A12022",
        },
        warning: {
            light: "#DFB35D",
            main: "#D7A034",
            dark: "#A17827",
        },
        info: {
            light: "#849495",
            main: "#79797B",
            dark: "#5B5B5C",
        },
        success: {
            light: "#4caf50",
            main: "#2e7d32",
            dark: "#1b5e20",
        },
        trustiinGrey,
    },
})

const textFieldStyle: Partial<OverridesStyleRules<"root", "MuiTextField" | "MuiFormControl", Theme>>["root"] = ({
    ownerState,
}) => ({
    ...(ownerState.variant === "filled" && {
        "& div:first-of-type": {
            borderRadius: "4px",
            "&::before": { borderBottom: "none" },
            "&:hover::before": { borderBottom: "none" },
            "&::after": { borderBottom: "none" },
        },
    }),
})

export const theme = createTheme(
    {
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: "none",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: colorTheme.palette.trustiinGrey?.[300],
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: textFieldStyle,
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: textFieldStyle,
                },
            },
            MuiChip: {
                // Custom ghost chip variant
                variants: [
                    ...(["primary", "error", "secondary", "info", "success", "warning"] as const).map((color) => ({
                        props: { variant: "ghost" as const, color },
                        style: {
                            color: colorTheme.palette[color].main,
                            backgroundColor: `rgba(${hexToRgb(colorTheme.palette[color].main)}, 0.1)`,
                        },
                    })),
                    {
                        props: { variant: "ghost" as const, color: "default" },
                        style: {
                            color: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                ],
            },
        },
    },
    colorTheme,
)
