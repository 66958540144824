import { useState } from "react"
import Button from "@mui/material/Button"
import { Dialog, Box, Typography, Tooltip, CircularProgress, MenuItem } from "@mui/material"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import DeleteIcon from "@mui/icons-material/Delete"
import { SxProps, Theme } from "@mui/system"

interface DeleteModalProps {
    handleDelete: () => void
    onClose?: () => void
    warningText?: string
    componentName?: string
    disabled?: boolean
    referencedInTestPlan?: boolean
    referencedInTestCase?: boolean
    btnSize?: "small" | "large"
    type?: string
    loading?: boolean
    disabledTooltip?: string
}

const deleteButton: SxProps<Theme> = {
    backgroundColor: "primary.light",
    color: "white",
    "&:hover": {
        backgroundColor: "primary.light",
        color: "white",
    },
}

const buttonStyle: SxProps<Theme> = {
    height: "fit-content",
    paddingTop: "8.5px",
    paddingBottom: "8.5px",
}

export default function ConfirmDeleteModal({
    handleDelete,
    onClose,
    referencedInTestPlan,
    referencedInTestCase,
    componentName,
    disabled,
    type,
    btnSize,
    warningText,
    loading,
    disabledTooltip,
}: DeleteModalProps) {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        onClose?.()
        setOpen(false)
    }
    return (
        <div>
            <Tooltip
                title={
                    referencedInTestPlan
                        ? "Before deleting, first unlink the test from related test plans."
                        : referencedInTestCase
                        ? "Before deleting, first unlink the test from related tests."
                        : disabledTooltip
                        ? disabledTooltip
                        : ""
                }
            >
                <div>
                    {componentName === "plan" ? (
                        <MenuItem
                            onClick={handleClickOpen}
                            disabled={disabled || referencedInTestPlan || referencedInTestCase}
                            disableRipple
                        >
                            <DeleteIcon /> Delete
                        </MenuItem>
                    ) : (
                        <Button
                            variant="outlined"
                            style={{ minWidth: "auto", whiteSpace: "nowrap" }}
                            disabled={disabled || referencedInTestPlan || referencedInTestCase}
                            color="primary"
                            startIcon={<DeleteIcon />}
                            onClick={handleClickOpen}
                            sx={buttonStyle}
                        >
                            DELETE
                        </Button>
                    )}
                </div>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ p: "20px 25px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            mb: "20px",
                        }}
                    >
                        <WarningAmberIcon sx={{ color: "red" }} />
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            align="center"
                            sx={{ color: "red", fontWeight: 900, ml: "10px" }}
                        >
                            Warning
                        </Typography>
                    </Box>
                    <DialogContentText style={{ textAlign: "center" }}>
                        {warningText ||
                            (componentName === "plan"
                                ? "The plan along with its associated test results will be permanently removed. This process cannot be undone. Are you sure you want to proceed?"
                                : "The test along with its associated results will be permanently removed. This process cannot be undone. Are you sure you want to proceed?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", marginTop: "20px", mb: "20px" }}>
                    <Button onClick={handleClose} variant="outlined">
                        CANCEL
                    </Button>
                    {loading ? (
                        <Button
                            disabled
                            sx={deleteButton}
                            color="primary"
                            startIcon={<CircularProgress color="inherit" size={13} />}
                        >
                            DELETING...
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                setOpen(false)
                                handleDelete()
                            }}
                            autoFocus
                            sx={deleteButton}
                        >
                            DELETE
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}
