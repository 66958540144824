import React, { useEffect } from "react"
import {
    Box,
    Button,
    Typography,
    SxProps,
    Theme,
    Paper,
    CircularProgress as Spinner,
    Skeleton,
    Divider,
} from "@mui/material"
import PlayCirceOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import EditIcon from "@mui/icons-material/ModeEdit"
import {
    useGetPlanCasesByProjectIdQuery,
    useUpdatePlanCaseMutation,
    useDeletePlanCaseMutation,
    useGetTenantMetadataQuery,
} from "../../store/api"
import { getShortSchedule, PlanCase } from "../plan-case"
import BrowserIcon from "../../components/BrowserIcon"
import UpsertPlanModal from "../UpsertPlanModal"
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline"
import { useRunning, useRunLoader } from "../../AppContextProvider"
import { setDeletingPlans, getItemFromLocalStorage, removeItemFromLocalStorage } from "../../utils/accessLocalStorage"
import { TestRunInterface } from "../../../src/test-results/test-result"
import { textEllipsis } from "../../utils/index"
import { CustomSwitch } from "../../components/CustomSwitch"
import { SkippedBrowsersOBj } from "../../constants/browserEnum"
import Tooltip from "@mui/material/Tooltip"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal"
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { styled } from "@mui/material/styles"
import Menu, { MenuProps } from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
            "& .MuiCircularProgress-root": {
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}))

const browserListStyle: SxProps<Theme> = {
    display: "flex",
    height: "20px",
    "& > *": {
        marginRight: "5px",
    },
}

const containerStyle: SxProps<Theme> = {
    display: "flex",
    minWidth: 1000,
    flexDirection: "column",
    marginBottom: 5,
}

const summaryStyle: SxProps<Theme> = {
    display: "flex",
    borderWidth: 1,
    borderColor: "trustiinGrey.300",
    p: 3,
}

const planNameCellStyle: SxProps<Theme> = {
    flexGrow: 1,
}

interface PlanCaseInfoProps {
    hasData?: boolean
    planCaseDetails?: PlanCase
    isLoading: boolean
    refetch: () => void
    isEditTestsModalOpen?: boolean
    setIsEditTestsModalOpen?: (status: boolean) => void
    executeRun: (plan: PlanCase, value: boolean) => void
    handleAbortPlanCase: (id: string) => void
    startingRuns: string[]
    allSkippedBrowsers: SkippedBrowsersOBj
}

export const PlanCaseInfo: React.FunctionComponent<PlanCaseInfoProps> = ({
    hasData,
    planCaseDetails,
    isLoading,
    refetch,
    isEditTestsModalOpen = false,
    setIsEditTestsModalOpen,
    executeRun,
    handleAbortPlanCase,
    startingRuns,
    allSkippedBrowsers,
}) => {
    const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
    const [projectId, setProjectId] = React.useState(planCaseDetails?.testProjectId?.toString())
    const [updatePlan] = useUpdatePlanCaseMutation()
    const [deletePlan] = useDeletePlanCaseMutation()
    const { data: tenantMetadata } = useGetTenantMetadataQuery({}, { refetchOnMountOrArgChange: true })

    const [selectedPlanId, setSelectedPlanId] = React.useState("")
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const { abortingRuns } = useRunLoader()

    const { isRunning } = useRunning()

    const plans =
        useGetPlanCasesByProjectIdQuery({ projectId: projectId ?? "" }, { skip: !projectId }).data?.content ?? []
    const planCaseDetailsId = String(planCaseDetails?.id)
    let testRunId = getItemFromLocalStorage(planCaseDetailsId)

    const testRuns: TestRunInterface[] = JSON.parse(getItemFromLocalStorage("testRuns") || "[]")
    const isPlanRunning = testRuns.some((run) => run.planCaseDetailsId === planCaseDetailsId)

    const [enabledStatus, setEnabledStatus] = React.useState<boolean>(planCaseDetails?.enabled || false)
    const handleChangeEnable = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabledStatus(event.target.checked)
        try {
            await updatePlan?.({
                ...planCaseDetails,
                testCases: planCaseDetails?.testCases.map((testCase) => testCase.id),
                enabled: event.target.checked,
                id: planCaseDetails?.id.toString()!,
                projectId: planCaseDetails?.testProjectId.toString()!,
                environmentId: planCaseDetails?.environment.id?.toString()!,
                name: planCaseDetails?.name!,
                scheduleTime: planCaseDetails?.scheduleTime!,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = () => {
        setDeletingPlans(`${planCaseDetails?.id}_${planCaseDetails?.name}`)
        setSelectedPlanId(String(planCaseDetails?.id))
        deletePlan?.({ id: planCaseDetails?.id })
            .unwrap()
            .catch((err) => {
                console.log(err)
            })
    }

    const isDeleting = (planId: string) => {
        const delItems = getItemFromLocalStorage("deletingPlans")
        return !!(delItems?.length && JSON.parse(delItems).find((plan: string) => plan.match(planId)))
    }

    useEffect(() => {
        setEnabledStatus(planCaseDetails?.enabled || false)
    }, [planCaseDetails?.enabled])

    useEffect(() => {
        if (!isRunning && testRunId) {
            handleClose()
            removeItemFromLocalStorage(planCaseDetailsId)
        }
    }, [isRunning, planCaseDetailsId, testRunId])

    const areAllSkipped = Object.keys(allSkippedBrowsers).every(
        (testCase) => (allSkippedBrowsers[testCase] || []).length === (planCaseDetails?.browsers || []).length,
    )

    const areAllBrowsersDisabled = !!planCaseDetails?.browsers?.every(
        (browser) => !tenantMetadata?.allowedBrowsers.includes(browser),
    )

    return (
        <Box sx={containerStyle}>
            <Paper sx={summaryStyle}>
                <Box sx={planNameCellStyle} className="hiddenOverflow">
                    <Typography sx={{ whiteSpace: "nowrap", mb: 1, minWidth: 200 }}>Plan Name</Typography>
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && planCaseDetails && (
                        <Box title={planCaseDetails.name} sx={{ ...textEllipsis, maxWidth: "15vw", margin: "0" }}>
                            {planCaseDetails.name}
                        </Box>
                    )}
                </Box>

                <Box sx={planNameCellStyle} marginLeft={5}>
                    <Typography mb={1}>Description</Typography>
                    {isLoading && <Skeleton width={100} />}
                    {!isLoading && planCaseDetails && (
                        <Box
                            title={planCaseDetails.description}
                            sx={{ ...textEllipsis, maxWidth: "150px", margin: "0" }}
                        >
                            {planCaseDetails.description}
                        </Box>
                    )}
                </Box>
                <Divider sx={{ ml: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                <Box marginLeft={5}>
                    <Typography mb={1}>Browsers</Typography>
                    {isLoading && <Skeleton width={70} />}
                    {!isLoading && planCaseDetails && (
                        <Box sx={browserListStyle}>
                            {planCaseDetails?.browsers?.map((browser: string) => (
                                <Tooltip
                                    key={browser}
                                    title={
                                        !tenantMetadata?.allowedBrowsers.includes(browser)
                                            ? "Upgrade your plan to unlock this feature."
                                            : ""
                                    }
                                >
                                    <Box>
                                        <BrowserIcon
                                            icon={browser}
                                            key={browser}
                                            disabled={!tenantMetadata?.allowedBrowsers.includes(browser)}
                                            sx={{ height: "20px" }}
                                        />
                                    </Box>
                                </Tooltip>
                            ))}
                        </Box>
                    )}
                </Box>
                <Divider sx={{ ml: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                <Box marginLeft={5}>
                    <Typography mb={1}>Schedule</Typography>
                    {isLoading && <Skeleton width={67} />}
                    {!isLoading && planCaseDetails && (
                        <p style={{ maxWidth: "fit-content", whiteSpace: "nowrap", margin: "0" }}>
                            {getShortSchedule(planCaseDetails.schedule)}
                        </p>
                    )}
                </Box>
                <Divider sx={{ ml: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                <Box marginLeft={5}>
                    <Typography mb={1}>Environment</Typography>
                    {isLoading && <Skeleton width={60} />}
                    {!isLoading && planCaseDetails && (
                        <p style={{ maxWidth: "fit-content", margin: "0" }}>{planCaseDetails.environment.name}</p>
                    )}
                </Box>
                <Divider sx={{ ml: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                <Box marginLeft={5}>
                    <Typography mb={1}>Enabled</Typography>
                    {isLoading && <Skeleton width={50} />}
                    {!isLoading && planCaseDetails && (
                        <Tooltip
                            title={
                                areAllBrowsersDisabled
                                    ? "Upgrade your plan to unlock this feature."
                                    : "Toggle the Enabled button to prevent the test plan from running on schedule and to deactivate the RUN PLAN button."
                            }
                        >
                            <Box>
                                <CustomSwitch
                                    checked={enabledStatus && !areAllBrowsersDisabled}
                                    onChange={!areAllBrowsersDisabled ? handleChangeEnable : () => {}}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Box>
                <Divider sx={{ ml: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                <Box marginLeft={5}>
                    {isPlanRunning ? (
                        <>
                            {abortingRuns.includes(String(planCaseDetails?.id)) ? (
                                <Button
                                    color="error"
                                    variant="contained"
                                    disabled={!hasData || !planCaseDetails?.enabled}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <Spinner size={"1rem"} style={{ marginRight: "10px" }} />
                                    Aborting...
                                </Button>
                            ) : (
                                <Button
                                    color="error"
                                    variant="contained"
                                    disabled={!hasData}
                                    startIcon={<PauseCircleOutlineIcon />}
                                    onClick={() => {
                                        handleAbortPlanCase(String(planCaseDetails?.id))
                                    }}
                                >
                                    RUNNING..
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            {startingRuns.includes(String(planCaseDetails?.id)) ? (
                                <Button
                                    variant="outlined"
                                    disabled={!hasData || !planCaseDetails?.enabled}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <Spinner size={"1rem"} style={{ marginRight: "10px" }} />
                                    Queueing...
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    disabled={!hasData || !enabledStatus || areAllSkipped || areAllBrowsersDisabled}
                                    startIcon={<PlayCirceOutlineIcon />}
                                    onClick={() => {
                                        if (planCaseDetails) {
                                            executeRun(planCaseDetails, isPlanRunning)
                                        }
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    Run Plan
                                </Button>
                            )}
                        </>
                    )}
                </Box>
                <div>
                    <IconButton sx={{ ml: 5 }} onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <MenuItem
                            onClick={() => {
                                setIsEditModalOpen(true)
                                handleClose()
                            }}
                            disableRipple
                        >
                            <EditIcon />
                            Edit
                        </MenuItem>
                        {selectedPlanId === String(planCaseDetails?.id) || isDeleting(String(planCaseDetails?.id)) ? (
                            <MenuItem onClick={handleClose} disableRipple>
                                <Spinner color="inherit" size={13} />
                                Deleting...
                            </MenuItem>
                        ) : (
                            <ConfirmDeleteModal
                                onClose={handleClose}
                                handleDelete={handleDelete}
                                disabled={isPlanRunning || startingRuns.includes(String(planCaseDetails?.id))}
                                componentName={"plan"}
                            />
                        )}
                    </StyledMenu>
                </div>
            </Paper>
            <UpsertPlanModal
                {...{ updatePlan, plans }}
                open={isEditModalOpen || isEditTestsModalOpen}
                onlyTestCases={isEditTestsModalOpen}
                handleClose={() => {
                    setIsEditModalOpen(false)
                    setIsEditTestsModalOpen?.(false)
                }}
                onSuccessfulSubmit={refetch}
                componentName="UpdatePlanModal"
                projectState={[projectId, setProjectId]}
                planData={planCaseDetails}
                currentPlanDetail={planCaseDetails}
            />
        </Box>
    )
}
