import React, { useContext, useState, useEffect } from "react"
import PusherNetwork from "./pusher/pusher"
import { useGetPusherMetaDataQuery } from "./store/api"
import { appSelector } from "./store/slices/app-slice"
import { useSelector } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"

const AppContext = React.createContext<{
    pusherNetwork?: PusherNetwork
    isRunning: boolean
    setRunning?: React.Dispatch<React.SetStateAction<boolean>>
    abortingRuns: string[]
    setAbortingRuns: React.Dispatch<React.SetStateAction<string[]>>
}>({
    isRunning: false,
    setRunning: () => {},
    abortingRuns: [],
    setAbortingRuns: () => {},
})

const AppContextProvider = (childrenProp: {
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
}) => {
    const { authToken } = useSelector(appSelector)
    const { isAuthenticated } = useAuth0()

    const [pusherNetwork, setPusherNetwork] = useState<PusherNetwork>()
    const [isRunning, setRunning] = useState(false)
    const [abortingRuns, setAbortingRuns] = React.useState<string[]>([])

    const { data: pusherMetadata } = useGetPusherMetaDataQuery({}, { skip: !isAuthenticated || !authToken })
    useEffect(() => {
        if (pusherMetadata) {
            setPusherNetwork(new PusherNetwork(pusherMetadata))
        }
    }, [pusherMetadata])

    const context = { pusherNetwork, isRunning, setRunning, abortingRuns, setAbortingRuns }

    return <AppContext.Provider value={context}>{childrenProp.children}</AppContext.Provider>
}

export const usePusherNetwork = (): PusherNetwork | undefined => {
    const { pusherNetwork } = useContext(AppContext)
    return pusherNetwork
}

export const useRunning = () => {
    const { isRunning, setRunning } = useContext(AppContext)
    return { isRunning, setRunning: setRunning as React.Dispatch<React.SetStateAction<boolean>> }
}

export const useRunLoader = () => {
    const { abortingRuns, setAbortingRuns } = useContext(AppContext)
    return { abortingRuns, setAbortingRuns: setAbortingRuns as React.Dispatch<React.SetStateAction<string[]>> }
}

export default AppContextProvider
