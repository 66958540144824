const setItemInLocalStorage = (itemName: string, value: string) => {
    window.localStorage.setItem(itemName, value)
}

const getItemFromLocalStorage = (itemName: string) => {
    const savedItem = window.localStorage.getItem(itemName)
    return savedItem ?? undefined
}

const removeItemFromLocalStorage = (itemName: string) => {
    window.localStorage.removeItem(itemName)
}

const setDeletingPlans = (value: string) => {
    const items: string[] = localStorage.getItem("deletingPlans")
        ? JSON.parse(localStorage.getItem("deletingPlans")!)
        : []
    localStorage.setItem("deletingPlans", JSON.stringify([...items, value]))
}

const setDeletingRun = (value: string) => {
    const items: string[] = localStorage.getItem("deletingRuns")
        ? JSON.parse(localStorage.getItem("deletingRuns")!)
        : []
    localStorage.setItem("deletingRuns", JSON.stringify([...items, value]))
}

const setDeletingProjects = (value: string) => {
    const items: string[] = localStorage.getItem("deletingProjects")
        ? JSON.parse(localStorage.getItem("deletingProjects")!)
        : []
    localStorage.setItem("deletingProjects", JSON.stringify([...items, value]))
}

export {
    setItemInLocalStorage,
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
    setDeletingProjects,
    setDeletingPlans,
    setDeletingRun,
}
