import { Project } from "./project"
import { Link, useHistory } from "react-router-dom"
import { Box, Button, Menu, MenuItem, MenuList, SxProps, Theme, Tooltip } from "@mui/material"
import { useSelector } from "react-redux"
import { appSelector, selectProjectById } from "../store/slices/app-slice"
import { useAppDispatch } from "../store/store"
import { useGetProjectsQuery, useGetTenantMetadataQuery } from "../store/api"
import { textEllipsis } from "../utils/commonCSS"
import RoutePaths from "../constants/Routes"
import AddIcon from "@mui/icons-material/Add"
import Check from "@mui/icons-material/Check"
import { CaretDown } from "@phosphor-icons/react"
import usePusherSubscription from "../hooks/usePusherSubscription"
import Alert from "../components/Alert"
import React from "react"

function createMenuItems(projects: Project[] | undefined, changeProject: (projectId?: string) => void) {
    if (!projects) {
        return null
    }

    return [...projects]
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((project, i) => (
            <MenuItem key={i} onClick={() => changeProject(project.id)}>
                {project.name}
            </MenuItem>
        ))
}

function getSelectedProjectName(project: Project | undefined): string | undefined {
    if (project) {
        return project.name
    }
}

export const ProjectMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const { data: projectsData, refetch, isLoading: isLoadingProjects } = useGetProjectsQuery()
    const {
        data: tenantMetadata,
        isFetching: fetchingMetaData,
        isLoading,
    } = useGetTenantMetadataQuery({}, { refetchOnMountOrArgChange: true })

    const { selectedProject } = useSelector(appSelector)
    const dispatch = useAppDispatch()
    const [openAlert, setOpenAlert] = React.useState(false)
    const projects = projectsData?.content
    const history = useHistory()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const changeProject = (projectId?: string) => {
        setAnchorEl(null)
        let currentPathName = window.location.pathname
        if (currentPathName.includes("test-details")) {
            history.push(RoutePaths.TEST_CASES)
        } else if (currentPathName.includes("plans")) {
            history.push(RoutePaths.PLANS)
        } else if (currentPathName.includes("results")) {
            history.push(RoutePaths.RESULTS)
        }
        dispatch(selectProjectById({ selectedProjectId: projectId, projects }))
    }

    const menuItems = createMenuItems(projects, changeProject)

    const menuButtonStyle: SxProps<Theme> = {
        justifyContent: "flex-start",
        color: "trustiinGrey.dark",
        "& button": {},
    }

    const menuButtonTextStyle: SxProps<Theme> = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "75vw",
        borderColor: "trustiinGrey.300 !important",
        display: "inline-block",
        textTransform: "none",
        fontSize: "larger",
    }

    const menuStyle: SxProps<Theme> = {
        padding: 0,

        "& a": {
            textDecoration: "none",
            display: "block",
            "& li": {
                color: "#000",
            },
        },
        "& li": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: 200,
            width: "100%",
            display: "block",
        },
    }

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    const selectedProjectName = getSelectedProjectName(selectedProject)

    const onSuccess = () => {
        setOpenAlert(true)
        refetch()
    }

    const alertMessage = usePusherSubscription(onSuccess)

    const canNotCreateProject =
        isLoading ||
        isLoadingProjects ||
        fetchingMetaData ||
        (tenantMetadata && projects && projects?.length >= tenantMetadata?.noOfProjects)

    return (
        <>
            <Alert open={openAlert} message={alertMessage} handleClose={handleAlertClose} severity={"info"} />
            <Box sx={{ marginLeft: "2rem" }}>
                <Button
                    id="projects-button"
                    aria-controls="projects-menu"
                    aria-haspopup="true"
                    startIcon={
                        <Box
                            sx={{
                                width: 25,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 25,
                                background: "#E9F1DE",
                                borderRadius: 50,
                            }}
                        >
                            <CaretDown size={20} weight="thin" />
                        </Box>
                        // <KeyboardArrowDownIcon style={{ width: 32, height: 32, background: "#E9F1DE", borderRadius: 50 }} />
                    }
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={menuButtonStyle}
                >
                    <Box sx={menuButtonTextStyle}>{selectedProjectName}</Box>
                </Button>
                <Menu
                    id="projects-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={menuStyle}
                    MenuListProps={{
                        "aria-labelledby": "projects-button",
                    }}
                >
                    <Box sx={{ height: 309, overflow: "scroll" }}>
                        {menuItems?.map((item) => (
                            <MenuItem
                                disabled={selectedProjectName === menuItems[Number(item.key)]?.props.children}
                                key={item.key}
                                sx={{ ...textEllipsis, p: 0, m: 0, maxWidth: "300px", display: "flex !important" }}
                                title={menuItems[Number(item.key)].props.children}
                            >
                                <MenuList sx={{ m: 0, p: 0, width: "100%" }}>{item}</MenuList>
                                {selectedProjectName === menuItems[Number(item.key)]?.props.children && (
                                    <Check sx={{ m: "2px" }} />
                                )}
                            </MenuItem>
                        ))}
                    </Box>

                    <Box p={1} sx={{ boxShadow: "-3px -8px 15px -3px rgba(0,0,0,0.1)" }}>
                        <Tooltip title={canNotCreateProject ? "Upgrade your plan to unlock this feature. " : ""}>
                            <Link
                                style={{ paddingInline: 5, marginBottom: -3 }}
                                to={canNotCreateProject ? "#" : RoutePaths.CREATE_PROJECT}
                            >
                                <Button
                                    startIcon={<AddIcon />}
                                    disableElevation
                                    onClick={handleClose}
                                    size="small"
                                    fullWidth
                                    variant="contained"
                                    disabled={canNotCreateProject}
                                >
                                    Create Project
                                </Button>
                            </Link>
                        </Tooltip>
                    </Box>
                </Menu>
            </Box>
        </>
    )
}
