import React from "react"
import { Box, Breadcrumbs, SxProps, Theme, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useGetPlanCaseByIdQuery } from "../store/api"
import { PlanCaseInfo } from "./component/PlanCaseInfo"
import { EditTests } from "./component/EditTests"
import { CustomDataGrid } from "../components/grid/CustomDataGrid"
import { date, textEllipsis } from "../utils"
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid"
import { TestCaseMenu } from "./component/TestCaseMenu"
import { Browser, SkippedBrowsersOBj } from "../constants/browserEnum"
import { PlanCase } from "../plan-cases/plan-case"
import { AppLink } from "../components/AppLink"
import RoutePaths from "../constants/Routes"

interface PlanDetailsPageParams {
    id?: string
}
const planStepsContainerStyle: SxProps<Theme> = {
    display: "flex",
    flexGrow: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "trustiinGrey.300",
}
interface PlanDetailsPageProps {
    executeRun: (plan: PlanCase, value: boolean) => void
    handleAbortPlanCase: (id: string) => void
    startingRuns: string[]
    setPlanCaseDetailsId: (id: string) => void
}

export const PlanDetailsPage = ({
    executeRun,
    handleAbortPlanCase,
    startingRuns,
    setPlanCaseDetailsId,
}: PlanDetailsPageProps) => {
    const { id } = useParams<PlanDetailsPageParams>()
    const hasPlanDetails = id !== undefined && id !== null
    const {
        data: planCaseDetails,
        isUninitialized,
        isLoading,
        refetch,
    } = useGetPlanCaseByIdQuery(id ?? "", { refetchOnMountOrArgChange: true })

    const hasData = hasPlanDetails && planCaseDetails !== null && planCaseDetails !== undefined
    const [isEditTestsModalOpen, setIsEditTestsModalOpen] = React.useState(false)

    const [allSkippedBrowsers, setAllSkippedBrowsers] = React.useState<SkippedBrowsersOBj>(
        planCaseDetails?.skippedBrowsers || {},
    )

    React.useEffect(() => {
        if (planCaseDetails?.id) {
            setPlanCaseDetailsId(String(planCaseDetails?.id))
        }
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, planCaseDetails?.id])
    const headerColumns: GridColDef[] = [
        {
            field: "testSummary",
            headerName: "Test Summary",
            flex: 2,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box title={params.value} sx={{ ...textEllipsis, maxWidth: "900px" }}>
                        {params.value}
                    </Box>
                )
            },
        },
        {
            field: "creator",
            headerName: "Created By",
            width: 150,
            editable: true,
            flex: 0.5,
        },
        {
            field: "lastUpdate",
            headerName: "Last Update",
            width: 200,
            editable: true,
            valueGetter: (params: GridValueGetterParams) => date.stringify(params.row.lastEditTimestamp),
            flex: 0.5,
        },
        {
            field: "menus",
            headerName: "Skipping Browsers",
            sortable: false,
            width: 150,
            align: "right",
            renderCell: (params: GridRenderCellParams) => (
                <TestCaseMenu
                    initialBrowsers={(planCaseDetails?.skippedBrowsers?.[params.row.id] as Browser[]) ?? []}
                    testCaseId={params.row.id}
                    planId={planCaseDetails!.id.toString()}
                    planBrowsers={planCaseDetails!.browsers as Browser[]}
                    key={params.row.id}
                    setAllSkippedBrowsers={setAllSkippedBrowsers}
                />
            ),
        },
    ]

    return (
        <>
            <Breadcrumbs sx={{ mb: 2 }}>
                <AppLink underline="hover" to={RoutePaths.PLANS}>
                    Plans
                </AppLink>
                <Typography title={planCaseDetails?.name} sx={{ ...textEllipsis, maxWidth: "300px" }}>
                    {planCaseDetails?.name}
                </Typography>
            </Breadcrumbs>
            <PlanCaseInfo
                {...{
                    planCaseDetails,
                    hasData,
                    refetch,
                    isEditTestsModalOpen,
                    setIsEditTestsModalOpen,
                    executeRun,
                    handleAbortPlanCase,
                    startingRuns,
                    allSkippedBrowsers,
                    isLoading: isUninitialized || isLoading,
                }}
            />
            <EditTests hasData={hasData} onClick={() => setIsEditTestsModalOpen(true)} />
            <Box sx={planStepsContainerStyle}>
                {!planCaseDetails || !hasData || planCaseDetails.testCases === null ? (
                    <CustomDataGrid columns={headerColumns} loading={isUninitialized || isLoading} />
                ) : (
                    <CustomDataGrid
                        columns={headerColumns}
                        rows={planCaseDetails.testCases}
                        loading={isUninitialized || isLoading}
                    />
                )}
            </Box>
        </>
    )
}
