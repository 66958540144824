import { Link, LinkProps } from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

export interface AppLinkProps extends LinkProps {
    to: string
}

export const AppLink: React.FunctionComponent<AppLinkProps> = (props) => {
    const { to, ...otherProps } = props

    return (
        <Link component={RouterLink} to={to} {...otherProps}>
            {props.children}
        </Link>
    )
}
