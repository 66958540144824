import * as React from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertProps } from "@mui/material/Alert"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface SnackbarProps {
    message: string
    open: boolean
    handleClose?: () => void
    severity?: "success" | "error" | "info" | "warning"
    horizontal?: "center" | "left" | "right"
    vertical?: "top" | "bottom"
}
export default function CustomizedSnackbar({
    message,
    open,
    handleClose = () => {},
    severity = "success",
    vertical = "top",
    horizontal = "center",
}: SnackbarProps): JSX.Element | null {
    if (!message) return null
    return (
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    )
}
