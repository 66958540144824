import { Typography, Paper, Box, Skeleton, SxProps, Theme, Divider } from "@mui/material"

interface IProps {
    [key: string]: SxProps<Theme>
}
export default function PlanResultsPageHeaderLoader({ summaryHeaderStyle, dividedCell }: IProps) {
    return (
        <Paper sx={summaryHeaderStyle}>
            <Box>
                <Typography>Plan Summary</Typography>
                <Box sx={{ textAlign: "center", py: "18px" }} className="alignInMiddle">
                    <Skeleton width={150} />
                </Box>
            </Box>
            <Box>
                <Typography>Environment</Typography>
                <Box sx={{ textAlign: "center", py: "18px" }} className="alignInMiddle">
                    <Skeleton width={70} />
                </Box>
            </Box>
            <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
            <Box sx={dividedCell}>
                <Typography>Status</Typography>
                <Box sx={{ textAlign: "center", py: "18px" }} className="alignInMiddle">
                    <Skeleton width={80} />
                </Box>
            </Box>
            <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
            <Box sx={dividedCell}>
                <Typography>Browser</Typography>
                <Box sx={{ textAlign: "center", py: "18px" }} className="alignInMiddle">
                    <Skeleton width={50} />
                </Box>
            </Box>
            <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
            <Box sx={dividedCell}>
                <Typography>Started Time</Typography>
                <Box sx={{ textAlign: "center", py: "18px" }} className="alignInMiddle">
                    <Skeleton width={150} />
                </Box>
            </Box>
            <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
            <Box sx={dividedCell}>
                <Typography>Runtime</Typography>
                <Box sx={{ textAlign: "center", py: "18px" }} className="alignInMiddle">
                    <Skeleton width={100} />
                </Box>
            </Box>
        </Paper>
    )
}
