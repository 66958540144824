import { Switch, styled } from "@mui/material"
import { ChangeEvent } from "react"
import { SwitchProps } from "react-router-dom"

type CustomSwitchProps = {
    checked: boolean
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange }) => {
    const CustomSwitchStyle = styled((props: SwitchProps & CustomSwitchProps) => <Switch {...props} />)(
        ({ theme }) => ({
            width: 33,
            height: 18,
            padding: 3,
            "& .MuiSwitch-switchBase": {
                padding: 2,
                margin: 3,
                "&.Mui-checked": {
                    transform: "translateX(15px)",
                    color: "#fff",
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            },
            "& .MuiSwitch-thumb": {
                boxSizing: "border-box",
                width: 8,
                height: 8,
            },
            "& .MuiSwitch-track": {
                backgroundColor: theme.palette.trustiinGrey?.[700],
                opacity: 0.3,
            },
        }),
    )

    return <CustomSwitchStyle checked={checked} onChange={onChange} />
}
