import React from "react"
import { Box, Button, Card, CardContent, CircularProgress } from "@mui/material"
import { TestStepImageMetadata } from "../test-cases/test-case"
import { ResultStatus } from "../test-results/test-result"
import { Browser } from "../constants/browserEnum"
import { PhotoProvider, PhotoView } from "react-photo-view"
import { styled } from "@mui/system"
import { RemoveRedEyeOutlined } from "@mui/icons-material"
import "react-photo-view/dist/react-photo-view.css"
import fallbackImg from "../images/placeholder.svg"
import generatingImg from "../images/generatingImg.svg"
import skippedImg from "../images/skippedImg.svg"

const ImgCard = styled(Card)({
    position: "relative",
    width: "100%",
    "&:hover": {
        "& img": {
            opacity: 0.3,
        },
        "& .imgOverlay": {
            opacity: 1,
        },
    },
    "& img": {
        opacity: 1,
        display: "block",
        width: "100%",
        height: "auto",
        transition: ".5s ease",
        backfaceVisibility: "hidden",
    },
    "& .imgOverlay": {
        transition: ".5s ease",
        opacity: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
})

type ComponentName = "BaseLine" | "TestResult" | "TestDetails"
export interface TestStepImageProps {
    componentName?: ComponentName
    testCaseId?: string
    resultId?: string
    stepNumber?: string
    metadata?: TestStepImageMetadata
    doubleHeight?: boolean
    fallbackSrc?: string
    browser: Browser
    // stepResult is to show error or skipped/aborted message.
    stepResult?: ResultStatus
}

/**
 * A component which displays a test step image focused on the area provided in the image metadata.
 */
export const TestStepImage: React.FunctionComponent<TestStepImageProps> = (props) => {
    const [imageError, setImageError] = React.useState<boolean>(false)
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false)

    // Ensure that whenever the props of the component change, the new image is loaded and drawn on the canvas.
    const imgSrc = React.useMemo(() => {
        if (
            props &&
            props.testCaseId &&
            props.resultId &&
            props.stepNumber !== null &&
            props.stepNumber !== undefined
        ) {
            setImageError(false)
            return props.componentName === "BaseLine"
                ? `/api/test-cases/${props.testCaseId}/results/${props.resultId}/browser/chrome/image/${props.stepNumber}.png`
                : `/api/test-cases/${props.testCaseId}/results/${props.resultId}/browser/${props.browser}/image/${props.stepNumber}.png`
        }
        return fallbackImg
    }, [props])

    return (
        <>
            {checkDataToDisplayImage(props, imageError) ? (
                <PhotoProvider>
                    <PhotoView src={imgSrc}>
                        <ImgCard sx={{ borderColor: imageLoaded ? "none" : "transparent" }}>
                            <CardContent
                                sx={{
                                    padding: 1,
                                    paddingBottom: "8px !important",
                                    cursor: "pointer",
                                    display: imageLoaded ? "block" : "none",
                                }}
                            >
                                {imgSrc && (
                                    <img
                                        src={imgSrc || fallbackImg}
                                        style={{ width: "100%" }}
                                        onLoad={() => setImageLoaded(true)}
                                        onError={() => setImageError(true)}
                                        alt={"test"}
                                    />
                                )}
                                <Box sx={{ display: imageLoaded ? "block" : "none" }} className="imgOverlay">
                                    <Button
                                        startIcon={<RemoveRedEyeOutlined />}
                                        color="inherit"
                                        variant="contained"
                                        size="small"
                                    >
                                        view
                                    </Button>
                                </Box>
                            </CardContent>
                            {!imageLoaded && (
                                <CardContent
                                    sx={{
                                        padding: 0,
                                        paddingBottom: "0px !important",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress sx={{ m: 10 }} color="primary" />
                                </CardContent>
                            )}
                        </ImgCard>
                    </PhotoView>
                </PhotoProvider>
            ) : (
                getSkippedOrLoadingMessage(props)
            )}
        </>
    )
}

const getSkippedOrLoadingMessage = (props: TestStepImageProps) => {
    return props.stepResult !== ResultStatus.Failed &&
        (props.stepResult === ResultStatus.Skipped || props.stepResult === ResultStatus.Aborted) ? (
        <img src={skippedImg} style={{ width: "100%", border: "solid 1px gainsboro" }} alt="generating" />
    ) : (
        <img src={generatingImg} style={{ width: "100%", border: "solid 1px gainsboro" }} alt="generating" />
    )
}

/**
 * Show image only if the step result is not skipped or aborted and other values are there in the prop, and if the image is found
 */
const checkDataToDisplayImage = (props: React.PropsWithChildren<TestStepImageProps>, imageError: boolean) => {
    return (
        props.fallbackSrc ||
        // props.stepResult === ResultStatus.Running ||
        props.stepResult === ResultStatus.Passed ||
        (props.testCaseId &&
            props.resultId &&
            props.stepNumber !== null &&
            props.stepNumber !== undefined &&
            props.stepResult !== ResultStatus.Skipped &&
            props.stepResult !== ResultStatus.Aborted &&
            !imageError)
    )
}
