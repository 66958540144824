/* eslint-disable @typescript-eslint/no-unused-vars */
import { SxProps, Theme } from "@mui/system"
import { Box } from "@mui/material"
import React, { useState } from "react"
import { Route, Switch } from "react-router"
import { CreateProject } from "../projects/CreateProjectPage"
import { TestCasesPage } from "../test-cases/TestCasesPage"
import { TestDetailsPage } from "../test-cases/TestDetailsPage"
import { PlanCasesPage } from "../plan-cases/PlanCasesPage"
import { PlanDetailsPage } from "../plan-cases/PlanDetailsPage"
import { UpdateProjectPage } from "../settings/UpdateProjectPage"
import { TestResultsListPage } from "../test-results/TestResultsListPage"
import { PlanResultPage } from "../test-results/PlanResultPage"
import { PlanResultDetailsPage } from "../test-results/PlanResultDetails"
import { usePusherNetwork, useRunning, useRunLoader } from "../AppContextProvider"
import { ResultAlertMessages, TestRunInterface } from "../../src/test-results/test-result"
import Alert from "../components/Alert"
import { setItemInLocalStorage, getItemFromLocalStorage } from "../utils/accessLocalStorage"
import { useAbortPlanCaseMutation, useExecuteTestPlanMutation } from "../store/api"
import { PlanCase } from "../plan-cases/plan-case"
import SignUp from "../components/auth0/SignUp"
import RoutePaths from "../constants/Routes"
import PrivateRoute from "./PrivateRoute"
import CustomLogin from "./auth0/CustomLogin"
import { useLocation } from "react-router-dom"
import AcceptInvite from "./auth0/AcceptInvite"
import usePusherDelPlanSubscription from "../hooks/usePusherDelPlanSubscription"

export const MainContent = () => {
    const location = useLocation()
    const isSignUpRoute = location.pathname.includes("/signup")

    const containerStyle: SxProps<Theme> = !isSignUpRoute
        ? {
              flexGrow: 1,
              padding: "1rem",
              bgcolor: "trustiinGrey.200",
              ml: "100px",
              height: "100vh",
              minWidth: 1000,
              overflowX: "scroll",
              paddingTop: "100px",
          }
        : {
              flexGrow: 1,
              height: "100vh",
              display: "flex",
              alignItems: "center",
              bgcolor: "rgba(100, 100, 100, 0.3)",
          }
    const pusherNetwork = usePusherNetwork()

    usePusherDelPlanSubscription((message: string) => {
        setAlertMessage(message)
        setOpenAlert(true)
    })

    const { setRunning } = useRunning()
    const { setAbortingRuns } = useRunLoader()
    const [startingRuns, setStartingRuns] = useState([] as string[])
    const [alertMessage, setAlertMessage] = useState("")
    const [openAlert, setOpenAlert] = useState(false)
    const [planCaseDetailsId, setPlanCaseDetailsId] = useState("")

    const [abortPlanCase] = useAbortPlanCaseMutation()
    const [executeTestPlan] = useExecuteTestPlanMutation()

    const testRuns: TestRunInterface[] = JSON.parse(getItemFromLocalStorage("testRuns") || "[]")
    // @ts-ignore
    const subscriptionHandler = async (event) => {
        const testRuns: TestRunInterface[] = JSON.parse(getItemFromLocalStorage("testRuns") || "[]")
        const currentRun = testRuns.find((run) => run.testRunId === event?.detail?.test_run_id)
        if (currentRun) {
            setRunning(event?.detail?.event_type === "completed" ? false : true)
            if (event?.detail?.event_type === "completed") {
                setAbortingRuns((curr) => curr.filter((id) => id !== currentRun?.planCaseDetailsId))
                const testRunsCopy = testRuns.filter((run) => run.testRunId !== event?.detail?.test_run_id)
                setAlertMessage(
                    ResultAlertMessages.Completed.replace("{Test plan name}", currentRun.placeCaseDetailsName),
                )
                setItemInLocalStorage("testRuns", JSON.stringify(testRunsCopy))
                setRunning(false)
                setOpenAlert(true)
            } else {
                setRunning(true)
            }
        }
    }
    const executeRun = (planCase: PlanCase, isPlanRunning: boolean) => {
        if (isPlanRunning) {
            return
        }
        if (planCase.id !== undefined) {
            setStartingRuns((curr) => [...curr.filter((id) => id !== planCase.id.toString()), planCase.id.toString()])
            executeTestPlan(planCase.id.toString())
                .unwrap()
                .then((testRunId: string) => {
                    const testRunObj = {
                        testRunId,
                        placeCaseDetailsName: planCase.name,
                        planCaseDetailsId: planCase.id,
                    }
                    setItemInLocalStorage("testRuns", JSON.stringify([...testRuns, testRunObj]))
                    setRunning(true)
                    setStartingRuns((curr) => curr.filter((id) => id !== planCase.id.toString()))
                })
                .catch(() => {
                    setRunning(false)
                    setStartingRuns((curr) => curr.filter((id) => id !== planCase.id.toString()))
                })
        }
    }

    const handleAbortPlanCase = async (planId: string) => {
        const testPlan = testRuns.find((plan) => plan.planCaseDetailsId === planId)
        if (!testPlan) {
            return
        }
        setAbortingRuns((curr) => [...curr.filter((id) => id !== planId), planId])
        try {
            await abortPlanCase([testPlan?.testRunId])
        } catch (err) {
            setAbortingRuns((curr) => curr.filter((id) => id !== planId))
        }
    }

    React.useEffect(
        () => {
            if (pusherNetwork) {
                window.addEventListener("trustiinEvent", subscriptionHandler as EventListener)
                return () => window.removeEventListener("trustiinEvent", subscriptionHandler as EventListener)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pusherNetwork, planCaseDetailsId],
    )

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    return (
        <Box sx={containerStyle}>
            <Alert open={openAlert} message={alertMessage} handleClose={handleAlertClose} severity={"info"} />

            <Switch>
                <PrivateRoute exact path={RoutePaths.HOME} component={TestCasesPage} />
                <PrivateRoute path={RoutePaths.RESULTS_DETAILS} component={PlanResultDetailsPage} />
                <PrivateRoute path={RoutePaths.PLAN_RESULTS} component={PlanResultPage} />
                <PrivateRoute path={RoutePaths.TEST_RUN_RESULTS} component={PlanResultPage} />
                <PrivateRoute path={RoutePaths.RESULTS} component={TestResultsListPage} />
                <PrivateRoute
                    path={RoutePaths.CREATE_PROJECT}
                    component={CreateProject}
                    componentProps={{
                        firstProject: false,
                    }}
                />
                <PrivateRoute path={RoutePaths.TEST_CASES} component={TestCasesPage} />
                <PrivateRoute exact path={RoutePaths.TEST_DETAILS} component={TestDetailsPage} />
                <PrivateRoute path={RoutePaths.TEST_DETAILS + "/:id"} component={TestDetailsPage} />
                <PrivateRoute
                    exact
                    path={RoutePaths.PLANS}
                    component={PlanCasesPage}
                    componentProps={{
                        handleAbortPlanCase,
                        executeRun,
                        startingRuns,
                    }}
                />
                <PrivateRoute
                    path={RoutePaths.PLAN_DETAILS}
                    component={PlanDetailsPage}
                    componentProps={{
                        handleAbortPlanCase,
                        executeRun,
                        startingRuns,
                        setPlanCaseDetailsId,
                    }}
                />
                <PrivateRoute path={RoutePaths.SETTINGS} component={UpdateProjectPage} />
                <Route exact path={RoutePaths.LOGIN}>
                    <CustomLogin />
                </Route>
                <Route exact path={RoutePaths.ACCEPT_INVITE}>
                    <AcceptInvite />
                </Route>
                <Route exact path={RoutePaths.SIGN_UP}>
                    <SignUp />
                </Route>
            </Switch>
        </Box>
    )
}
