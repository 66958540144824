import React from "react"
import { useHistory, useLocation } from "react-router"
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    Stack,
    IconButton,
    InputAdornment,
    Tooltip,
    FormGroup,
    MenuItem,
} from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { PageTitle } from "../components/PageTitle"
import LoadingButton from "@mui/lab/LoadingButton"
import DeleteIcon from "@mui/icons-material/Delete"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import { Project } from "./project"
import { selectProject } from "../store/slices/app-slice"
import { useAppDispatch } from "../store/store"
import WarningModal from "./WarningModal"
import { useAddProjectMutation, useUpdateProjectMutation } from "../store/api"
import * as zod from "zod"
import { FieldArray, Form, Formik, type FormikConfig, type FormikErrors, type FormikProps } from "formik"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { CheckboxInput, SelectInput, TextInput } from "../components/form"
import { array, object } from "../utils"

type ComponentName = "UpdateProjectPage" | "CreateProject"

const getFormSchema = (projects: Project[], projectIndex?: number) =>
    zod.object({
        name: zod
            .string()
            .max(100, "Project name must be less than 100 characters.")
            .refine((name) => {
                const sameName = projects.filter(
                    (project, index) => project.name === name && index !== projectIndex,
                ).length
                const hasError = sameName > 0

                return !hasError
            }, "Project name must be unique."),
        description: zod.string().optional(),
        environments: zod.array(
            zod.object({
                name: zod.string(),
                url: zod
                    .string()
                    .url()
                    .regex(/^http(s)?:/, "Malformed URL"),
                incognito: zod
                    .boolean()
                    .nullable()
                    .transform((arg) => (arg === undefined || arg === null ? true : arg))
                    .optional(),
            }),
        ),
        shouldStartRecording: zod.boolean(),
    })

type FormValues = ReturnType<typeof getFormSchema>["_output"]

const initialValues: FormValues = {
    name: "",
    description: "",
    environments: [{ name: "", url: "" }],
    shouldStartRecording: true,
}

type UpsertProjectFormProps = {
    formikProps: FormikProps<FormValues>
    dialogSetOpen: (value: boolean) => void
    componentName: ComponentName
    firstProject: boolean
}

const UpsertProjectForm: React.FC<UpsertProjectFormProps> = ({
    formikProps,
    dialogSetOpen,
    componentName,
    firstProject: isFirstProject,
}) => {
    // If undefined, hide modal, if a number is assigned, that is the index to delete
    const [removeEnvIndex, setRemoveEnvIndex] = React.useState<number>()
    const history = useHistory()
    const location = useLocation()

    const handleCancelButton = () => {
        if (componentName === "CreateProject") {
            history.goBack()
        } else {
            history.push(location.pathname)
            dialogSetOpen(false)
        }
    }

    const hasEnvOrUrlError = Boolean(formikProps.errors.environments && formikProps.errors.environments.length > 0)
    const isLastEnvTouchedOrFilled =
        formikProps.touched.environments?.[formikProps.values.environments.length - 1]?.name === true &&
        formikProps.touched.environments?.[formikProps.values.environments.length - 1]?.url === true

    return (
        <Form>
            <Box sx={{ display: "flex", flexDirection: "column", gap: ".75rem" }}>
                <TextInput name="name" label="Project Name *" variant="standard" />
                <TextInput
                    name="description"
                    id="project-description"
                    variant="filled"
                    label="Description"
                    multiline
                    minRows={5}
                />
                <FieldArray name="environments">
                    {(fieldArrayHelpers) => (
                        <>
                            <WarningModal
                                showRemoveEnvironmentWarning={removeEnvIndex !== undefined}
                                onNoClick={() => setRemoveEnvIndex(undefined)}
                                onYesClick={() => {
                                    fieldArrayHelpers.remove(removeEnvIndex!)
                                    setRemoveEnvIndex(undefined)
                                }}
                                text="If you remove this environment, any test plan associated with it will be disabled. Are you sure you want to do this?"
                                title="Warning"
                            />
                            {formikProps.values.environments.map((_, index) => (
                                <Grid key={index} justifyContent="center" marginBottom="2vh">
                                    <FormGroup row sx={{ marginLeft: 0 }}>
                                        <Grid item width="35%">
                                            <TextInput
                                                name={`environments[${index}].name`}
                                                variant="filled"
                                                sx={{
                                                    "& div:first-of-type": {
                                                        borderTopRightRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                    },
                                                }}
                                                InputProps={{ sx: { borderRight: "solid 1px rgba(0, 0, 0, 0.20)" } }}
                                                label={`Environment ${index === 0 ? "*" : ""}`}
                                            />
                                        </Grid>
                                        <Grid item width="65%">
                                            <TextInput
                                                name={`environments[${index}].url`}
                                                variant="filled"
                                                sx={{
                                                    "& div:first-of-type": {
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                    },
                                                }}
                                                fullWidth
                                                label={`URL ${index === 0 ? "*" : ""}`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" sx={{ display: "flex" }}>
                                                            {index === 0 ? (
                                                                <Tooltip title="The default environment for recording.">
                                                                    <IconButton
                                                                        edge="end"
                                                                        sx={{ color: "trustiinGrey.600" }}
                                                                    >
                                                                        <InfoIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <IconButton
                                                                    onClick={() => setRemoveEnvIndex(index)}
                                                                    edge="end"
                                                                    sx={{ color: "trustiinGrey.600" }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                            ))}
                            <Stack direction="column" justifyContent="center" alignItems="center">
                                <Tooltip
                                    title={
                                        hasEnvOrUrlError
                                            ? isLastEnvTouchedOrFilled
                                                ? "Fix the errors above to add a new environment."
                                                : "Fill in all fields to create a new environment."
                                            : "Click + to add more environments."
                                    }
                                >
                                    {/* Span is used to ensure tooltip shows because a disabled button cannot fire events back to the parent */}
                                    <span>
                                        <IconButton
                                            aria-label="edit"
                                            size="large"
                                            onClick={() => fieldArrayHelpers.push({ name: "", url: "" })}
                                            disabled={hasEnvOrUrlError}
                                        >
                                            <AddCircleIcon
                                                sx={{
                                                    color: hasEnvOrUrlError ? "" : "primary.main",
                                                    size: "35px",
                                                }}
                                                fontSize="inherit"
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Stack>
                        </>
                    )}
                </FieldArray>

                {/* Hide window-size and incognito mode for now; not supported for MVP */}
                <div style={{ display: "none" }}>
                    <SelectInput
                        id="windowSize"
                        name="windowSize"
                        variant="standard"
                        label="Window Size"
                        defaultValue={1}
                    >
                        <MenuItem value={1}>1920 x 1080</MenuItem>
                    </SelectInput>
                    <CheckboxInput name="incognito" label="Use Incognito/Private Mode" />
                </div>

                <Box sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {componentName === "CreateProject" && (
                            <CheckboxInput name="shouldStartRecording" label="Start Recording Now" />
                        )}
                    </Box>
                    <Box>
                        <Button
                            variant="outlined"
                            sx={{ mr: 2 }}
                            disabled={isFirstProject}
                            onClick={handleCancelButton}
                        >
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" loading={formikProps.isSubmitting}>
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Form>
    )
}

type UpsertProjectPageProps = {
    firstProject: boolean
    open: boolean
    addProject?: ReturnType<typeof useAddProjectMutation>[0]
    dialogSetOpen: (value: boolean) => void
    startRecordingNow?: boolean
    setStartRecordingNow?: (value: boolean) => void
    projectData?: Project
    componentName: ComponentName
    updateProject?: ReturnType<typeof useUpdateProjectMutation>[0]
    projects?: Project[]
    indexOfSelectedProject?: number
}

const UpsertProjectPage = ({
    firstProject,
    open,
    addProject,
    projects,
    indexOfSelectedProject,
    dialogSetOpen,
    setStartRecordingNow,
    projectData,
    componentName,
    updateProject,
}: UpsertProjectPageProps) => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const HTTP_DUPLICATE_STATUS = 409

    const handleBackdropClose = (): void => {
        // Don't want to close the modal when the backdrop is clicked if it is a firstProject.
        if (!firstProject && componentName === "CreateProject") {
            dialogSetOpen(false)
            history.push("/")
        } else if (componentName === "UpdateProjectPage") {
            history.push(location?.pathname)
            dialogSetOpen(false)
        }
    }

    const onSubmit: FormikConfig<FormValues>["onSubmit"] = async (formValues, formikHelpers) => {
        setStartRecordingNow?.(formValues.shouldStartRecording)

        const response =
            componentName === "CreateProject"
                ? await addProject?.(formValues)
                : await updateProject?.({ ...{ ...(projectData ?? {}), envs: undefined }, ...formValues })

        if (response && "error" in response) {
            const { status: errorStatus } = response.error as { status: number }

            if (errorStatus === HTTP_DUPLICATE_STATUS) {
                formikHelpers.setFieldError("name", "Project name must be unique")

                return
            }
        }

        if (componentName === "UpdateProjectPage" && response && "data" in response) {
            dispatch(selectProject(response.data))
        }
        dialogSetOpen(false)
    }

    const extraValidation: FormikConfig<FormValues>["validate"] = (formValues) => {
        const errors: FormikErrors<FormValues> = {}

        const envNames = formValues.environments.map(({ name }) => name)
        const duplicateIndices = array.getDuplicateIndices(envNames)
        const envErrors: { name: string }[] = []

        for (const index of duplicateIndices) {
            envErrors[index] = { name: "Environment name must be unique." }
        }

        // Assignment of errors must happen like so
        // If a property exists in the errors object, Formik will block submit, even if that property is `undefined`
        if (envErrors.length > 0) {
            errors.environments = envErrors
        }

        return errors
    }

    return (
        <Dialog open={open} onClose={handleBackdropClose} disableEscapeKeyDown={true}>
            <DialogContent>
                {componentName === "CreateProject" ? (
                    <PageTitle text="New Project" center={true} />
                ) : (
                    <PageTitle text="Edit Project" center={true} />
                )}
                <Formik
                    validate={extraValidation}
                    onSubmit={onSubmit}
                    initialValues={
                        projectData
                            ? {
                                  ...initialValues,
                                  ...object.pick(projectData, "name", "description", "environments"),
                              }
                            : initialValues
                    }
                    enableReinitialize
                    validationSchema={toFormikValidationSchema(getFormSchema(projects ?? [], indexOfSelectedProject))}
                    validateOnMount
                >
                    {(formikProps) => (
                        <UpsertProjectForm {...{ formikProps, dialogSetOpen, componentName, firstProject }} />
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default UpsertProjectPage
