import React from "react"
import { SxProps, Theme, Box, Tab, FormControl, Grid, Paper, Tooltip } from "@mui/material"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { useGetProjectsQuery, useGetTenantMetadataQuery } from "../store/api"
import AddIcon from "@mui/icons-material/Add"
import LoadingButton from "@mui/lab/LoadingButton"
import { useUpdateProjectMutation } from "../store/api"
import { Link } from "react-router-dom"
import ListViewPage from "../components/ListViewPage"
import RoutePaths from "../constants/Routes"
import UsageDataTable from "../components/UsageDataTable"
import { getItemFromLocalStorage, setItemInLocalStorage } from "../utils/accessLocalStorage"
import usePusherSubscription from "../hooks/usePusherSubscription"

export const UpdateProjectPage: React.FunctionComponent = () => {
    const [value, setValue] = React.useState<string>("1")
    const [open, setOpen] = React.useState<boolean>(false)
    const [updateProject] = useUpdateProjectMutation()
    const [page, setPage] = React.useState(1)
    const [size, setSize] = React.useState(25)

    const {
        data: projects,
        isLoading,
        refetch,
    } = useGetProjectsQuery(
        {
            page,
            size,
            sortBy: "lastEditTimestamp,DESC",
        },
        { refetchOnMountOrArgChange: true },
    )
    const {
        data: tenantMetadata,
        isFetching: fetchingMetaData,
        isLoading: isLoadingMetadata,
    } = useGetTenantMetadataQuery({}, { refetchOnMountOrArgChange: true })

    const onSuccess = () => {
        refetch()
    }

    usePusherSubscription(onSuccess)

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        setItemInLocalStorage("tabValue", newValue)
    }

    React.useEffect(() => {
        let tabValue = getItemFromLocalStorage("tabValue")!
        if (tabValue) {
            setValue(getItemFromLocalStorage("tabValue")!)
        } else {
            setValue("1")
        }
    }, [value])

    const canNotCreateProject =
        isLoading ||
        isLoadingMetadata ||
        fetchingMetaData ||
        (tenantMetadata && projects && projects?.content?.length >= tenantMetadata?.noOfProjects)

    return (
        <Grid container spacing={0} direction="column" alignItems="center" style={{ maxHeight: "100vh" }}>
            <Box sx={{ width: "100%" }}>
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: "none",
                            justifyContent: "space-between",
                            display: "flex",
                        }}
                    >
                        <TabList onChange={handleChangeTab} sx={{ justifyContent: "flex-start !important" }}>
                            <Tab label="Projects" value="1" />
                            {/* <Tab label="Item Two" value="2" /> */}
                            <Tab label="Plans" value="3" />
                        </TabList>
                        <Box sx={linkStyle}>
                            <Tooltip title={canNotCreateProject ? "Upgrade your plan to unlock this feature. " : ""}>
                                <Link to={canNotCreateProject ? "#" : RoutePaths.CREATE_PROJECT}>
                                    <FormControl variant="standard">
                                        <LoadingButton
                                            sx={{ whiteSpace: "nowrap", bgcolor: "common.white" }}
                                            type="submit"
                                            variant="outlined"
                                            disabled={canNotCreateProject}
                                            startIcon={<AddIcon />}
                                        >
                                            NEW PROJECT
                                        </LoadingButton>
                                    </FormControl>
                                </Link>
                            </Tooltip>
                        </Box>
                    </Box>
                    <TabPanel sx={{ px: 0, maxHeight: "calc(100vh - 250px)" }} value="1">
                        <Box sx={{ justifyContent: "center", pb: 5 }}>
                            <ListViewPage
                                isLoading={isLoading}
                                page={page}
                                setPage={setPage}
                                size={size}
                                setSize={setSize}
                                componentName="UpdateProjectPage"
                                search=""
                                headers={[
                                    "Project Name",
                                    "Created By",
                                    "Last Update",
                                    <Box sx={{ ml: "auto", textAlign: "right" }}>Action</Box>,
                                ]}
                                projects={projects}
                                updateProject={updateProject}
                                open={open}
                                setOpen={setOpen}
                            />
                        </Box>
                    </TabPanel>
                    {/* <TabPanel value="2">Item Two</TabPanel> */}
                    <TabPanel sx={{ px: 0 }} value="3">
                        <Paper
                            sx={{
                                display: "flex",
                                borderWidth: 0,
                                backgroundColor: "trustiinGrey.200",
                            }}
                        >
                            <UsageDataTable />
                        </Paper>
                    </TabPanel>
                </TabContext>
            </Box>
        </Grid>
    )
}

const linkStyle: SxProps<Theme> = {
    padding: 0,
    "& a": {
        textDecoration: "none",
        display: "block",
    },
}
