import React from "react"
import { Box, Button, Typography, Paper, SxProps, Theme } from "@mui/material"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"

const summaryStyle: SxProps<Theme> = {
    display: "flex",
    p: 2,
    borderStyle: "none",
}

const summaryCellStyle: SxProps<Theme> = {
    mr: 3,
}

const planSummaryCellStyle: SxProps<Theme> = {
    ...summaryCellStyle,
    flexGrow: 1,
}

export interface EditTestsProps {
    hasData: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const EditTests: React.FC<EditTestsProps> = ({ hasData, onClick }) => (
    <Paper sx={summaryStyle}>
        <Box sx={planSummaryCellStyle}>
            <Typography>Selected Tests</Typography>
        </Box>
        <Button variant="outlined" disabled={!hasData} startIcon={<PlaylistAddIcon />} onClick={onClick}>
            Edit Tests
        </Button>
    </Paper>
)
