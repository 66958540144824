import { ResultStatus, TestCaseResultDTO } from "../test-results/test-result"

interface TestScript {
    steps: Array<Action | TestSequence>
}

export interface TestCase {
    id: string
    testProjectId: number
    testSummary: string
    testScript: TestScript
    creator: string
    createTimestamp: number
    lastEditTimestamp: number
    baselineRunId?: string
    isTestSequence: boolean
    referencedInTestCase: boolean
    referencedInTestPlan: boolean
}
export interface TestSequence {
    testSequence: TestCase
    command: null
}

export enum LocatorStrategy {
    ID = "id",
    LINK = "link",
    NAME = "name",
    ELEMENT = "dom:name",
    RELATIVE_LINK = "xpath:link",
    RELATIVE_IMAGE = "xpath:img",
    RELATIVE_ATTRIBUTE = "xpath:attributes",
    RELATIVE_ID = "xpath:idRelative",
    RELATIVE_NEIGHBOR = "xpath:neighbor",
    RELATIVE_REFERENCE = "xpath:href",
    INDEX = "dom:index",
    XPATH = "xpath:position",
    CSS = "css",
}

export interface Locator {
    strategy: LocatorStrategy
    target: string
}

interface ActionElement {
    tag: string
    label: string
    type: string
    class_name: string
    height: number
    width: number
    location_x: number
    location_y: number
    value: string
    parent: string
    sibling: string[]
    sibling_location: number
    text?: string
}

enum AssertionType {
    NOT_ENDS_WITH = "does-not-end-with",
    NOT_CONTAINS = "does-not-contain",
    NOT_EQUAL = "does-not-equal",
    NOT_PRESENT = "is-not-present",
    NOT_START_WITH = "does-not-start-with",

    ENDS_WITH = "ends-with",
    CONTAINS = "contains",
    EQUALS = "equals",
    PRESENT = "is-present",
    STARTS_WITH = "starts-with",
}

enum AssertionAttribute {
    ELEMENT = "element",
    INNER_TEXT = "innerText",
    TEXT_CONTENT = "textContent",
}
interface AssertionData {
    type: AssertionType
    attribute: AssertionAttribute
    expected_value?: string
}

/**
 * Represents an action that the recorder recorded.
 */
export interface Action {
    /**
     * The type of action. e.g. click, type, etc.
     */
    command: string
    element?: ActionElement
    locators: Array<Locator>
    value: string
    frameLocation: string
    assertion?: AssertionData
    testSequence: TestCaseResultDTO
    ordinal: string
    result?: ResultStatus
}

export interface TestStepImageMetadata {
    focus_left: number
    focus_top: number
    focus_width: number
    focus_height: number
    snapshot: string
}

export enum CheckOptions {
    CHECKED = "is checked",
    NOT_CHECKED = "is not checked",
}

export interface MessageType {
    extensionVersion: string
    incognitoAllowed: boolean
}
