import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

export default function StepperLoader() {
    const width = (base: number, add: number) => Math.floor(Math.random() * base) + add

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={0} orientation="vertical" sx={{ marginBottom: "10px" }}>
                {Array.from({ length: 7 }).map((step, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={() => <Skeleton variant="circular" width={25} height={25} />}>
                            <Skeleton variant="text" width={width(200, 150)} sx={{ fontSize: "1.33rem" }} />
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}
