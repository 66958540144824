import { ResultStatus } from "../test-result"

export { default as BrowserFilter } from "./BrowserFilter"
export { default as EnvironmentFilter } from "./EnvironmentFilter"
export { default as PlansFilter } from "./PlansFilter"
export { default as StartedTimeFilter, type Options as StartedTimeOptions, type DateRange } from "./StartedTimeFilter"
export { default as StatusFilter } from "./StatusFilter"

export const statusFilterOptions: [display: string, actual: ResultStatus][] = [
    ["Passed", ResultStatus.Passed],
    ["Failed", ResultStatus.Failed],
    ["Passed with Warning", ResultStatus.PassedWithWarning],
    ["Failed with Warning", ResultStatus.FailedWithWarning],
    ["Running", ResultStatus.Running],
    ["Aborted", ResultStatus.Aborted],
    ["Skipped", ResultStatus.Skipped],
]
