import chromeIcon from "../images/chrome-icon.png"
import safariIcon from "../images/safari-icon.png"
import firefoxIcon from "../images/firefox-icon.png"
import edgeIcon from "../images/edge-icon.png"

import { Browser } from "../constants/browserEnum"
import { styled } from "@mui/material"

const BrowserIconComponent: React.FC<{
    icon: Browser | string
    className?: string
    disabled?: boolean
}> = ({ icon, ...props }) => {
    const styles = {
        filter: props.disabled ? "grayscale(100%)" : "none",
    }
    switch (icon.toLowerCase() as Browser) {
        case "chrome":
            return <img {...props} src={chromeIcon} alt="chrome icon" style={styles} />
        case "firefox":
            return <img {...props} src={firefoxIcon} alt="firefox icon" style={styles} />
        case "safari":
            return <img {...props} src={safariIcon} alt="safari icon" style={styles} />
        case "edge":
            return <img {...props} src={edgeIcon} alt="edge icon" style={styles} />
        default:
            throw new Error(`Unknown browser icon ${icon}`)
    }
}

export const BrowserIcon = styled(BrowserIconComponent)({})

export default BrowserIcon
