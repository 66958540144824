import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Project } from "../../projects/project"
import { RootState } from "../store"
import { TenantMetadataInterface } from "../api"
import { setItemInLocalStorage, getItemFromLocalStorage } from "../../utils/accessLocalStorage"

// This slice is used for the "root application state", which is any state that is required
// to initialize the application, or any other global state where only primitive values are required.

export interface AppState {
    selectedProject?: Project
    authToken?: string
    popupError: string
    recorderEnabled: boolean
    recorderRunning: boolean
    tenantMetaData: TenantMetadataInterface | null
}

export const initialState: AppState = {
    popupError: "",
    recorderEnabled: false,
    recorderRunning: false,
    tenantMetaData: null,
}

const cacheName = "trustiin.tta.projectId"

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setPopupError: (state, action: PayloadAction<string>) => {
            state.popupError = action.payload
        },
        clearPopupError: (state) => {
            state.popupError = ""
        },
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
        },
        selectProjectById: (state, action: PayloadAction<{ selectedProjectId?: string; projects?: Project[] }>) => {
            if (action.payload.projects && action.payload.selectedProjectId) {
                state.selectedProject = action.payload.projects.find(
                    (project) => project.id === action.payload.selectedProjectId,
                )
                setItemInLocalStorage(cacheName, state.selectedProject?.id!)
            }
        },
        selectProject: (state, action: PayloadAction<Project>) => {
            state.selectedProject = action.payload
            setItemInLocalStorage(cacheName, state.selectedProject?.id!)
        },
        initializeSelectedProject: (state, action: PayloadAction<Project[]>) => {
            if (state.selectedProject) {
                // A project is already selected. No initialization required.
                return
            }

            const projectIdFromLocalStorage = getItemFromLocalStorage(cacheName)
            if (projectIdFromLocalStorage) {
                const projectFromCache = action.payload.filter(
                    (project: Project) => project?.id === projectIdFromLocalStorage,
                )
                if (projectFromCache[0]) {
                    state.selectedProject = projectFromCache[0]
                    return
                }
            }
            if (action.payload && action.payload.length > 0) {
                // For now the first project is the initial selected project.
                state.selectedProject = action.payload[0]
            }
        },
        recorderEnabled: (state, action: PayloadAction<boolean>) => {
            state.recorderEnabled = action.payload
        },
        recorderActive: (state, action: PayloadAction<boolean>) => {
            state.recorderRunning = action.payload
        },
        setTenantMetaData: (state, action: PayloadAction<TenantMetadataInterface>) => {
            state.tenantMetaData = action.payload
        },
    },
})

export const {
    setPopupError,
    clearPopupError,
    setAuthToken,
    selectProjectById,
    initializeSelectedProject,
    selectProject,
    recorderEnabled,
    recorderActive,
    setTenantMetaData,
} = appSlice.actions

export const appReducer = appSlice.reducer

export const appSelector = (state: RootState) => state.app

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authToken = (state: any) => (state as RootState).app.authToken
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tenantMetaData = (state: any) => (state as RootState).app.tenantMetaData
