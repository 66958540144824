/**
 * Picks values from an object and creates a new object
 *
 * @example
 *
 * ```ts
 * pick({a: 1, b: 2, c: 3}, "a", "b") // {a: 1, b: 2}
 * ```
 *
 * @typeParam T - Type of the object to pick items from
 * @typeParam K - Type of the keys used to pick out items
 * @param obj - Object to pick keys from
 * @param keys - Keys to pick
 * @returns Object from picked values
 */
export const pick = <T extends {}, K extends (keyof T)[]>(obj: T, ...keys: K): Pick<T, (typeof keys)[number]> => {
    const newObj = {} as Pick<T, K[number]>

    for (const key of keys) {
        if (key in obj) {
            newObj[key] = obj[key]
        }
    }

    return newObj
}

/** filterObject predicate for removing empty arrays, strings, as well as undefined and null properties */
export const removeEmpty = <T extends {}>(value: T[keyof T], _key: keyof T, _object: T): boolean => {
    if ((value instanceof Array || typeof value === "string") && value.length === 0) {
        return false
    }

    return value !== undefined && value !== null
}

type FilterObject = {
    <T extends {}, S extends T[keyof T]>(
        obj: T,
        predicate: (value: T[keyof T], key: keyof T, object: T) => value is S,
    ): {
        [Key in keyof T]: T[Key] extends S ? S : never
    }
    // S param is there to keep typescript happy, doesn't actually serve a functional purpose
    /* eslint-disable @typescript-eslint/no-unused-vars */
    <T extends {}, S extends T[keyof T]>(obj: T, predicate?: undefined): T
    <T extends {}, S extends T[keyof T]>(obj: T, predicate: (value: T[keyof T], key: keyof T, object: T) => unknown): T
    /* eslint-enable @typescript-eslint/no-unused-vars */
}

/**
 * Filters an object as if it were an array, and creates a new object without mutating the original one
 *
 * @param obj - object to filter
 * @param predicate - function to filter with. If falsey, remove from the object, else keep it
 * @returns new object `obj` filtered with `predicate`
 */
export const filter: FilterObject = (obj, predicate = (val) => val !== undefined) => {
    for (const key of Object.keys(obj) as (keyof typeof obj)[]) {
        if (!predicate(obj[key], key, obj)) {
            delete obj[key]
        }
    }

    return obj
}
