import React, { useState } from "react"
import { Box, Tab } from "@mui/material"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import ByPlan from "./ByPlan"

export const TestResultsListPage: React.FC = () => {
    const [tabValue, setTabValue] = useState("1")

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (
        <TabContext value={tabValue ?? "1"}>
            <Box
                sx={{
                    borderBottom: "none",
                    justifyContent: "space-between",
                    display: "flex",
                }}
            >
                <TabList onChange={handleTabChange}>
                    <Tab label="By Plan" value="1" />
                </TabList>
            </Box>
            <TabPanel sx={{ px: 0 }} value="1">
                <ByPlan />
            </TabPanel>
        </TabContext>
    )
}

export default TestResultsListPage
