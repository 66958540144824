import { TimePicker, TimePickerProps } from "@mui/x-date-pickers/TimePicker"
import * as Formik from "formik"
import * as React from "react"
import { TextField, TextFieldProps } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

export type TimeInputProps = Omit<
    JSX.IntrinsicElements["input"] & TextFieldProps & Formik.FieldConfig<Date | null>,
    "ref"
> & {
    timePickerProps?: Partial<TimePickerProps<Date, Date>>
    /**
     * Omit feedback text, but still turn red if error
     * @default false
     */
    noFeedback?: boolean
}

export const TimeInput = ({ noFeedback = false, timePickerProps = {}, ...props }: TimeInputProps): JSX.Element => {
    const [field, meta, helpers] = Formik.useField(props)
    const errorText = meta.error && meta.touched ? meta.error : undefined
    return (
        // The DateFn adapter is not compatible with timezone hance the need to use a compatible library for this component
        // It is an on going discussion with material ui so we may hance to change it back to datefn in the future
        // https://mui.com/x/react-date-pickers/timezone/
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                mask="__:__"
                ampm={false}
                {...timePickerProps}
                {...field}
                onChange={(val) => {
                    helpers.setValue(val)

                    timePickerProps.onChange?.(val)
                }}
                value={field.value}
                renderInput={(params) => (
                    <Formik.Field
                        onChange={undefined}
                        {...params}
                        {...props}
                        as={TextField}
                        error={Boolean(errorText)}
                        helperText={noFeedback ? undefined : errorText}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

export default TimeInput
