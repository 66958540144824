export enum Browser {
    Chrome = "chrome",
    Firefox = "firefox",
    Edge = "edge",
    Safari = "safari",
}

export const browserFilterOptions: [display: string, actual: Browser][] = [
    ["chrome", Browser.Chrome],
    ["firefox", Browser.Firefox],
    ["edge", Browser.Edge],
    ["safari", Browser.Safari],
]

export interface SkippedBrowsersOBj {
    [key: string]: string[]
}
