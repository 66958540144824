import { Modal, Stack, Button, Box, Divider } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import Avatar from "@mui/material/Avatar"
import { useAuth0 } from "@auth0/auth0-react"
import { useGetTenantMetadataQuery } from "../../store/api"
import { stringAvatar } from "../../utils/string"
import { Theme } from "@mui/material"
import { SxProps } from "@mui/system"

type SignOutType = {
    closeModal: (value: boolean) => void
}

const SignOut = ({ closeModal }: SignOutType) => {
    const { logout, user } = useAuth0()

    function signOutClickHandler() {
        logout({ logoutParams: { returnTo: window.location.origin } })
    }
    const { data: tenantMetadata } = useGetTenantMetadataQuery({}, {})

    const avatarStyle: SxProps<Theme> = {
        backgroundColor: "#81BD41",
        width: 90,
        height: 90,
        fontSize: "70px",
    }

    return (
        <Modal
            open={true}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                    borderRadius: "10px",
                    width: 400,
                    outline: 0,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    sx={{ marginLeft: "-20px" }}
                >
                    <>
                        <Avatar sx={avatarStyle}>{stringAvatar(user?.email || "")}</Avatar>
                        <div id="parent-modal-title" style={{ color: "#9ACA67", fontSize: "23px", marginTop: 0 }}>
                            <p style={{ marginTop: "10px" }}>{tenantMetadata?.tenantName}</p>
                        </div>
                        <div id="parent-modal-description" style={{ color: "#9ACA67", marginTop: 0 }}>
                            <p style={{ marginTop: "0px" }}>{user?.email}</p>
                        </div>
                    </>
                </Stack>

                <Divider sx={{ marginTop: "10px" }} variant="middle" />
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    {
                        <Button variant="outlined" onClick={() => signOutClickHandler()}>
                            <LogoutIcon />
                            Sign out
                        </Button>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default SignOut
