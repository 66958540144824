import { TestCase } from "../test-cases/test-case"
import { Browser } from "../constants/browserEnum"

interface Environment {
    id?: number
    testProjectId: number
    incognito: boolean
    creator: string
    createTimestamp: number
    lastEditTimestamp: number
    url: string
    attached: boolean
    name: string
}

type SkippedTestCasesBrowsers = {
    [planCaseId: string]: Browser[]
}

export interface PlanCase {
    id: number
    testProjectId: number
    name: string
    environment: Environment
    creator: string
    createTimestamp: number
    lastEditTimestamp: number
    description: string
    testCases: Array<TestCase>
    enabled: boolean
    browsers: Array<string>
    skippedBrowsers: SkippedTestCasesBrowsers
    schedule: string[]
    scheduleTime: string
}

export const scheduleDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const scheduleMap: { [key: string]: string } = {
    Sunday: "Sun",
    Monday: "M",
    Tuesday: "Tu",
    Wednesday: "W",
    Thursday: "Th",
    Friday: "F",

    Saturday: "Sat",
}

const scheduleSort: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
}

/**
 * Processes array of days for plan case schedule
 *
 * @param schedule - array of days
 * @param shortenLimit - max number of days before shortening the days
 * @returns joined string of days
 */
export const getShortSchedule = (schedule: string[] = [], shortenLimit = 1): string => {
    if (schedule.includes("Every day")) {
        return "Every day"
    } else if (schedule.length === 0) {
        return "None"
    } else if (schedule.length <= shortenLimit) {
        return schedule.join(", ")
    }

    return new Array(...schedule) // Schedule array indexes have been frozen somewhere and are readonly
        .sort((day1, day2) => scheduleSort[day1] - scheduleSort[day2])
        .map((day) => scheduleMap[day] ?? day)
        .join(", ")
}
