import { Box, Breadcrumbs, Paper, Skeleton, SxProps, Theme, Typography, CircularProgress, Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { AppLink } from "../components/AppLink"
import ListViewPage from "../components/ListViewPage"
import { SearchInput } from "../components/SearchInput"
import {
    useDeleteTestResultsMutation,
    useGetPlanCaseByIdQuery,
    useGetTestResultByTestRunIdQuery,
    useGetTestRunsByFilterQuery,
} from "../store/api"
import { StatusFilter, BrowserFilter, statusFilterOptions } from "./filters"
import { useDebounce } from "../hooks"
import { getChipFromResultStatus, ResultStatus } from "./test-result"
import { Browser, browserFilterOptions } from "../constants/browserEnum"
import { BrowserIcon } from "../components/BrowserIcon"
import { date, textEllipsis } from "../utils"
import { getItemFromLocalStorage, setDeletingRun, setItemInLocalStorage } from "../utils/accessLocalStorage"
import RoutePaths from "../constants/Routes"
import PlanResultsPageHeaderLoader from "../components/PlanResultsPageHeaderLoader"
import queryString from "query-string"
import { v4 as keyGen } from "uuid"
import ConfirmDeleteModal from "../components/ConfirmDeleteModal"
import usePusherDeleteRuns from "../hooks/usePusherDeleteRuns"

const dividedCell: SxProps<Theme> = {
    "& .MuiBox-root": {
        py: 2,
        height: "60px",
        borderTopStyle: "solid",
        borderTopWidth: "2px",
        borderTopColor: "trustiinGrey.300",
        "&:first-of-type": {
            borderTopColor: "#0000",
        },
    },
}

const summaryHeaderStyle: SxProps<Theme> = {
    display: "flex",
    minWidth: 1000,
    border: 1,
    borderColor: "trustiinGrey.300",
    p: 2,
    pb: 0,
    mt: 2,
    "& > .MuiBox-root": {
        ":nth-of-type(1)": {
            width: "35%",
        },
        ":nth-of-type(2)": {
            width: "15%",
        },
        ":nth-of-type(3)": {
            width: "10%",
        },
        ":nth-of-type(4)": {
            width: "10%",
        },
        ":nth-of-type(5)": {
            width: "20%",
        },
        ":nth-of-type(6)": {
            width: "10%",
        },
    },
    "& .alignInMiddle": {
        position: "relative",
        left: "50%",
        top: "35%",
        transform: "translate(-50%, -40%)",
        paddingRight: 2,
    },
}

export const PlanResultPage: React.FC = () => {
    const { planId, testRunId } = useParams<{ planId?: string; testRunId: string }>()
    const [selectedPlanId, setSelectedPlanId] = useState("")
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(25)
    const [searchTestSummary, setSearchTestSummary] = useState("")
    const { value: debounceSearch, isWaiting } = useDebounce(searchTestSummary)
    const { data: plan } = useGetPlanCaseByIdQuery(planId ?? "", { skip: !planId })
    const [deleteTestResults] = useDeleteTestResultsMutation()
    const location = useLocation()

    const projectId = plan?.testProjectId?.toString()
    const {
        data: testRuns,
        isUninitialized,
        isLoading: loadingTestRuns,
    } = useGetTestRunsByFilterQuery(
        {
            projectId: projectId ?? "",
            testRunId: testRunId,
            startDate: 0,
            page: 0,
            size: 1,
        },
        {
            skip: plan?.testProjectId === undefined || !testRunId,
            refetchOnMountOrArgChange: true,
        },
    )

    const testResultSummary = testRuns?.content[0]

    //Handle Query Search
    function capitalizeString(input: string): string {
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
    }
    const parsed = queryString.parseUrl(location.search)

    //browser Filters
    const browsersArray = (parsed.query.browsers as string)?.split(",") || []
    const mappedBrowsersArray = browsersArray.map(
        (browserStr) => Browser[capitalizeString(browserStr) as keyof typeof Browser] as Browser,
    )
    //status Filters
    const statusesArray = (parsed.query.status as string)?.split(",") || []
    const mappedStatusesArray = statusesArray.map(
        (statusStr) => ResultStatus[capitalizeString(statusStr) as keyof typeof ResultStatus] as ResultStatus,
    )

    const { data: results, isLoading } = useGetTestResultByTestRunIdQuery(
        {
            testRunId,
            page,
            size,
            search: debounceSearch,
            browsers: mappedBrowsersArray,
            statuses: mappedStatusesArray,
        },
        {
            refetchOnMountOrArgChange: true,
        },
    )

    const handleSearchTestName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchTestSummary(event.target.value)
        setItemInLocalStorage(planId?.concat("SearchTestName") as string, event?.target?.value)
    }

    const loading = isUninitialized || loadingTestRuns || isLoading

    useEffect(() => {
        if (page > 1) {
            setPage(1)
        }
        //we don't need the page dependence
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const isDeleting = (planId: string) => {
        const delItems = getItemFromLocalStorage("deletingRuns")
        return !!(delItems?.length && JSON.parse(delItems).find((plan: string) => plan.match(planId)))
    }

    const handleDeleteResults = (testRunId: string) => {
        setSelectedPlanId(testRunId)
        setDeletingRun(testRunId)
        deleteTestResults?.({ id: testRunId }).unwrap()
    }

    usePusherDeleteRuns(() => {})

    const isRunning = testResultSummary?.testResult.some((result) => result.resultStatus === "RUNNING")

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <AppLink underline="hover" to={RoutePaths.RESULTS}>
                    Results
                </AppLink>
                <Typography title={plan?.name} sx={{ ...textEllipsis, maxWidth: "600px" }}>
                    {plan?.name || <Skeleton width={100} />}
                </Typography>
            </Breadcrumbs>
            {loading && (
                <PlanResultsPageHeaderLoader summaryHeaderStyle={summaryHeaderStyle} dividedCell={dividedCell} />
            )}
            {!loading && testResultSummary ? (
                <Paper sx={summaryHeaderStyle}>
                    <Box>
                        <Typography>Plan Summary</Typography>
                        <Box
                            title={testResultSummary.testPlanName}
                            className="alignInMiddle"
                            sx={{ ...textEllipsis, ml: "1px", maxWidth: "600px" }}
                        >
                            {testResultSummary.testPlanName}
                        </Box>
                    </Box>
                    <Box>
                        <Typography>Environment</Typography>
                        <Box className="alignInMiddle">{testResultSummary.environmentName}</Box>
                    </Box>
                    <Box sx={dividedCell}>
                        <Typography>Status</Typography>
                        {testResultSummary.testResult.map((result) => (
                            <Box key={keyGen()}>{getChipFromResultStatus(result.resultStatus)}</Box>
                        ))}
                    </Box>
                    <Box sx={dividedCell}>
                        <Typography>Browser</Typography>
                        {testResultSummary.testResult.map((result) => (
                            <Box key={keyGen()}>
                                <BrowserIcon icon={result.browser} sx={{ height: "25px" }} />
                            </Box>
                        ))}
                    </Box>
                    <Box sx={dividedCell}>
                        <Typography>Started Time</Typography>
                        {testResultSummary.testResult.map((_result) => (
                            <Box key={keyGen()}>{date.stringify(testResultSummary.testResult[0]?.startTime)}</Box>
                        ))}
                    </Box>
                    <Box sx={dividedCell}>
                        <Typography>Run Time</Typography>
                        {testResultSummary.testResult.map(({ runTime }) => (
                            <Box key={keyGen()}>
                                {runTime === undefined ? undefined : date.getDateFromSeconds(runTime)}
                            </Box>
                        ))}
                    </Box>
                    <Box sx={dividedCell}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {selectedPlanId === String(testRunId) || isDeleting(String(testRunId)) ? (
                                <Button
                                    variant="outlined"
                                    disabled
                                    style={{ minWidth: "auto", whiteSpace: "nowrap" }}
                                    color="primary"
                                    startIcon={<CircularProgress color="inherit" size={13} />}
                                >
                                    DELETING...
                                </Button>
                            ) : (
                                <ConfirmDeleteModal
                                    type="PLAN_RESULTS"
                                    warningText="This process cannot be undone. Are you sure you want to proceed?"
                                    handleDelete={() => handleDeleteResults(testRunId)}
                                    disabled={isRunning}
                                    disabledTooltip={isRunning ? "A running plan can not be deleted." : ""}
                                />
                            )}
                        </Box>
                    </Box>
                </Paper>
            ) : (
                !loading &&
                results && (
                    <Box sx={{ backgroundColor: "#fff" }}>
                        <Box sx={summaryHeaderStyle}>
                            <Box>
                                <Typography>Plan Summary</Typography>
                            </Box>
                            <Box>
                                <Typography>Environment</Typography>
                            </Box>
                            <Box sx={dividedCell}>
                                <Typography>Status</Typography>
                            </Box>
                            <Box sx={dividedCell}>
                                <Typography>Browser</Typography>
                            </Box>
                            <Box sx={dividedCell}>
                                <Typography>Started Time</Typography>
                            </Box>
                            <Box sx={dividedCell}>
                                <Typography>Runtime</Typography>
                            </Box>
                        </Box>
                        {testRunId && (
                            <div>
                                <Typography sx={{ textAlign: "center", p: "20px" }}>
                                    There is no result for current filter criteria.{" "}
                                </Typography>
                            </div>
                        )}
                    </Box>
                )
            )}
            <ListViewPage
                headers={[
                    <SearchInput
                        value={searchTestSummary}
                        onChange={handleSearchTestName}
                        label="Search Test Summary..."
                        variant="standard"
                        fullWidth
                        isLoading={isWaiting}
                    />,
                    <StatusFilter projectId={projectId} options={statusFilterOptions} />,
                    <BrowserFilter projectId={projectId} options={browserFilterOptions} />,
                    "Started Time",
                    "Run Time",
                ]}
                headerWidths={["50%", "15%", "15%", "15%", "5%"]}
                search={searchTestSummary}
                componentName="TestPlanResultSummaryPage"
                testRunResults={results}
                {...{ page, setPage, size, setSize, isLoading }}
            />
        </>
    )
}

export default PlanResultPage
