import { useState } from "react"
import Button from "@mui/material/Button"
import { Dialog, Box, Typography, Tooltip } from "@mui/material"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import DeleteIcon from "@mui/icons-material/Delete"
import { SxProps, Theme } from "@mui/system"

interface DeleteModalProps {
    handleDelete: () => void
    onOpen: () => void
    onClose: () => void
    disabled?: boolean
    name: string
}

const deleteButton: SxProps<Theme> = {
    backgroundColor: "primary.light",
    color: "white",
    "&:hover": {
        backgroundColor: "primary.light",
        color: "white",
    },
}

export default function ConfirmDelete({ handleDelete, disabled, onOpen, onClose }: DeleteModalProps) {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        onOpen()
        setOpen(true)
    }

    const handleClose = () => {
        onClose()
        setOpen(false)
    }

    return (
        <div>
            <Tooltip title={disabled ? "Cannot delete the project currently in use." : ""}>
                <Box>
                    <Button
                        variant="outlined"
                        style={{ minWidth: "auto", whiteSpace: "nowrap" }}
                        color="primary"
                        size="small"
                        disabled={disabled}
                        startIcon={<DeleteIcon />}
                        onClick={handleClickOpen}
                    >
                        DELETE
                    </Button>
                </Box>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ p: "20px 25px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            mb: "20px",
                        }}
                    >
                        <WarningAmberIcon sx={{ color: "orange" }} />
                        <Typography variant="h6" align="center" sx={{ color: "orange", fontWeight: 900, ml: "10px" }}>
                            Warning
                        </Typography>
                    </Box>
                    <DialogContentText>
                        The project along with its associated test plan, test cases, and results will be permanently
                        removed. This process cannot be undone. Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", marginTop: "20px", mb: "20px" }}>
                    <Button onClick={handleClose} variant="outlined">
                        CANCEL
                    </Button>
                    <Button
                        onClick={() => {
                            setOpen(false)
                            handleDelete()
                        }}
                        autoFocus
                        sx={deleteButton}
                    >
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
