import React, { useEffect } from "react"
import {
    styled,
    MenuItem,
    FormControl,
    FormGroup,
    FormHelperText,
    OutlinedInput,
    ListItemText,
    Checkbox,
    Stack,
    SxProps,
    Theme,
    Box,
    Button,
    Dialog,
    DialogContent,
    Tooltip,
    CircularProgress as Spinner,
} from "@mui/material"
import chromeIcon from "../images/chrome-icon.png"
import safariIcon from "../images/safari-icon.png"
import firefoxIcon from "../images/firefox-icon.png"
import edgeIcon from "../images/edge-icon.png"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueFormatterParams,
    getGridStringOperators,
    GridRenderCellParams,
} from "@mui/x-data-grid"
import { useGetProjectsQuery, useUpdatePlanCaseMutation } from "../store/api"
import { Project } from "../projects/project"
import { Environment } from "../settings/environment"
import { useGetTestCasesByProjectIdQuery } from "../store/api"
import { useAddPlanCaseMutation, useGetTenantMetadataQuery } from "../store/api"
import { appSelector, selectProjectById } from "../store/slices/app-slice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "../store/store"
import * as zod from "zod"
import { Form, Field, Formik, FormikConfig, type FormikProps } from "formik"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { SelectInput, TextInput, TimeInput, type SelectInputProps, type CheckboxInputProps } from "../components/form"
import { type PlanCase, scheduleDays } from "./plan-case"
import { LoadingButton } from "@mui/lab"
import { PageTitle } from "../components/PageTitle"
import { date, object, textEllipsis } from "../utils"
import { Browser } from "../constants/browserEnum"
import dayjs from "dayjs"
import { DateTime } from "luxon"

type ComponentName = "UpdatePlanModal" | "CreatePlanModal"

const getFormSchema = (planCases: PlanCase[], currentPlanId?: number) =>
    zod.object({
        name: zod
            .string({
                invalid_type_error: "Please provide Plan Name.",
                required_error: "Please provide Plan Name.",
            })
            .refine((name) => name.trim() !== "", {
                message: "Please provide Plan Name.",
            })
            .refine((name) => {
                const hasError = planCases.filter((plan) => plan.name === name && plan.id !== currentPlanId).length > 0

                return !hasError
            }, "Plan name for this project must be unique."),
        description: zod.string().optional(),
        projectId: zod.string(),
        browsers: zod.array(zod.string()).min(1, "Select at least one browser.").default([]),
        environment: zod.string(),
        url: zod.string(), // URL will have value if env. has value
        schedule: zod.array(zod.string()).min(1, "Select at least one date.").default([]),
        scheduleTime: zod
            .any()
            .refine((value) => value !== null, {
                message: "Please provide a time.",
            })
            .refine(
                (value) => {
                    const time = dayjs(value, { format: "HH:mm" })
                    return time.isValid()
                },
                {
                    message: "Please enter the time in hh:mm format.",
                },
            ),
        testCases: zod.array(zod.string()).min(1, "Select at least one test.").default([]),
        environmentId: zod.string(),
        scheduleTimeZone: zod.string(),
    })

type FormValues = ReturnType<typeof getFormSchema>["_output"]

const testsTableStyle: SxProps<Theme> = {
    "& > div:nth-of-type(1)": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& > div:nth-of-type(1):before": {
        content: '"Select Tests"',
        fontWeight: "600",
        marginLeft: "1rem",
    },
}

const upsertPlanFormStyle = {
    width: "80vw",
    maxWidth: 520,
    borderRadius: "8px",
    bgcolor: "background.paper",
    p: 4,
    "& .dayStyle > fieldset, & .timeStyle > div > fieldset": {
        border: "0",
        backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
    "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
    },
    "& .customHeaderStyle > .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer": {
        padding: "0",
    },
}

const browserCheckStyle: SxProps<Theme> = {
    "&.MuiFormGroup-root": {
        justifyContent: "space-between",
    },
    ".wrapper": {
        position: "relative",
        marginBottom: "10px",
    },
    ".wrapper .MuiCheckbox-root": {
        borderWidth: "1px",
        borderColor: "trustiinGrey.300",
        borderStyle: "solid",
        borderRadius: "4px",
        padding: "20px 30px 40px",
        minWidth: "82px",
        marginTop: "5px",
        marginBottom: "10px",
        "&.Mui-disabled": {
            filter: "grayscale(100%)",
        },
        "&:before": {
            content: '""',
            width: "5vw",
            height: "5vw",
            maxWidth: "30px",
            maxHeight: "30px",
            position: "absolute",
        },
        "&:after": {
            position: "absolute",
            bottom: "10px",
        },
    },
    ".chrome .MuiCheckbox-root": {
        "&:before": {
            background: "url(" + chromeIcon + ")",
            backgroundSize: "contain",
        },
        "&:after": {
            content: '"Chrome"',
        },
    },
    ".firefox .MuiCheckbox-root": {
        "&:before": {
            background: "url(" + firefoxIcon + ")",
            backgroundSize: "contain",
        },
        "&:after": {
            content: '"Firefox"',
        },
    },
    ".safari .MuiCheckbox-root": {
        "&:before": {
            background: "url(" + safariIcon + ")",
            backgroundSize: "contain",
        },
        "&:after": {
            content: '"Safari"',
        },
    },
    ".edge .MuiCheckbox-root": {
        "&:before": {
            background: "url(" + edgeIcon + ")",
            backgroundSize: "contain",
        },
        "&:after": {
            content: '"Edge"',
        },
    },
}

const ToggleTestsButton = styled(Button)({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    boxShadow: "none",
    textTransform: "none",
    fontWeight: "400",
    color: "#79797B",
    padding: "4px 0 5px 0",
    WebkitBoxShadow: "inset 0 -1px 0 0 rgb(0 0 0 / 42%)",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    backgroundColor: "white",
    borderRadius: "0",
    "&:hover": {
        backgroundColor: "white",
        WebkitBoxShadow: "inset 0 -2px 0 0 rgb(0 0 0 / 87%)",
    },
    "&.selectTestsOn": {
        WebkitBoxShadow: "none",
    },
})

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            {/* @ts-ignore */}
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    )
}

function projectsMenuItems(projects: Project[] | undefined) {
    if (!projects) {
        return null
    }

    return projects.map((project) => (
        <MenuItem key={project.id} value={project.id}>
            {project.name}
        </MenuItem>
    ))
}

function envsMenuItems(environments: Environment[]) {
    if (!environments || environments.length === 0) {
        return null
    }

    return environments.map((env) => (
        <MenuItem key={env.id} value={env.name}>
            {env.name}
        </MenuItem>
    ))
}

interface UpsertPlanFormProps {
    formikProps: FormikProps<FormValues>
    handleClose: () => void
    projectIdState: [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]
    environmentIdState: [string, React.Dispatch<React.SetStateAction<string>>]
    projects?: Project[]
    selectedProject: Project | undefined
    planCases?: PlanCase[]
    componentName: ComponentName
    shouldShowOnlyTestCases: boolean
    currentPlanDetail?: PlanCase
}

const UpsertPlanForm: React.FC<UpsertPlanFormProps> = ({
    formikProps,
    projectIdState: [projectId, setProjectId],
    environmentIdState: [, setEnvironmentId],
    handleClose,
    projects = [],
    selectedProject,
    planCases = [],
    componentName,
    shouldShowOnlyTestCases,
    currentPlanDetail,
}) => {
    const { values, setFieldValue, errors, touched, setFieldTouched } = formikProps

    const [selectTests, setSelectTests] = React.useState(!shouldShowOnlyTestCases)
    const [environments, setEnvironments] = React.useState<Environment[]>([])
    const [page, setPage] = React.useState(1)
    const [size, setSize] = React.useState(25)
    const { data: testCases, isLoading: isLoadingTestCases } = useGetTestCasesByProjectIdQuery(
        { projectId: projectId ?? "", search: "", page, size },
        { skip: !projectId },
    )

    const resetEnvironment = (_projectId?: string) => {
        const environments = projects?.find((project) => project.id === _projectId)?.environments

        if (componentName === "UpdatePlanModal") {
            // set plan case's environment name and url from PlanCaseInfo
            formikProps.setFieldValue("environment", currentPlanDetail?.environment.name)
            const env = environments?.find((e) => e.name === currentPlanDetail?.environment.name)
            formikProps.setFieldValue("environmentId", env?.id ?? "")
            formikProps.setFieldValue("url", currentPlanDetail?.environment.url)
        } else {
            // Set default env automatically
            formikProps.setFieldValue("environment", environments?.[0]?.name)
            formikProps.setFieldValue("environmentId", environments?.[0]?.id ?? "")
            formikProps.setFieldValue("url", environments?.[0]?.url)
        }
        setEnvironments(environments ?? [])
    }

    useEffect(() => {
        setSelectTests(shouldShowOnlyTestCases)
    }, [shouldShowOnlyTestCases])

    // Recheck if the name field is duplicate after project change
    useEffect(
        () => {
            formikProps.validateField("name")

            // Touch field to update error
            if (formikProps.values.name) {
                formikProps.setFieldTouched("name")
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [projectId, planCases.length],
    )

    useEffect(() => {
        if (componentName === "CreatePlanModal") {
            // For create plan modal, set the project to the current selected project
            setProjectId(selectedProject?.id ?? "")
            resetEnvironment(selectedProject?.id)
            formikProps.setFieldValue("testCases", [])
        } else {
            // Otherwise, set the project to the one pre-populated in the initialValues prop for Formik
            setProjectId(values.projectId)
            resetEnvironment(values.projectId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject?.id])

    // Select all schedule items if every day is selected (only "Every day" is stored in the array on the API)
    useEffect(() => {
        if (componentName === "UpdatePlanModal" && values.schedule.includes("Every day")) {
            formikProps.setFieldValue("schedule", scheduleDays)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleProjectChange: SelectInputProps["onChange"] = (event) => {
        formikProps.handleChange(event)

        const value = String(event.target.value)
        resetEnvironment(value)
        setProjectId(value)
        formikProps.setFieldValue("testCases", [])
    }

    const handleEnvironmentChange: SelectInputProps["onChange"] = (event) => {
        formikProps.handleChange(event)

        const value = String(event.target.value)
        const environment = environments.find((env) => env.name === value)

        if (environment) {
            formikProps.setFieldValue("environmentId", environment.id ?? "")
            formikProps.setFieldValue("url", environment.url ?? "")
        }
    }

    const handleScheduleChange: SelectInputProps["onChange"] = (event) => {
        const targetValue = event.target.value as string[]

        const hasEveryDay = targetValue.includes("Every day")
        const hasPrevEveryDay = values.schedule.includes("Every day")

        if (hasEveryDay && !hasPrevEveryDay) {
            // If "every day" is selected, and wasn't already selected, select all days
            formikProps.setFieldValue("schedule", scheduleDays)
        } else if (hasEveryDay && hasPrevEveryDay) {
            // If "every day" was already selected, and a day was deselected, deselect "every day" as well
            formikProps.setFieldValue(
                "schedule",
                targetValue.filter((day) => day !== "Every day"),
            )
        } else if (!hasEveryDay && hasPrevEveryDay) {
            // If "every day" was already selected, but is now deselected, deselect all days
            formikProps.setFieldValue("schedule", [])
        } else {
            formikProps.setFieldValue("schedule", targetValue)
        }
    }

    const handleBrowserChange: CheckboxInputProps["onChange"] = (event) => {
        const selectedBrowserName = event.target.value as Browser
        if (formikProps.values.browsers.includes(selectedBrowserName)) {
            const removedBrowserFilter = formikProps.values.browsers.filter(
                (browser) => browser !== selectedBrowserName,
            )
            formikProps.setFieldValue("browsers", removedBrowserFilter)
        } else {
            const existingBrowsers = formikProps.values.browsers
            if (existingBrowsers.length > 0) {
                const newBrowser = [...existingBrowsers, selectedBrowserName]
                formikProps.setFieldValue("browsers", newBrowser)
            } else {
                formikProps.setFieldValue("browsers", [selectedBrowserName])
            }
        }
    }

    const handleChangePage = (newPage: number) => {
        setPage(newPage + 1)
    }
    const handleChangeSize = (newSize: number) => {
        setSize(newSize)
        setPage(1)
    }

    const disableFilterArray = ["isEmpty", "isNotEmpty"]
    const filterOperations = getGridStringOperators().filter((operator) => !disableFilterArray.includes(operator.value))
    const columns: GridColDef[] = [
        {
            field: "testSummary",
            headerName: "Test Summary",
            sortable: false,
            flex: 1,
            headerClassName: "customHeaderStyle",
            filterOperators: filterOperations,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box title={params.value} sx={{ ...textEllipsis, maxWidth: "200px" }}>
                        {params.value}
                    </Box>
                )
            },
        },
        {
            type: "number",
            field: "createTimestamp",
            headerName: "Created Date",
            sortable: false,
            flex: 1,
            headerAlign: "right",
            headerClassName: "customHeaderStyle",
            valueFormatter: (params: GridValueFormatterParams) => date.stringify(Number(params.value)),
            filterable: false,
        },
    ]

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }
    const { data: tenantMetadata } = useGetTenantMetadataQuery({}, { refetchOnMountOrArgChange: true })

    const supportedBrowsers = ["chrome", "firefox"]
    const unsupportedBrowsers = ["safari", "edge"]

    const browserList = [
        ...new Set([...supportedBrowsers, ...unsupportedBrowsers, ...(tenantMetadata?.allowedBrowsers || [])]),
    ]

    return (
        <Form>
            {!selectTests && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".75rem" }}>
                    <TextInput
                        name="name"
                        fullWidth
                        id="plan-name"
                        label="Plan Name *"
                        variant="standard"
                        // disabled={componentName === "UpdatePlanModal"}
                    />
                    <TextInput
                        name="description"
                        multiline
                        variant="filled"
                        aria-label="Plan Description"
                        label="Description"
                        minRows={3}
                    />
                    <SelectInput
                        name="projectId"
                        disabled
                        formControlProps={{ variant: "standard", sx: { marginBottom: "10px" } }}
                        label="Project Name *"
                        onChange={handleProjectChange}
                        useFastField
                    >
                        {projectsMenuItems(projects)}
                    </SelectInput>
                    <FormControl component="fieldset" variant="standard" sx={{ width: "100%", marginTop: "0px" }}>
                        <FormHelperText
                            sx={{
                                marginTop: "1rem",
                                color:
                                    errors.browsers && errors.browsers.length > 0 && touched.browsers
                                        ? "error.main"
                                        : undefined,
                            }}
                        >
                            Browsers *
                        </FormHelperText>
                        <FormGroup aria-label="position" row sx={browserCheckStyle}>
                            {browserList.map((name) => (
                                <Tooltip
                                    key={name}
                                    title={
                                        !supportedBrowsers.includes(name)
                                            ? "This browser is not yet supported."
                                            : !tenantMetadata?.allowedBrowsers.includes(name)
                                            ? "Upgrade your plan to unlock this feature."
                                            : ""
                                    }
                                >
                                    <div className={`${name} wrapper`}>
                                        <Field
                                            as={Checkbox}
                                            name="browsers"
                                            value={name}
                                            key={name}
                                            inputProps={{ "aria-label": name }}
                                            checked={formikProps.values.browsers.includes(name as Browser)}
                                            disabled={
                                                !supportedBrowsers.includes(name) ||
                                                !tenantMetadata?.allowedBrowsers?.includes(name)
                                            }
                                            onChange={handleBrowserChange}
                                        />
                                    </div>
                                </Tooltip>
                            ))}
                        </FormGroup>
                        {errors.browsers && errors.browsers.length > 0 && touched.browsers && (
                            <FormHelperText sx={{ color: "error.main" }}>{errors.browsers}</FormHelperText>
                        )}
                    </FormControl>

                    <div>
                        <SelectInput
                            name="environment"
                            formControlProps={{ variant: "standard", sx: { marginBottom: "10px" } }}
                            label="Environment"
                            noFeedback
                            onChange={handleEnvironmentChange}
                            onBlur={(event) => {
                                formikProps.handleBlur(event)
                                setFieldTouched("url", true)
                            }}
                        >
                            {envsMenuItems(environments)}
                        </SelectInput>
                        <TextInput
                            name="url"
                            fullWidth
                            inputProps={{ readOnly: false }}
                            label="URL"
                            variant="standard"
                            value={values.url}
                            disabled={true}
                        />
                    </div>

                    <div>
                        <FormHelperText sx={{ margin: "0rem 0 0rem" }}>Schedule *</FormHelperText>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <SelectInput
                                name="schedule"
                                id="run-schedule"
                                multiple
                                input={<OutlinedInput label="Run" />}
                                renderValue={(selected) =>
                                    (selected as string[]).includes("Every day")
                                        ? "Every day"
                                        : (selected as string[]).join(", ")
                                }
                                MenuProps={MenuProps}
                                className="dayStyle"
                                useFastField
                                onChange={handleScheduleChange}
                            >
                                {scheduleDays.map((day) => (
                                    <MenuItem key={day} value={day}>
                                        <Checkbox checked={values.schedule.indexOf(day) > -1} value={day} />
                                        <ListItemText primary={day} />
                                    </MenuItem>
                                ))}
                            </SelectInput>
                            <TimeInput
                                name="scheduleTime"
                                sx={{ ml: 1, width: "100%" }}
                                InputLabelProps={{ shrink: true }}
                                className="timeStyle"
                            />
                        </Box>
                    </div>
                </Box>
            )}
            {!shouldShowOnlyTestCases && (
                <ToggleTestsButton
                    variant="text"
                    onClick={() => setSelectTests((prev) => !prev)}
                    disableRipple
                    className={selectTests ? "selectTestsOn" : "selectTestsOff"}
                    sx={{
                        marginTop: "10px",
                        color:
                            errors.testCases && errors.testCases.length > 0 && touched.testCases
                                ? "error.main"
                                : undefined,
                    }}
                >
                    Tests *{selectTests ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </ToggleTestsButton>
            )}
            {errors.testCases && errors.testCases.length > 0 && touched.testCases && (
                <FormHelperText sx={{ color: "error.main" }}>{errors.testCases}</FormHelperText>
            )}
            <div>
                {selectTests && (
                    <div style={{ height: 611, width: "100%" }}>
                        <DataGrid
                            rowsPerPageOptions={[25, 50, 100]}
                            sx={testsTableStyle}
                            rowCount={testCases?.totalSize ?? 0}
                            onPageChange={handleChangePage}
                            onPageSizeChange={handleChangeSize}
                            pagination
                            paginationMode="server"
                            keepNonExistentRowsSelected
                            pageSize={size}
                            page={page - 1}
                            components={{
                                Toolbar: CustomToolbar,
                                NoRowsOverlay: isLoadingTestCases
                                    ? () => (
                                          <Box
                                              sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  height: "100%",
                                              }}
                                          >
                                              <Spinner />
                                          </Box>
                                      )
                                    : undefined,
                            }}
                            checkboxSelection
                            rows={testCases?.content ?? []}
                            columns={columns}
                            onSelectionModelChange={(ids) => {
                                setFieldValue("testCases", ids)
                            }}
                            onCellFocusOut={() => {
                                setFieldTouched("testCases")
                            }}
                            selectionModel={isLoadingTestCases ? [] : values.testCases}
                            // display is none to hide a X sign which is very confusing
                            componentsProps={{
                                panel: {
                                    sx: {
                                        "& .MuiDataGrid-filterForm": {
                                            width: "453px",
                                            "& > div:first-of-type": {
                                                display: "none",
                                            },
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                )}
            </div>
            <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleClose}>
                    cancel
                </Button>
                <LoadingButton variant="contained" type="submit" loading={formikProps.isSubmitting}>
                    save
                </LoadingButton>
            </Stack>
        </Form>
    )
}

export interface UpsertPlanProps {
    open: boolean
    handleClose(): void
    addPlan?: ReturnType<typeof useAddPlanCaseMutation>[0]
    updatePlan?: ReturnType<typeof useUpdatePlanCaseMutation>[0]
    /** Data for pre-populating fields when updating plan */
    planData?: PlanCase
    componentName: ComponentName
    plans?: PlanCase[]
    projectState: [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]
    onSuccessfulSubmit?: () => void
    onlyTestCases?: boolean
    currentPlanDetail?: PlanCase
}

export const UpsertPlanModal: React.FunctionComponent<UpsertPlanProps> = ({
    open,
    handleClose,
    addPlan,
    updatePlan,
    planData,
    componentName,
    plans: planCases,
    projectState: [projectId, setProjectId],
    onSuccessfulSubmit,
    onlyTestCases: shouldShowOnlyTestCases = false,
    currentPlanDetail,
}) => {
    const { selectedProject } = useSelector(appSelector)
    const dispatch = useAppDispatch()
    const HTTP_DUPLICATE_STATUS = 409

    const { data: projectsData } = useGetProjectsQuery()
    const [environmentId, setEnvironmentId] = React.useState("")

    const projects = projectsData?.content

    const changeProject = (projectId?: string) => {
        dispatch(selectProjectById({ selectedProjectId: projectId, projects }))
    }

    const onSubmit: FormikConfig<FormValues>["onSubmit"] = async (formValues, formikHelpers) => {
        const luxonDateTime = DateTime.fromJSDate(formValues?.scheduleTime.toDate())
        const formattedScheduleTime = luxonDateTime.toFormat("HH:mm:ss")

        const response =
            componentName === "CreatePlanModal"
                ? await addPlan?.({
                      ...formValues,
                      scheduleTime: formattedScheduleTime,
                  })
                : await updatePlan?.({
                      ...formValues,
                      id: planData!.id.toString(),
                      scheduleTime: formattedScheduleTime,
                  })

        if (response && "error" in response) {
            const { status: errorStatus } = response.error as { status: number }

            if (errorStatus === HTTP_DUPLICATE_STATUS) {
                formikHelpers.setFieldError("name", "Project name must be unique")

                return
            }
        }

        onSuccessfulSubmit?.()
        changeProject(projectId)
        handleClose()
    }

    const environments = projects?.find((project) => project.id === projectId)?.environments
    const initialEnvironment = environments?.[0]?.name || ""
    const initialUrl = environments?.[0]?.url || ""
    const initialEnvironmentId = environments?.[0]?.id || ""

    const dateTime = DateTime.now()

    const timezoneName = dateTime.zoneName || "America/New_York"
    const initialValues: FormValues = {
        name: "",
        description: "",
        projectId: "",
        browsers: [],
        environment: initialEnvironment,
        url: initialUrl,
        schedule: [...scheduleDays],
        scheduleTime: dayjs(new Date()),
        testCases: [],
        environmentId: initialEnvironmentId,
        scheduleTimeZone: timezoneName,
    }

    const planDataScheduleTime = planData?.scheduleTime
        ? DateTime.fromFormat(planData?.scheduleTime, "HH:mm:ss")
        : DateTime.local()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogContent sx={upsertPlanFormStyle}>
                {componentName === "CreatePlanModal" ? (
                    <PageTitle text="New Plan" center={true} />
                ) : (
                    <PageTitle text={shouldShowOnlyTestCases ? "Edit Tests" : "Edit Plan"} center={true} />
                )}
                <Formik
                    onSubmit={onSubmit}
                    //@ts-ignore
                    initialValues={
                        planData
                            ? {
                                  ...initialValues,
                                  enabled: planData.enabled,
                                  projectId: planData.testProjectId.toString(),
                                  environment: planData.environment.name,
                                  testCases: planData.testCases.map((testCase) => testCase.id),
                                  ...object.pick(planData, "name", "description", "browsers", "schedule"),
                                  //@ts-ignore
                                  scheduleTime: dayjs(planDataScheduleTime?.toJSDate()),
                                  environmentId: planData.environment.id,
                                  scheduleTimeZone: timezoneName,
                              }
                            : { ...initialValues, projectId: selectedProject?.id ?? "" }
                    }
                    validationSchema={toFormikValidationSchema(getFormSchema(planCases ?? [], planData?.id))}
                >
                    {(formikProps) => {
                        return (
                            <UpsertPlanForm
                                {...{
                                    formikProps,
                                    projects,
                                    selectedProject,
                                    planCases,
                                    componentName,
                                    shouldShowOnlyTestCases,
                                }}
                                handleClose={handleClose}
                                projectIdState={[projectId, setProjectId]}
                                environmentIdState={[environmentId, setEnvironmentId]}
                                currentPlanDetail={planData}
                            />
                        )
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export { CreatePlanModal } from "./CreatePlanModal"

export default UpsertPlanModal
