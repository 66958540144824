enum RoutePaths {
    HOME = "/",
    TEST_CASES = "/v=/test-cases",
    TEST_DETAILS = "/v=/test-details",
    RESULTS = "/v=/results",
    RESULTS_DETAILS = "/v=/results/:planId/:testRunId/:testCaseId/:browser",
    PLAN_RESULTS = "/v=/results/:planId/:testRunId",
    TEST_RUN_RESULTS = "/v=/results/:testRunId",
    CREATE_PROJECT = "/v=/create-project",
    PLANS = "/v=/plans",
    PLAN_DETAILS = "/v=/plans/:id",
    SETTINGS = "/v=/settings",
    LOGIN = "/v=/login",
    SIGN_UP = "/v=/signup",
    FORGOT_PASSWORD = "/v=/forgot_password",
    PASSWORD_RESET = "/v=/password_reset",
    ACCEPT_INVITE = "/v=/accept_invite",
}

export default RoutePaths
