import { Chip } from "@mui/material"
import { Action, TestCase } from "../test-cases/test-case"
import { Browser } from "../constants/browserEnum"

export enum ResultStatus {
    Running = "RUNNING",
    Failed = "FAILED",
    PassedWithWarning = "PASSED_WITH_WARNING",
    Passed = "PASSED",
    FailedWithWarning = "FAILED_WITH_WARNING",
    Aborted = "ABORTED",
    Skipped = "SKIPPED",
    // WARN: CONFIRMED is not an actual status recognized by the API
    Confirmed = "CONFIRMED",
}

export interface TestSequence {
    testSequence: TestCase
    command: null
}

export interface TestResultDTO {
    resultStatus: ResultStatus
    browser: Browser
    startTime: number
    runTime: number
}

export interface TestRunDTO {
    testRunId: string
    testPlanId: string
    testPlanName: string
    environmentName: string
    testResult: TestResultDTO[]
}

export interface StepResult {
    testStep: Action
    result: ResultStatus
    error: string | null
    message: string | null
}

export interface TestCaseResultDTO<HasStepResults extends boolean = false> {
    testRunId: string
    baselineRunId: string
    testCaseId: string
    testCaseName: string
    startTime: number
    browser: Browser
    status: ResultStatus
    runTime: number
    stepResultList: HasStepResults extends true ? StepResult[] : null
}

export interface TestRunInterface {
    planCaseDetailsId: string
    testRunId: string
    placeCaseDetailsName: string
}

export const getColorFromStatus = (status?: ResultStatus): string => {
    switch (status) {
        case ResultStatus.Running:
        case ResultStatus.Passed:
            return "primary.main"
        case ResultStatus.PassedWithWarning:
        case ResultStatus.Aborted:
            return "warning.main"
        case ResultStatus.Failed:
        case ResultStatus.FailedWithWarning:
            return "error.main"
        case ResultStatus.Skipped:
        default:
            return "rgba(0, 0, 0, 0.38)"
    }
}

export const getChipFromResultStatus = (status: ResultStatus): React.ReactNode => {
    switch (status) {
        case ResultStatus.Running:
            return (
                <Chip
                    size="small"
                    variant="ghost"
                    color="primary"
                    label="Running"
                    sx={{ background: "none", "& .MuiChip-label": { px: 0 } }}
                />
            )
        case ResultStatus.PassedWithWarning:
            return <Chip size="small" variant="ghost" color="warning" label="Passed with warning" />
        case ResultStatus.Passed:
            return <Chip size="small" variant="ghost" color="primary" label="Passed" />
        case ResultStatus.Confirmed:
            return <Chip size="small" variant="ghost" color="primary" label="Confirmed" />
        case ResultStatus.Failed:
            return <Chip size="small" variant="ghost" color="error" label="Failed" />
        case ResultStatus.FailedWithWarning:
            return <Chip size="small" variant="ghost" color="error" label="Failed with warning" />
        case ResultStatus.Aborted:
            return <Chip size="small" variant="ghost" color="default" label="Aborted" />
        case ResultStatus.Skipped:
            return <Chip size="small" variant="ghost" color="default" label="Skipped" />
    }
}

export const ResultAlertMessages = {
    Completed: "Test plan {Test plan name} is completed. Please look at the results in Results view.",
}
