import {
    Box,
    Button,
    Breadcrumbs,
    CircularProgress as Spinner,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    SxProps,
    Theme,
    Typography,
    Stack,
    InputLabel,
    TextField,
    Divider,
    Badge,
} from "@mui/material"
import { AppLink } from "../components/AppLink"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import {
    useGetPlanCaseByIdQuery,
    useGetTestResultDetailsQuery,
    useGetTestStepImageMetadataQuery,
    useGetTestResultAutofixQuery,
} from "../store/api"
import {
    getChipFromResultStatus,
    ResultStatus,
    TestCaseResultDTO,
    getColorFromStatus,
    TestSequence,
    StepResult,
} from "./test-result"
import BrowserIcon from "../components/BrowserIcon"
import { Browser } from "../constants/browserEnum"
import { date, testStep, textEllipsis } from "../utils"

import { TestStepImage } from "../components/TestStepImage"
import { Action } from "../test-cases/test-case"
import RoutePaths from "../constants/Routes"

const headerStyle: SxProps<Theme> = {
    borderRadius: 2,
    borderWidth: 1,
    borderColor: "trustiinGrey.300",
    display: "flex",
    minWidth: 1000,

    p: 2,
    mt: 2,
    mb: 3,
    "& > .MuiBox-root": {
        mr: 5,
        "& > .MuiTypography-root": {
            mb: 1,
        },
    },
}

const badgeStyle: SxProps<Theme> = {
    marginRight: "1rem",
    "& .MuiBadge-colorInfo": {
        // We need to update the theme with this color, I have realized that theme colors are not being used
        // It should probably be a conversation worth talking about.
        backgroundColor: "#9e9e9e",
    },
}

type AutoFixComponentProps = {
    showRecommendation: string
    handleAcceptButton: (index: number, value: string) => () => void
    handleActiveButton: (index: string) => (event: React.MouseEvent<HTMLButtonElement>) => void
    index: number
    testResult?: TestCaseResultDTO<true>
    autofixRecommendation?: Action["element"]
}

const AutoFixComponent = ({
    showRecommendation,
    handleAcceptButton,
    handleActiveButton,
    index,
    testResult,
    autofixRecommendation,
}: AutoFixComponentProps): JSX.Element => {
    return (
        <Box sx={{ alignItems: "flex-start" }}>
            <Button
                id={String(index)}
                variant="outlined"
                startIcon={<EditIcon />}
                color="warning"
                sx={{ marginTop: "10px" }}
                onClick={handleActiveButton(index.toString())}
            >
                AutoFix
            </Button>

            {Number(showRecommendation) === index ? (
                <>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            marginTop: "10px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box width="100%">
                            <InputLabel>The original element</InputLabel>
                            <TextField
                                multiline
                                maxRows={11}
                                InputProps={{ sx: { width: "100%" } }}
                                sx={{
                                    width: "100%",
                                    alignItems: "flex-start",
                                }}
                                defaultValue={JSON.stringify(
                                    testResult?.stepResultList[index].testStep.element,
                                    null,
                                    2,
                                )}
                                minRows={10}
                                disabled
                            />
                        </Box>
                        <Box width="100%">
                            <InputLabel>The recommended element</InputLabel>
                            <TextField
                                multiline
                                maxRows={11}
                                InputProps={{ sx: { width: "100%" } }}
                                sx={{
                                    width: "100%",
                                    alignItems: "flex-start",
                                }}
                                minRows={10}
                                defaultValue={JSON.stringify(autofixRecommendation, null, 2)}
                                disabled
                            />
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            marginTop: "15px",
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Button
                            color="warning"
                            variant="outlined"
                            sx={{ marginRight: "20px" }}
                            onClick={handleAcceptButton(index, "decline")}
                        >
                            Decline
                        </Button>
                        <Button color="warning" variant="outlined" onClick={handleAcceptButton(index, "accept")}>
                            Accept
                        </Button>
                    </Stack>
                </>
            ) : null}
        </Box>
    )
}

function findByOrdinal(arr: StepResult[], ordinal: string): ResultStatus {
    for (const obj of arr) {
        if (obj.testStep && obj.testStep.ordinal === ordinal) {
            return obj.result
        }
        const nestedStepResultList = obj.testStep?.testSequence?.stepResultList
        if (Array.isArray(nestedStepResultList)) {
            const nestedResult = findByOrdinal(nestedStepResultList, ordinal)
            if (nestedResult) {
                return nestedResult
            }
        }
    }
    return "" as ResultStatus
}

export const PlanResultDetailsPage: React.FC = () => {
    const { planId, testRunId, testCaseId, browser } = useParams<{
        planId?: string
        testRunId: string
        testCaseId: string
        browser: Browser
    }>()
    const {
        data: testResultData,
        isLoading: isLoadingResult,
        refetch,
    } = useGetTestResultDetailsQuery({
        testRunId,
        testCaseId,
        browser,
    })

    // TODO: replace this with something from the API instead of a temporary mutation
    const [testResult, setTestResult] = React.useState<TestCaseResultDTO<true> | undefined>(testResultData)

    useEffect(() => {
        setTestResult(testResultData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingResult, testResultData])

    const { data: plan } = useGetPlanCaseByIdQuery(planId ?? "", { skip: !planId })

    const [showRecommendation, setShowRecommendation] = React.useState("-1")
    const [activeStep, setActiveStep] = React.useState<string>("1")

    const { data: image1Meta, error: image1MetaError } = useGetTestStepImageMetadataQuery(
        {
            testCaseId: testResult?.testCaseId,
            resultId: testResult?.baselineRunId,
            stepNumber: activeStep,
            browser: Browser.Chrome,
        },
        {
            skip: !testResult,
        },
    )

    const { data: image2Meta, error: image2MetaError } = useGetTestStepImageMetadataQuery(
        {
            testCaseId: testResult?.testCaseId,
            resultId: testResult?.testRunId,
            stepNumber: activeStep,
            browser: testResult?.browser as Browser,
        },
        {
            skip: !testResult,
        },
    )

    const { data: autofixData } = useGetTestResultAutofixQuery(
        {
            testCaseId: testResult?.testCaseId,
            resultId: testResult?.testRunId,
            stepNumber: activeStep,
            browser: testResult?.browser as Browser,
        },
        {
            skip:
                !testResult ||
                ![ResultStatus.PassedWithWarning, ResultStatus.FailedWithWarning].includes(
                    testResult.stepResultList[Number(activeStep)]?.result,
                ),
        },
    )

    const handleActiveStep = (ordinal: string) => () => {
        setActiveStep(ordinal)
        refetch()
    }

    const handleActiveButton = (index: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setShowRecommendation(index)
        setActiveStep(index)
        event.stopPropagation()
    }

    React.useEffect(() => {
        if (testResult?.stepResultList[0]) {
            if (!testResult?.stepResultList[0].testStep.command) {
                setActiveStep("1.1")
            } else {
                setActiveStep("1")
            }
        }
    }, [testResult?.stepResultList])

    const handleAcceptButton = (indexToChange: number, value: string) => () => {
        const newResult = testResult?.stepResultList?.map((testResult, index) => {
            if (indexToChange === index) {
                return {
                    ...testResult,
                    result: value === "accept" ? ResultStatus.Confirmed : ResultStatus.Failed,
                }
            }
            return testResult
        })
        setTestResult(
            testResult
                ? {
                      ...testResult,
                      stepResultList: newResult ?? [],
                  }
                : undefined,
        )
    }

    return (
        <>
            <Breadcrumbs sx={{ my: 1 }}>
                <AppLink underline="hover" to={RoutePaths.RESULTS}>
                    Results
                </AppLink>
                <AppLink underline="hover" to={`${RoutePaths.RESULTS}/${planId}/${testRunId}`}>
                    <Box title={plan?.name} sx={{ ...textEllipsis, maxWidth: "600px" }}>
                        {plan?.name ?? ". . ."}
                    </Box>
                </AppLink>
                <Typography title={testResult?.testCaseName} sx={{ ...textEllipsis, maxWidth: "300px" }}>
                    {testResult?.testCaseName}
                </Typography>
            </Breadcrumbs>
            {testResult ? (
                <>
                    <Paper sx={headerStyle}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography>Test Summary</Typography>
                            <Box title={testResult.testCaseName} sx={{ ...textEllipsis, maxWidth: "600px" }}>
                                {testResult.testCaseName}
                            </Box>
                        </Box>
                        <Box>
                            <Typography>Status</Typography>
                            {getChipFromResultStatus(testResult.status)}
                        </Box>
                        <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                        <Box>
                            <Typography>Environment</Typography>
                            {plan?.environment.name ?? "Unknown"}
                        </Box>
                        <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                        <Box>
                            <Typography>Browser</Typography>
                            <BrowserIcon icon={testResult.browser} sx={{ height: "25px" }} />
                        </Box>
                        <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                        <Box>
                            <Typography>Started Time</Typography>
                            {date.stringify(testResult.startTime)}
                        </Box>
                        <Divider sx={{ mr: 4, height: 50, my: "auto", borderWidth: 0.3 }} flexItem />
                        <Box>
                            <Typography>Run Time</Typography>
                            {testResult.runTime === undefined ? undefined : date.getDateFromSeconds(testResult.runTime)}
                        </Box>
                    </Paper>
                    <Paper
                        sx={{
                            bgcolor: "transparent",
                            border: 0,
                            p: 0,
                            display: "flex",
                            flexGrow: 1,
                            gap: 2,
                            height: "calc(100vh - 19rem)",
                        }}
                    >
                        <Box
                            sx={{
                                width: "33%",
                                bgcolor: "white",
                                p: 3,
                                m: 0,
                                borderRadius: 2,
                                border: 1,
                                borderColor: "trustiinGrey.300",
                                overflow: "scroll",
                            }}
                        >
                            <Typography component="h1" variant="body1" mb={1}>
                                STEPS
                            </Typography>
                            <Stepper nonLinear activeStep={Number(activeStep) - 1} orientation="vertical">
                                {testResult.stepResultList?.map((step, index) => {
                                    return step.testStep.command ? (
                                        <Step
                                            key={step.testStep.ordinal}
                                            onClick={handleActiveStep(step.testStep.ordinal)}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            <StepLabel>
                                                <Box display="flex" flexDirection="column" mt={step.message ? 3 : 0}>
                                                    <Box sx={{ mr: 1 }}>{getChipFromResultStatus(step.result)}</Box>
                                                    <div
                                                        style={{ fontSize: "1rem" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: testStep.getFriendlyDescription(step.testStep),
                                                        }}
                                                    />
                                                </Box>
                                                {!!step.message && (
                                                    <Typography color={getColorFromStatus(step.result)}>
                                                        {step.message}
                                                    </Typography>
                                                )}
                                            </StepLabel>
                                            <StepContent hidden={false}>
                                                {step.result === ResultStatus.PassedWithWarning ? (
                                                    <AutoFixComponent
                                                        showRecommendation={showRecommendation}
                                                        handleAcceptButton={handleAcceptButton}
                                                        handleActiveButton={handleActiveButton}
                                                        index={index}
                                                        testResult={testResult}
                                                        autofixRecommendation={autofixData}
                                                    />
                                                ) : null}
                                            </StepContent>
                                        </Step>
                                    ) : (
                                        <Step
                                            active={step.testStep.ordinal === activeStep.split(".")[0]}
                                            key={step.testStep.ordinal}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            <StepLabel
                                                sx={{
                                                    "& .MuiStepLabel-label": { display: "flex", alignItems: "center" },
                                                }}
                                            >
                                                <Box sx={{ mr: 1 }}>{getChipFromResultStatus(step.result)}</Box>
                                                <Typography fontSize={"1rem"}>
                                                    {" "}
                                                    {
                                                        (step.testStep as unknown as TestSequence).testSequence
                                                            .testSummary
                                                    }
                                                </Typography>
                                            </StepLabel>
                                            <Box ml="30px">
                                                <Stepper nonLinear orientation="vertical">
                                                    {(
                                                        step.testStep.testSequence as unknown as TestCaseResultDTO<true>
                                                    ).stepResultList.map((step) => {
                                                        const CustomStepIcon = () => {
                                                            return (
                                                                <Badge
                                                                    sx={badgeStyle}
                                                                    color={
                                                                        step.testStep.ordinal === activeStep
                                                                            ? "primary"
                                                                            : "info"
                                                                    }
                                                                    badgeContent={step.testStep.ordinal}
                                                                />
                                                            )
                                                        }
                                                        return (
                                                            <Step
                                                                onClick={handleActiveStep(step.testStep.ordinal)}
                                                                key={step.testStep.ordinal}
                                                                active={step.testStep.ordinal === activeStep}
                                                                sx={{ cursor: "pointer" }}
                                                            >
                                                                <StepLabel
                                                                    StepIconComponent={CustomStepIcon}
                                                                    sx={{
                                                                        "& .MuiStepLabel-label": {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        },
                                                                    }}
                                                                >
                                                                    {/* <StepLabel StepIconComponent={CustomStepIcon}> */}
                                                                    <Box sx={{ mr: 1 }}>
                                                                        {getChipFromResultStatus(step.result)}
                                                                    </Box>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: testStep.getFriendlyDescription(
                                                                                step.testStep as Action,
                                                                            ),
                                                                        }}
                                                                    />
                                                                </StepLabel>
                                                                {!!step.message && (
                                                                    <Typography color={getColorFromStatus(step.result)}>
                                                                        {step.message}
                                                                    </Typography>
                                                                )}
                                                            </Step>
                                                        )
                                                    })}
                                                </Stepper>
                                            </Box>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </Box>
                        {/* <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                            sx={{ borderWidth: "inherit", borderColor: "trustiinGrey.300" }}
                        /> */}
                        <Box
                            sx={{
                                width: "67%",
                                bgcolor: "white",
                                flexDirection: "column",
                                display: "flex",
                                border: 1,
                                borderColor: "trustiinGrey.300",
                                borderRadius: 2,
                                p: 3,
                                gap: 1,
                            }}
                        >
                            <Box sx={{ width: "100%", gap: 3, display: "flex" }}>
                                <Box
                                    sx={{ width: "48%", display: "flex", alignItems: "start", flexDirection: "column" }}
                                >
                                    <Typography component="h1" variant="body1">
                                        Baseline Image:
                                    </Typography>
                                    <TestStepImage
                                        componentName="BaseLine"
                                        testCaseId={testResult.testCaseId}
                                        resultId={testResult.baselineRunId}
                                        stepNumber={activeStep}
                                        doubleHeight
                                        metadata={image1MetaError ? undefined : image1Meta}
                                        browser={testResult?.browser as Browser}
                                        stepResult={findByOrdinal(testResult.stepResultList, activeStep)}
                                    />
                                </Box>
                                <Box
                                    sx={{ width: "48%", display: "flex", alignItems: "start", flexDirection: "column" }}
                                >
                                    <Typography component="h1" variant="body1">
                                        Test Run Image:
                                    </Typography>
                                    <TestStepImage
                                        componentName="TestResult"
                                        testCaseId={testResult.testCaseId}
                                        resultId={testResult.testRunId}
                                        stepNumber={activeStep}
                                        doubleHeight
                                        metadata={image2MetaError ? undefined : image2Meta}
                                        browser={testResult?.browser as Browser}
                                        stepResult={findByOrdinal(testResult.stepResultList, activeStep)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </>
            ) : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <Spinner />
                </Box>
            )}
        </>
    )
}

export default PlanResultDetailsPage
