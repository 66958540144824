import React, { useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"
import { appSelector } from "../../store/slices/app-slice"
import { Box } from "@mui/material"
import ListViewPage from "../../components/ListViewPage"
import { useGetTestRunsByFilterQuery } from "../../store/api"
import {
    EnvironmentFilter,
    StatusFilter,
    StartedTimeFilter,
    StartedTimeOptions,
    DateRange,
    BrowserFilter,
    PlansFilter,
    statusFilterOptions,
} from "../filters"
import * as dateFns from "date-fns"
import { browserFilterOptions } from "../../constants/browserEnum"
import { useDebounce } from "../../hooks"
import { setItemInLocalStorage } from "../../utils/accessLocalStorage"
import { useQueryParams, useUrlString } from "../../utils/parseQuerySearch"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import LinearProgress from "@mui/material/LinearProgress"

export const ByPlan: React.FC = () => {
    const { selectedProject } = useSelector(appSelector)
    const history = useHistory()
    const location = useLocation()
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(25)
    const options = ["Today", "Yesterday", "Last 7 days", "Last 30 days", "Custom"]
    const envNames: string[] = useMemo(
        () => selectedProject!?.environments.map((environment) => environment?.name) || [],
        [selectedProject],
    )
    const [searchPlanName, setSearchPlanName] = useState("")
    const { value: debounceSearch, isWaiting } = useDebounce(searchPlanName)
    const [startedTime, setStartedTime] = useState<StartedTimeOptions>("Last 30 days")
    const { from, to, q } = queryString.parse(location.search)

    let start = new Date(from as string)! || new Date()
    let end = new Date(to as string)! || new Date()

    let startRange = start.toString() !== "Invalid Date" ? start : null
    let endRange = end.toString() !== "Invalid Date" ? end : null

    const [dateRange, setDateRange] = useState<DateRange>([startRange, endRange])
    const { environments, status, browsers } = useQueryParams()
    const updateUrlString = useUrlString()

    const handleSearchPlanName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPage(1)
        setItemInLocalStorage("query", updateUrlString({ q: event?.target?.value }) as string)
        history.replace(updateUrlString({ q: event?.target?.value }) || location.pathname)
        setSearchPlanName(event?.target?.value)
    }

    const getStartDateFromOption = (option: StartedTimeOptions, dateRange: DateRange): number => {
        switch (option) {
            case "Today":
                return dateFns.startOfDay(new Date()).getTime()
            case "Yesterday":
                return dateFns.startOfYesterday().getTime()
            case "Last 7 days":
                return dateFns.add(dateFns.startOfDay(new Date()), { days: -7 }).getTime()
            case "Last 30 days":
                return dateFns.add(dateFns.startOfDay(new Date()), { days: -30 }).getTime()
            case "Custom":
                return dateFns.startOfDay(dateRange[0] ?? new Date()).getTime()
            default:
                return dateFns.add(dateFns.startOfDay(new Date()), { days: -30 }).getTime()
        }
    }

    useEffect(() => {
        if (q) {
            setSearchPlanName(Array.isArray(q) ? q.join(", ") : q)
        }
        if (from) {
            const timeOption = (Array.isArray(from) ? from.join(", ") : from).replace(/_/g, " ")
            if (options.includes(timeOption)) {
                setStartedTime(timeOption as StartedTimeOptions)
            }
        }
        if (to && from) setStartedTime("Custom")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endRange, from, location.search, q, startRange, to])

    const {
        data: testResults,
        isUninitialized,
        isLoading,
        refetch,
    } = useGetTestRunsByFilterQuery(
        {
            projectId: selectedProject?.id ?? "",
            startDate: getStartDateFromOption(startedTime, dateRange),
            endDate: startedTime === "Custom" ? dateRange[1]?.getTime() : undefined,
            browsers,
            environments,
            search: debounceSearch,
            statuses: status,
            page,
            size,
        },
        {
            skip: !selectedProject,
            refetchOnMountOrArgChange: true,
        },
    )

    return (
        <>
            {isWaiting && (
                <LinearProgress sx={{ position: "absolute", top: 0, left: 0, zIndex: 1000, width: "100vw" }} />
            )}
            <Box sx={{ justifyContent: "center" }}>
                <ListViewPage
                    componentName="TestPlanResultsListPage"
                    headers={[
                        <PlansFilter
                            value={searchPlanName}
                            onPlanNameChange={handleSearchPlanName}
                            isLoading={isWaiting}
                        />,
                        <EnvironmentFilter
                            projectId={selectedProject?.id as string}
                            options={envNames}
                            setPage={setPage}
                        />,
                        <StatusFilter
                            options={statusFilterOptions}
                            projectId={selectedProject?.id as string}
                            setPage={setPage}
                        />,
                        <BrowserFilter
                            options={browserFilterOptions}
                            projectId={selectedProject?.id as string}
                            setPage={setPage}
                        />,
                        <StartedTimeFilter
                            startedTime={startedTime}
                            dateRange={dateRange}
                            projectId={selectedProject?.id as string}
                            setPage={setPage}
                            setDateRange={setDateRange}
                        />,
                        "Run Time",
                    ]}
                    headerWidths={["55%", "10%", "10%", "10%", "10%", "5%"]}
                    {...{ testResults, refetch, isLoading: isUninitialized || isLoading, page, setPage, size, setSize }}
                />
            </Box>
        </>
    )
}

export default ByPlan
