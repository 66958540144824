import * as React from "react"

import { Header } from "./components/Header"
import { Router } from "react-router-dom"
import * as z from "zod"
import { customErrorMap } from "./custom-error-map"
import { isRecorderEnabled } from "./utils/messaging"
import { Box, CssBaseline, SxProps, Theme, CircularProgress as Spinner } from "@mui/material"
import { InstallExtensionModal } from "./components/InstallExtensionModal"
import { theme } from "./theme"
import { ThemeProvider } from "@mui/material"
import { SideBar } from "./components/SideBar"
import { MainContent } from "./components/MainContent"
import { PopupError } from "./components/PopupError"
import { useAppDispatch } from "./store/store"
import { CreateProject } from "./projects/CreateProjectPage"
import { useGetProjectsQuery, useGetTenantMetadataQuery } from "./store/api"
import { createBrowserHistory } from "history"
import AppContextProvider from "./AppContextProvider"
import { appSelector, setAuthToken, setTenantMetaData } from "./store/slices/app-slice"
import { useSelector } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"

z.setErrorMap(customErrorMap)

export const history = createBrowserHistory()

const AppContent: React.FunctionComponent<{ recorderEnabled: boolean; isAuthenticated: boolean }> = (props) => {
    const { authToken } = useSelector(appSelector)

    const { data: projects, error: retrievalError } = useGetProjectsQuery(
        {},
        { skip: !props.isAuthenticated || !authToken },
    )

    const waitForInitialization = props.isAuthenticated && (!props.recorderEnabled || projects === undefined)
    const hasProjects = Boolean(projects && projects.content.length > 0)
    const hasInitializationError = retrievalError !== undefined

    if (!props.isAuthenticated) {
        return <MainContent />
    }

    return waitForInitialization ? (
        hasInitializationError ? null : (
            <MainContent />
        )
    ) : hasProjects ? (
        <MainContent />
    ) : (
        <CreateProject firstProject />
    )
}

export const App = () => {
    const isSignUpRoute = window.location.href.includes("/signup")

    const { recorderEnabled: recorderEnabledStatus } = useSelector(appSelector)
    const [installExtensionModalOpen, setInstallExtensionModalOpen] = React.useState<boolean>()
    const dispatch = useAppDispatch()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isLoading, error, user, loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently } = useAuth0()

    const { data: tenantMetadata } = useGetTenantMetadataQuery({}, { skip: !isAuthenticated })
    React.useEffect(() => {
        if (tenantMetadata) {
            dispatch(setTenantMetaData(tenantMetadata))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantMetadata])

    const { REACT_APP_BASE_API } = process.env
    React.useEffect(() => {
        const getToken = async () => {
            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `${REACT_APP_BASE_API}`,
                    },
                })
                dispatch(setAuthToken(accessToken))
            }
        }

        getToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    React.useEffect(() => {
        if (tenantMetadata) {
            dispatch(setTenantMetaData(tenantMetadata))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantMetadata])

    React.useEffect(() => {
        setTimeout(() => {
            // After half a second check if the extension version has been received from the extension.
            // If not received the extension has not been installed. Prompt user to install.

            setInstallExtensionModalOpen(!isRecorderEnabled())
        }, 500)
    }, [dispatch])

    React.useEffect(() => {
        const isOpen = !isRecorderEnabled() || !recorderEnabledStatus

        // If installExtensionModalOpen is undefined, it indicates that we should wait for the 500ms timeout above first
        if (installExtensionModalOpen !== undefined && isOpen !== installExtensionModalOpen) {
            setInstallExtensionModalOpen(isOpen)
        }
    }, [recorderEnabledStatus, installExtensionModalOpen])

    const appContainerStyle: SxProps<Theme> = {
        display: "flex",
        height: "100%",
    }
    if (isLoading) {
        return (
            <Box
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}
            >
                <Spinner />
            </Box>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <AppContextProvider>
                <CssBaseline />
                <Router history={history}>
                    <Box sx={appContainerStyle}>
                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, height: "100%" }}>
                            <>
                                {isAuthenticated && <Header />}
                                <Box sx={{ display: "flex", flexGrow: 1 }}>
                                    <SideBar />
                                    {(!installExtensionModalOpen || isSignUpRoute) && (
                                        <AppContent
                                            recorderEnabled={recorderEnabledStatus}
                                            isAuthenticated={isAuthenticated}
                                        />
                                    )}
                                    {(!installExtensionModalOpen || isSignUpRoute) && <PopupError />}
                                </Box>
                            </>
                        </Box>
                    </Box>
                </Router>
                {isAuthenticated && <InstallExtensionModal open={Boolean(installExtensionModalOpen)} />}
            </AppContextProvider>
        </ThemeProvider>
    )
}
