import { Dialog, DialogContent, Typography, Link, Button, Box } from "@mui/material"
import React from "react"
import { SxProps, Theme } from "@mui/system"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import RoutePaths from "../constants/Routes"

export interface InstallExtensionModalProps {
    open: boolean
}

export const InstallExtensionModal: React.FunctionComponent<InstallExtensionModalProps> = (props) => {
    const { open } = props

    const noExtensionInfo = {
        description:
            "Please install the TRUSTiiN Extension on your Chrome browser. Afterward, refresh this page to continue.",
        link: "https://chrome.google.com/webstore/detail/trustiin-agent/jfamabjgfpgnmepoihgmnogkkcokomob?utm_source=ext_app_menu",
    }

    const installButton: SxProps<Theme> = {
        backgroundColor: "primary.main",
        color: "white",
        "&:hover": {
            backgroundColor: "primary.main",
            color: "white",
        },
    }

    const refreshButton: SxProps<Theme> = {
        marginLeft: "5px",
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="extension-modal-title"
            aria-describedby="extension-modal-description"
            disableEscapeKeyDown={true}
        >
            <Box
                sx={{
                    padding: "30px",
                    maxWidth: "515px",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                    <WarningAmberIcon sx={{ color: "orange" }} />
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        align="center"
                        sx={{ color: "orange", fontWeight: 900, ml: "10px" }}
                    >
                        Warning
                    </Typography>
                </Box>

                <DialogContent sx={{ textAlign: "center", alignContent: "center", padding: "30px" }}>
                    <Typography id="extension-modal-description">{noExtensionInfo.description}</Typography>

                    <Box sx={{ margin: "20px 0", display: "flex", flexGap: "10px", justifyContent: "center" }}>
                        <Box>
                            <Link href={`${noExtensionInfo.link}`} target="_blank">
                                <Button sx={installButton} variant="outlined">
                                    INSTALL
                                </Button>
                            </Link>
                            <Button
                                sx={refreshButton}
                                variant="outlined"
                                onClick={() => {
                                    const currentURL = new URL(window.location.href)

                                    currentURL.pathname = RoutePaths.TEST_CASES
                                    window.location.href = currentURL.href
                                }}
                            >
                                REFRESH
                            </Button>
                        </Box>
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: "small", marginBottom: "10px" }}>
                            Your security is our top concern. We follow Chrome's Extension policies. The extension only
                            records tests with the Application Under Test (AUT) in your browser.
                        </Typography>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}
