import React from "react"
import { useSelector } from "react-redux"
import { useAddPlanCaseMutation } from "../store/api"
import { appSelector } from "../store/slices/app-slice"
import UpsertPlanModal from "./UpsertPlanModal"
import { PlanCase } from "./plan-case"

export interface CreatePlanModalProps {
    plans: PlanCase[]
    open: boolean
    handleClose(): void
}

export const CreatePlanModal: React.FC<CreatePlanModalProps> = ({ open, handleClose, plans }) => {
    const { selectedProject } = useSelector(appSelector)
    const [projectId, setProjectId] = React.useState(selectedProject?.id)
    const [addPlan] = useAddPlanCaseMutation()

    return (
        <UpsertPlanModal
            {...{ open, handleClose, addPlan, plans }}
            componentName="CreatePlanModal"
            projectState={[projectId, setProjectId]}
        />
    )
}
