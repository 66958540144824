import { useEffect } from "react"
import { usePusherNetwork } from "../AppContextProvider"
import { getItemFromLocalStorage, setItemInLocalStorage } from "../utils/accessLocalStorage"
import { PlanDeleteStatus } from "../pusher/pusher"
import RoutePaths from "../constants/Routes"
import { useHistory, useLocation } from "react-router-dom"

const usePusherSubscription = (onSuccess: (message: string) => void) => {
    const pusherNetwork = usePusherNetwork()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const subscriptionHandler = (event: PlanDeleteStatus) => {
            if (onSuccess) {
                const delItems: string | null = getItemFromLocalStorage("deletingPlans") ?? null
                if (delItems) {
                    const planName =
                        delItems?.length &&
                        JSON.parse(delItems)
                            .find((plan: string) => plan.match(event.plan_id))
                            ?.split("_")[1]
                    planName && onSuccess(`${planName} has been successfully deleted.`)
                    if (/v=\/plans\/[a-zA-Z0-9]+$/.test(location.pathname)) {
                        history.push(RoutePaths.PLANS)
                    }
                    setItemInLocalStorage(
                        "deletingPlans",
                        JSON.stringify(JSON.parse(delItems).filter((plan: string) => !plan.match(event.plan_id))),
                    )
                }
            }
        }
        if (pusherNetwork) {
            window.addEventListener("trustiinDelPlanEvent", subscriptionHandler as unknown as EventListener)
            return () =>
                window.removeEventListener("trustiinDelPlanEvent", subscriptionHandler as unknown as EventListener)
        }
    }, [pusherNetwork, location, history, onSuccess])
}
export default usePusherSubscription
