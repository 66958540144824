import { TextField, TextFieldProps } from "@mui/material"
import * as Formik from "formik"

export type TextInputProps = JSX.IntrinsicElements["input"] &
    Formik.FieldConfig<string> &
    TextFieldProps & {
        /**
         * Omit feedback text, but still turn red if error
         * @default false
         */
        noFeedback?: boolean
    }

export const TextInput = ({ noFeedback = false, ...props }: TextInputProps): JSX.Element => {
    const [field, meta] = Formik.useField(props)
    const errorText = meta.error && meta.touched ? meta.error : undefined

    return (
        <Formik.FastField
            {...field}
            {...props}
            as={TextField}
            error={Boolean(errorText)}
            helperText={noFeedback ? undefined : errorText}
        />
    )
}

export default TextInput
