import React from "react"
import qs from "query-string"
import { useHistory } from "react-router"

export type UseQueryReturn<T> = {
    /** Raw query string from the search bar */
    rawQuery: string
    setRawQuery: (query: string) => void
    /** Parsed query from the search bar */
    query: qs.ParsedQuery
    setQuery: (queryObject: qs.ParsedQuery) => void
    /** Value of specific param in query */
    value: T
    setValue: (value: T) => void
}

type UseQueryParam = {
    <T extends qs.ParsedQuery["string"] = string, D extends T = T>(param: string, defaultValue: D): UseQueryReturn<T>
    <T extends qs.ParsedQuery["string"] = string, D extends T | undefined = T>(
        param: string,
        defaultValue?: D,
    ): UseQueryReturn<T | undefined>
}

/**
 * Manipulate the browser's query string
 *
 * @param param - name of the query param
 * @returns object with query manipulation variables
 */
export const useQueryParam: UseQueryParam = <T extends qs.ParsedQuery["string"] = string, D extends T | undefined = T>(
    param: string,
    defaultValue?: D,
) => {
    const [rawQuery, setRawQuery] = React.useState(window.location.search)
    const [query, setQuery] = React.useState(qs.parse(window.location.search))
    const [value, setValue] = React.useState<T>((qs.parse(window.location.search)[param] ?? defaultValue) as T)
    const history = useHistory()

    const queryChangeHandler = () => {
        if (window.location.search !== rawQuery) {
            const parsed = qs.parse(window.location.search)

            setRawQuery(window.location.search)
            setQuery(parsed)
            setValue((parsed[param] ?? defaultValue) as T)
        }
    }

    React.useEffect(() => {
        const parsed = qs.parse(window.location.search)

        setRawQuery(window.location.search)
        setQuery(parsed)
        setValue((parsed[param] ?? defaultValue) as T)

        window.addEventListener("locationchange", queryChangeHandler)

        return () => {
            window.removeEventListener("locationchange", queryChangeHandler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param])

    const setRawQueryPublic = (queryString: string) => {
        history.replace(`${window.location.pathname}?${queryString}`)
    }

    const setQueryPublic = (queryObject: qs.ParsedQuery) => {
        history.replace(qs.stringifyUrl({ url: window.location.pathname, query: queryObject }))
    }

    const setValuePublic = (queryValue: T) => {
        setValue(queryValue)

        history.replace(
            qs.stringifyUrl({ url: window.location.pathname, query: { ...query, [param]: queryValue || undefined } }),
        )
    }

    return {
        rawQuery,
        setRawQuery: setRawQueryPublic,
        query,
        setQuery: setQueryPublic,
        value,
        setValue: setValuePublic,
    }
}

export default useQueryParam
