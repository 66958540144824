import { useEffect } from "react"
import { usePusherNetwork } from "../AppContextProvider"
import { getItemFromLocalStorage, setItemInLocalStorage } from "../utils/accessLocalStorage"
import { RunDeleteStatus } from "../pusher/pusher"
import RoutePaths from "../constants/Routes"
import { useHistory, useLocation } from "react-router-dom"

const usePusherDeleteRuns = (onSuccess: (message: string) => void) => {
    const pusherNetwork = usePusherNetwork()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const subscriptionHandler = (event: RunDeleteStatus) => {
            if (onSuccess) {
                const delItems: string | null = getItemFromLocalStorage("deletingRuns") ?? null
                if (delItems) {
                    onSuccess(`Test Run has been successfully deleted.`)
                    history.push(RoutePaths.RESULTS)
                    setItemInLocalStorage(
                        "deletingRuns",
                        JSON.stringify(JSON.parse(delItems).filter((run: string) => !run.match(event.test_run_id))),
                    )
                }
            }
        }
        if (pusherNetwork) {
            window.addEventListener("trustiinDelPlanResultsEvent", subscriptionHandler as unknown as EventListener)
            return () =>
                window.removeEventListener(
                    "trustiinDelPlanResultsEvent",
                    subscriptionHandler as unknown as EventListener,
                )
        }
    }, [pusherNetwork, location, history, onSuccess])
}
export default usePusherDeleteRuns
