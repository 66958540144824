import React from "react"
import { Modal, Stack, Typography, Button, Box } from "@mui/material"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import LoadingButton from "@mui/lab/LoadingButton"

interface WarningModalType {
    showRemoveEnvironmentWarning: boolean
    onNoClick: () => void
    onYesClick: () => void
    title: string
    text: string
}

const WarningModal = ({ showRemoveEnvironmentWarning, onNoClick, onYesClick, title, text }: WarningModalType) => {
    return (
        <Modal
            open={showRemoveEnvironmentWarning}
            onClose={onNoClick}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                    borderRadius: "10px",
                    width: 400,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    sx={{ marginLeft: "-20px" }}
                >
                    <WarningAmberIcon sx={{ color: "orange" }} />
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        align="center"
                        sx={{ color: "orange", fontWeight: 900 }}
                    >
                        {title}
                    </Typography>
                </Stack>
                <p id="parent-modal-description" style={{ color: "black" }}>
                    {text}
                </p>
                <div style={{ display: "inline", justifyContent: "center" }}>
                    <Button variant="outlined" sx={{ borderRadius: "5px", marginLeft: "100px" }} onClick={onYesClick}>
                        Yes
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={false}
                        sx={{ borderRadius: "5px", marginLeft: "20px" }}
                        onClick={onNoClick}
                    >
                        No
                    </LoadingButton>
                </div>
            </Box>
        </Modal>
    )
}
export default WarningModal
