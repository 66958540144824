import React from "react"
import {
    Box,
    Collapse,
    Grid,
    ListItem,
    Paper,
    Skeleton,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { useGetTestResultStatsQuery } from "../store/api"
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt"
import { useSelector } from "react-redux"
import { appSelector } from "../store/slices/app-slice"
import { BrowserIcon } from "./BrowserIcon"
import { addMonths, startOfMonth, subMinutes, format } from "date-fns"
import { DateTime } from "luxon"
import { textEllipsis } from "../utils/index"
import { theme } from "../theme"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Queue, ShoppingBag, Timer } from "@phosphor-icons/react"
import { v4 as keyGen } from "uuid"

interface rowData {
    date: string
    planName: string
    startedTime: string
    browsers: JSX.Element
    testCases: number
    executionTime: string
    runID: string
}
interface newData {
    date: string
    value: rowData[]
}

const secondsToHHMMSS = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const HH = String(hours).padStart(2, "0")
    const MM = String(minutes).padStart(2, "0")
    const ss = String(remainingSeconds).padStart(2, "0")

    return `${HH}:${MM}:${ss}`
}

// for collapised table row
function Row(props: { row: newData }) {
    const { row } = props
    const [open, setOpen] = React.useState(false)
    return (
        <Table sx={{ border: 0 }}>
            <TableBody>
                <TableRow>
                    <TableCell
                        style={{ border: 0, paddingTop: 8, paddingBottom: 8, paddingLeft: 0, paddingRight: 0 }}
                        colSpan={6}
                    >
                        <IconButton sx={{ position: "relative" }} onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        {row.date}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {row.value.map((rowItem) => (
                                <Table size="small" key={keyGen()}>
                                    <TableBody>
                                        <TableRow sx={headerStyle}>
                                            <TableCell />
                                            <TableCell align="left">
                                                <Typography sx={{ ...textEllipsis, width: "200px !important" }}>
                                                    {rowItem.planName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">{rowItem.startedTime}</TableCell>
                                            <TableCell align="left">{rowItem.browsers}</TableCell>
                                            <TableCell align="left">{rowItem.testCases}</TableCell>
                                            <TableCell align="left">{rowItem.executionTime}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default function UsageDataTable() {
    // get result data from api
    const { selectedProject } = useSelector(appSelector)

    // select year and month in date picker component
    const now = new Date(Date.now())
    const nowStart = new Date(now.getFullYear(), now.getMonth(), 1)
    const [startDate, setStartDate] = React.useState<Date>(nowStart)

    const startOfNextMonth = startOfMonth(addMonths(startDate as Date, 1))
    const endDate = subMinutes(startOfNextMonth, 1)

    const dateTime = DateTime.now()
    const timezoneName = dateTime.zoneName || "America/New_York"

    const {
        data: testResultStats,
        isLoading,
        isFetching,
        isUninitialized,
    } = useGetTestResultStatsQuery(
        {
            projectId: selectedProject?.id ?? "",
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            timezone: timezoneName,
            groupBy: "day",
        },
        { skip: !selectedProject?.id, refetchOnMountOrArgChange: true },
    )

    const handleChangeMonth = (date: Date | null) => {
        if (date instanceof Date) {
            setStartDate(date as Date)
        }
    }

    function sumExecutionTime(items: number[]) {
        const totalSeconds = items.reduce(function (total: number, ts: number) {
            return total + ts
        }, 0)

        return secondsToHHMMSS(totalSeconds)
    }

    const transformData = (): newData[] => {
        const transformedData: newData[] = []

        for (const day in testResultStats?.testResults) {
            const testResults = testResultStats?.testResults[day] || []
            const rowDataArray: rowData[] = []

            for (const result of testResults) {
                const { testRunId, testPlanName, startTime } = result
                const browsers = result.testResult.map(({ browser }) => (
                    <BrowserIcon
                        key={keyGen()}
                        icon={browser}
                        sx={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            mr: "5px",
                        }}
                    />
                ))
                const testCases = result.testCaseResults.length
                const executionTime = sumExecutionTime(result.testResult.map((row) => row.runTime))
                const runID = testRunId
                const startTimeDate = new Date(startTime)
                const formattedStartTime = format(startTimeDate, "HH:mm:ss")

                rowDataArray.push({
                    date: day,
                    planName: testPlanName,
                    startedTime: `${formattedStartTime}`,
                    //@ts-ignore
                    browsers,
                    testCases,
                    executionTime,
                    runID,
                })
            }

            transformedData.push({
                date: day,
                value: rowDataArray,
            })
        }

        return transformedData
    }

    let newData = transformData() || {}

    const subtotalTestRun = testResultStats?.totalTestRuns || 0
    const subtotalExecutionTime = secondsToHHMMSS(testResultStats?.totalRunTime || 0)
    const subtotalTestCase = testResultStats?.totalExecutedTestCases || 0

    const headerList = ["Date", "Plan Name", "Started Time", "Browsers", "Test Cases", "Execution Time"]
    return (
        <Grid container spacing={4}>
            <Grid item xs={3}>
                <Stack spacing={3}>
                    <ListItem sx={{ ...listItemStyle, paddingY: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={["year", "month"]}
                                minDate={new Date("2023-01-02")}
                                maxDate={new Date(nowStart.getFullYear(), 11)}
                                value={startDate!}
                                onChange={handleChangeMonth}
                                disableFuture={false}
                                renderInput={(params) => (
                                    <TextField {...params} sx={{ paddingX: "20px" }} variant="standard" fullWidth />
                                )}
                            />
                        </LocalizationProvider>
                    </ListItem>
                    <ListItem sx={listItemStyle}>
                        <Box sx={iconBoxStyle}>
                            <Queue size={30} style={{ color: "white" }} weight="light" />
                        </Box>
                        <Typography component={"div"} sx={{ ...textStyle, marginLeft: "auto", textAlign: "right" }}>
                            TEST RUNS
                            <Typography sx={{ fontWeight: "bold", paddingLeft: "70%" }}>{subtotalTestRun}</Typography>
                        </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle}>
                        <Box sx={iconBoxStyle}>
                            <ShoppingBag size={30} style={{ color: "white" }} weight="light" />
                        </Box>
                        <Typography component={"div"} sx={{ ...textStyle, marginLeft: "auto", textAlign: "right" }}>
                            TEST CASES
                            <Typography sx={{ fontWeight: "bold", paddingLeft: "70%" }}>{subtotalTestCase}</Typography>
                        </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle}>
                        <Box sx={iconBoxStyle}>
                            <Timer size={30} style={{ color: "white" }} weight="light" />
                        </Box>
                        <Typography component={"div"} sx={{ ...textStyle, marginLeft: "auto", textAlign: "right" }}>
                            EXECUTION TIME
                            <Typography sx={{ fontWeight: "bold", paddingLeft: "50%" }}>
                                {subtotalExecutionTime}
                            </Typography>
                        </Typography>
                    </ListItem>
                </Stack>
            </Grid>
            {/* below is table container */}
            <Grid item xs={9}>
                <Box
                    sx={{
                        backgroundColor: "trustiinGrey.601", //theme.palette.info.main,
                        paddingBottom: "12px",
                        position: "relative",
                        borderRadius: "5px 5px 0 0",
                    }}
                >
                    <Stack direction="row" sx={{ pt: 1 }} spacing={2}>
                        <Box>
                            <ViewQuiltIcon
                                fontSize="large"
                                sx={{ color: "white", position: "absolute", marginLeft: "15px" }}
                            />
                        </Box>
                        <Box sx={{ ml: "auto !important" }}>
                            <Typography
                                style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    paddingRight: "20px",
                                    paddingLeft: "auto",
                                    textAlign: "right",
                                    paddingTop: "5px",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                MONTHLY USAGE DETAILS
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <TableContainer
                    component={Paper}
                    sx={{
                        height: "47vh",
                        overflowY: "scroll",
                        overflowX: "scroll",
                    }}
                >
                    <Table sx={{ border: `solid 1px ${theme.palette?.trustiinGrey?.[300]}` }}>
                        <TableHead sx={headerStyle}>
                            <TableRow sx={{ border: `solid 1px ${theme.palette?.trustiinGrey?.[300]}` }}>
                                {headerList.map((headerName) => {
                                    return (
                                        <TableCell
                                            sx={{ minWidth: headerName === "Plan Name" ? 200 : "auto" }}
                                            key={keyGen()}
                                        >
                                            {headerName}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    </Table>
                    {isLoading || isFetching || isUninitialized ? (
                        <Table>
                            <TableBody>
                                {Array.from({ length: 6 }).map((__) => (
                                    <TableRow key={keyGen()}>
                                        <TableCell>
                                            <Box display="flex">
                                                <Skeleton height={25} width={20} />
                                                <Skeleton sx={{ ml: 1 }} height={25} width={150} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : newData.length > 0 ? (
                        newData.map((item: newData) => <Row key={keyGen()} row={item} />)
                    ) : (
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">There are no records for this month</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {/* below: bottom subtotal */}
                <Grid
                    container
                    sx={{
                        paddingY: "10px",
                        border: `solid 1px ${theme.palette?.trustiinGrey?.[300]}`,
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Box sx={{ ...tableFooterStyle, marginLeft: "5px" }}>
                            Test Runs:
                            <Typography sx={footerValueStyle}>{subtotalTestRun}</Typography>
                        </Box>
                        <Box sx={{ ...tableFooterStyle, marginLeft: "30px" }}>
                            Test Cases:
                            <Typography sx={footerValueStyle}>{subtotalTestCase}</Typography>
                        </Box>

                        <Box
                            sx={{
                                ...tableFooterStyle,
                                marginLeft: "30px",
                            }}
                        >
                            Duration:
                            <Typography sx={footerValueStyle}>{subtotalExecutionTime}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
const headerStyle: SxProps<Theme> = {
    border: `solid 1px ${theme.palette?.trustiinGrey?.[300]}`,
    "& :nth-of-type(1)": {
        width: "100px",
        whiteSpace: "nowrap",
    },
    "& :nth-of-type(2)": {
        width: "300px",
        whiteSpace: "nowrap",
    },
    "& :nth-of-type(3)": {
        width: "240px",
        whiteSpace: "nowrap",
    },
    "& :nth-of-type(4)": {
        width: "180px",
        whiteSpace: "nowrap",
    },
    "& :nth-of-type(5)": {
        width: "180px",
        whiteSpace: "nowrap",
    },
    "& :nth-of-type(6)": {
        width: "150px",
        whiteSpace: "nowrap",
    },
}

const textStyle: SxProps<Theme> = {
    color: theme.palette.primary.main,
    fontWeight: "bold",
}

const listItemStyle: SxProps = {
    paddingTop: "38px",
    backgroundColor: "white",
    border: `solid 1px ${theme.palette?.trustiinGrey?.[300]}`,
    borderColor: theme.palette.trustiinGrey?.[300],
    borderRadius: 2,
}

const iconBoxStyle: SxProps = {
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    transform: "translate(10px, -50px)",
    backgroundColor: "trustiinGrey.601", //theme.palette.info.main,
}

const tableFooterStyle: SxProps = {
    color: "#81BD41",
    whiteSpace: "nowrap",
    textAlign: "right",
    display: "inline",
}

const footerValueStyle: SxProps = {
    paddingLeft: "10px",
    display: "inline",
    fontWeight: "bold",
}
