import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    type SelectProps,
    type FormControlTypeMap,
} from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import * as Formik from "formik"

export type SelectInputProps = JSX.IntrinsicElements["select"] &
    Formik.FieldConfig<string> &
    SelectProps & {
        /**
         * Omit feedback text, but still turn red if error
         * @default false
         */
        noFeedback?: boolean
        /**
         * Use Formik `FastField` which implements `shouldComponentUpdate` to help relieve performance-related issues
         * Use if `children` do not change. Changes in `children` will not be detected.
         * @default false
         */
        useFastField?: boolean
        formControlProps?: DefaultComponentProps<FormControlTypeMap<{}, "div">>
        children: React.ReactNode
    }

export const SelectInput = ({
    children,
    label,
    formControlProps,
    noFeedback = false,
    useFastField: shouldUseFastField = false,
    ...props
}: SelectInputProps): JSX.Element => {
    const [field, meta] = Formik.useField(props)
    const errorText = meta.error && meta.touched ? meta.error : undefined

    const id = props.id ?? props.name
    const labelId = `${id}-label`

    const FieldComponent = shouldUseFastField ? Formik.FastField : Formik.Field

    return (
        <FormControl {...formControlProps} sx={{ width: "100%", ...formControlProps?.sx }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <FieldComponent {...field} {...props} as={Select} error={Boolean(errorText)} id={id}>
                {children}
            </FieldComponent>
            {typeof errorText === "string" && !noFeedback && (
                <FormHelperText sx={{ color: "error.main" }}>{errorText}</FormHelperText>
            )}
        </FormControl>
    )
}

export default SelectInput
