import React, { useEffect, useState } from "react"
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, FormControl, SxProps, Theme } from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FilterPropType } from "../types"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { setItemInLocalStorage } from "../utils/accessLocalStorage"
import { useUrlString } from "../utils/parseQuerySearch"
interface FilterPropTypes<T extends string, K extends string> extends FilterPropType<T, K> {
    label: string
    fullWidth?: boolean
    setPage?: React.Dispatch<React.SetStateAction<number>>
}

const MenuItemCss: SxProps<Theme> = {
    maxHeight: "40px",
    "div:nth-of-type(2)": {
        span: {
            color: "black",
        },
    },
}

/** Get the display names from the actual values in O(n) time */
const getDisplayFromActualValues = <T extends string, K extends string>(options: [K, T][], selected: T[]): K[] => {
    const nameMappings: Partial<Record<T, K>> = {}
    const names: K[] = []

    let index = 0

    for (const option of options) {
        if (nameMappings[selected[index]] !== undefined) {
            names.push(nameMappings[selected[index]]!)
            index++
        } else if (option[1] === selected[index]) {
            names.push(option[0])
            index++
        }

        nameMappings[option[1]] = option[0]
    }

    for (; index < selected.length; index++) {
        if (nameMappings[selected[index]] !== undefined) {
            names.push(nameMappings[selected[index]]!)
        }
    }

    return names
}

const Filter = <T extends string = string, K extends string = string>({
    projectId,
    label,
    options: rawOptions,
    fullWidth,
    setPage = () => {},
}: FilterPropTypes<T, K>): React.ReactElement => {
    const history = useHistory()
    const location = useLocation()
    const [selectedQ, setSelectedQ] = useState<string[]>([])
    const updateUrlString = useUrlString()

    const options: [display: K, actual: T][] =
        rawOptions[0] instanceof Array
            ? (rawOptions as [K, T][])
            : (rawOptions as T[]).map((option) => [option as unknown as K, option])

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setPage(1)
        const { value } = event?.target
        const stringValue = Array.isArray(value) ? value.join(",") : value
        const newKey = label.toLowerCase()
        history.replace(updateUrlString({ [newKey]: stringValue }) || location.pathname)
        setItemInLocalStorage("query", updateUrlString({ [newKey]: stringValue }))
    }

    useEffect(() => {
        const query = queryString.parse(location.search)
        const labelParam = query[label.toLowerCase()]
        if (typeof labelParam === "string") {
            const labelArray = labelParam.split(",").map((item) => item.trim())
            setSelectedQ(labelArray)
        } else {
            setSelectedQ([])
        }
    }, [label, location.search])

    return (
        <FormControl
            variant="standard"
            sx={{
                width: fullWidth ? "100%" : 185,
                marginRight: "10px",
            }}
        >
            <InputLabel id="multiple-select-label" sx={{ fontSize: "15px" }}>
                {label}
            </InputLabel>
            <Select
                id={projectId}
                labelId="multiple-select-label"
                multiple
                value={selectedQ}
                onChange={handleChange}
                variant="standard"
                renderValue={(selected) => getDisplayFromActualValues(options, selected).join(", ")}
                MenuProps={{ autoFocus: false }}
                sx={{ maxHeight: "50px" }}
            >
                {options.map((option) => (
                    <MenuItem key={option[1]} value={option[1]} sx={MenuItemCss}>
                        <ListItemIcon>
                            <Checkbox checked={selectedQ.indexOf(option[1]) > -1}></Checkbox>
                        </ListItemIcon>
                        <ListItemText primary={option[0]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default Filter
