import { useEffect } from "react"
import { Box, CircularProgress as Spinner } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"

const Auth0Login = () => {
    const { loginWithRedirect, error } = useAuth0()
    const handleLogin = async () => {
        try {
            console.log("Attempting login...")
            const res = await loginWithRedirect({
                appState: {
                    returnTo: "/test-cases",
                },
            })

            console.log(res, "-----res--->", error)
        } catch (error) {
            console.error("Login error:", error)
        }
    }

    useEffect(() => {
        handleLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <Spinner />
        </Box>
    )
}

export default Auth0Login
