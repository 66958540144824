import { Box, ListItem } from "@mui/material"
import { SxProps, Theme } from "@mui/system"
import React from "react"
import { AppLink } from "./AppLink"

export interface SideMenuItemProps {
    text: string
    icon: React.ReactNode
    route: string
    matchPath: boolean
}

export const SideMenuItem: React.FunctionComponent<SideMenuItemProps> = (props) => {
    const { matchPath, icon, text, route } = props

    const match = matchPath ? "palette.primary.main" : "info.main"

    const listStyle: SxProps<Theme> = {
        listStyleType: "none",
        padding: 0,
        paddingTop: "2rem",
        cursor: "pointer",
    }

    const linkStyle: SxProps<Theme> = {
        textDecoration: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: match,
    }

    const elementStyle: SxProps<Theme> = {
        width: "8px",
        height: matchPath ? "47px" : "0px",
        position: "absolute",
        left: "88px",
        borderRadius: "40px 0 0 40px",
        backgroundColor: "primary.main",
    }

    return (
        <ListItem sx={listStyle}>
            <Box sx={{ width: 92 }}>
                <AppLink to={route} sx={linkStyle}>
                    <Box sx={{ lineHeight: 1 }}>{icon}</Box>
                    <Box sx={{ fontSize: 13 }}>{text}</Box>
                </AppLink>
            </Box>
            <Box sx={elementStyle} />
        </ListItem>
    )
}
