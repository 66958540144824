import PusherClient, { Channel } from "pusher-js/with-encryption"
import { commons } from "../commons"

export interface PusherMetaData {
    appKey: string
    appCluster: string
    endpoint: string
    tenantId: string
    csrfAuthorization: string
}

export interface TestExecutionMetrics {
    total_tests: number
    total_runs: number
    passed_tests: number
    passed_warning_tests: number
    failed_tests: number
    failed_warning_tests: number
    skipped_tests: number
}

export interface TestExecutionStatus {
    test_run_id: string
    event_type: "completed" | "started" | "verified"
}

export interface ProjectDeleteStatus {
    project_id: string
    event_type: "completed" | "started" | "verified"
}

export interface PlanDeleteStatus {
    plan_id: string
    event_type: "completed"
}

export interface RunDeleteStatus {
    test_run_id: string
    event_type: "completed"
}

export default class PusherNetwork {
    private pusher: PusherClient

    constructor(pusherMetadata: PusherMetaData) {
        this.pusher = new PusherClient(pusherMetadata.appKey!, {
            cluster: pusherMetadata.appCluster!,
            forceTLS: true,
            authEndpoint: pusherMetadata.endpoint!,
            auth: {
                headers: { "X-CSRF-Token": pusherMetadata.csrfAuthorization },
                params: { tenant_id: pusherMetadata.tenantId },
            },
        })
        this.registerTestStatusHandler(pusherMetadata.tenantId, (pusherEvent) => {
            window.dispatchEvent(
                new CustomEvent("trustiinEvent", {
                    detail: pusherEvent,
                }),
            )
        })
        this.registerDeleteProjectStatusHandler(pusherMetadata.tenantId, (pusherEvent) => {
            window.dispatchEvent(
                new CustomEvent("trustiinDelEvent", {
                    detail: pusherEvent,
                }),
            )
        })
        this.registerDeletePlanStatusHandler(pusherMetadata.tenantId, (pusherEvent) => {
            window.dispatchEvent(
                new CustomEvent("trustiinDelPlanEvent", {
                    detail: pusherEvent,
                }),
            )
        })
        this.registerDeleteRunStatusHandler(pusherMetadata.tenantId, (pusherEvent) => {
            window.dispatchEvent(
                new CustomEvent("trustiinDelPlanResultsEvent", {
                    detail: pusherEvent,
                }),
            )
        })
    }

    private bindings: Map<string, { channel: Channel; events: Array<string> }> = new Map()

    private getChannelSubscription(tenantId: string): Channel {
        console.log("Incoming channel is ", +tenantId)
        var subscription = this.bindings.get(tenantId)?.channel
        if (subscription === undefined) {
            console.log("Subscribing to channel " + tenantId)
            subscription = this.pusher.subscribe(commons.PUSHER_CHANNEL_PREFIX + tenantId)
            this.bindings.set(tenantId, { channel: subscription, events: [] })
        }
        return subscription
    }

    private unsubscribeToChannel(tenantId: string) {
        const bindings = this.bindings.get(tenantId)?.events
        if (bindings !== undefined) {
            bindings.forEach((event) => this.pusher.unbind(event))
            this.bindings.delete(tenantId)
        }
        console.log("unsubscribing to channel " + tenantId)
        this.pusher.unsubscribe(commons.PUSHER_CHANNEL_PREFIX + tenantId)
    }

    private unregisterEventHandler(tenantId: string, event: string) {
        const subscription = this.bindings.get(tenantId)
        if (subscription !== undefined) {
            const remaining_events: string[] = []
            subscription.events.forEach((_event) => {
                if (_event === event) {
                    subscription.channel.unbind(event)
                } else {
                    remaining_events.push(event)
                }
            })
            subscription.events = remaining_events
            if (subscription.events.length === 0) {
                this.unsubscribeToChannel(tenantId)
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private registerEventHandler(tenantId: string, event: string, handler: (event_data: any) => void) {
        this.unregisterEventHandler(tenantId, event)
        this.getChannelSubscription(tenantId).bind(event, handler)
    }

    private registerTestMetricsHandler(tenantId: string, handler: (metric: unknown) => void) {
        this.registerEventHandler(tenantId, commons.TESTING_METRICS, handler)
    }

    private unregisterTestMetricsHandler(tenantId: string) {
        this.unregisterEventHandler(tenantId, commons.TESTING_METRICS)
    }
    private registerTestStatusHandler(tenantId: string, handler: (metric: TestExecutionStatus) => void) {
        this.registerEventHandler(tenantId, commons.TESTING_STATUS, handler)
    }

    private unregisterTestStatusHandler(tenantId: string) {
        this.unregisterEventHandler(tenantId, commons.TESTING_STATUS)
    }

    private registerDeletePlanStatusHandler(tenantId: string, handler: (metric: PlanDeleteStatus) => void) {
        const event: string = commons.DELETE_PLAN_STATUS
        const subscription: Channel = this.getChannelSubscription(tenantId)
        // Register event handler only if it's not already registered
        if (!this.bindings.get(tenantId)?.events.includes(event)) {
            subscription.bind(event, handler)
            this.bindings.set(tenantId, {
                channel: subscription,
                events: [...(this.bindings.get(tenantId)?.events || []), event],
            })
        }
    }

    private registerDeleteRunStatusHandler(tenantId: string, handler: (metric: RunDeleteStatus) => void) {
        const event: string = commons.DELETE_RUN_STATUS
        const subscription: Channel = this.getChannelSubscription(tenantId)
        // Register event handler only if it's not already registered
        if (!this.bindings.get(tenantId)?.events.includes(event)) {
            subscription.bind(event, handler)
            this.bindings.set(tenantId, {
                channel: subscription,
                events: [...(this.bindings.get(tenantId)?.events || []), event],
            })
        }
    }

    private registerDeleteProjectStatusHandler(tenantId: string, handler: (metric: ProjectDeleteStatus) => void) {
        const event: string = commons.DELETE_PROJECT_STATUS
        const subscription: Channel = this.getChannelSubscription(tenantId)

        // Register event handler only if it's not already registered
        if (!this.bindings.get(tenantId)?.events.includes(event)) {
            subscription.bind(event, handler)
            this.bindings.set(tenantId, {
                channel: subscription,
                events: [...(this.bindings.get(tenantId)?.events || []), event],
            })
        }
    }

    private unregisterDeleteProjectStatusHandler(tenantId: string) {
        this.unregisterEventHandler(tenantId, commons.DELETE_PROJECT_STATUS)
    }
}
