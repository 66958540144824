import { Action, CheckOptions } from "../test-cases/test-case"

export enum AssertionType {
    NOT_ENDS_WITH = "does-not-end-with",
    NOT_CONTAINS = "does-not-contain",
    NOT_EQUAL = "does-not-equal",
    NOT_PRESENT = "is-not-present",
    NOT_START_WITH = "does-not-start-with",

    ENDS_WITH = "ends-with",
    CONTAINS = "contains",
    EQUALS = "equals",
    PRESENT = "is-present",
    STARTS_WITH = "starts-with",
}

export const ASSERTION_TYPES = {
    [AssertionType.CONTAINS]: "contains",
    [AssertionType.NOT_ENDS_WITH]: "does not end with",
    [AssertionType.NOT_CONTAINS]: "does not contain",
    [AssertionType.NOT_EQUAL]: "does not equal",
    [AssertionType.NOT_START_WITH]: "does not start with",
    [AssertionType.ENDS_WITH]: "ends with",
    [AssertionType.EQUALS]: "equals",
    [AssertionType.NOT_PRESENT]: "is not present",
    [AssertionType.PRESENT]: "is present",
    [AssertionType.STARTS_WITH]: "starts with",
}

export enum RadioOptions {
    SELECTED = "is selected",
    NOT_SELECTED = "is not selected",
}

const capitalize = (arg: string) => {
    return arg.slice(0, 1).toUpperCase() + arg.slice(1)
}

function constrainTextLength(text: string) {
    if (!text) {
        return ""
    }
    if (text.length > 100) {
        return text.substring(0, 100) + "..."
    }

    return text
}
// TODO: This was copied from the recorder... we might need a small project to share methods like this and maybe some types?
/**
 * Gets a user friendly description of the action.
 *
 * @param action The action to generate a friendly description from.
 * @returns A user friendly description of the action to be displayed on the UI.
 */
export const getFriendlyDescription = (action: Action) => {
    // This is similar to getFriendlyDescription function in the recorder so they need to keep in sync
    if (action.locators?.length > 0) {
        let default_description: string = action.command
        const isCheckbox = action.element?.type === "checkbox"
        const isRadio = action.element?.type === "radio"
        const inclusiveElements = ["LABEL", "BUTTON", "TEXTAREA", "INPUT", "FORM"]

        if (action.command === "assert") {
            if ([AssertionType.PRESENT, AssertionType.NOT_PRESENT].includes(action?.assertion?.type as AssertionType)) {
                default_description =
                    `<span style="font-weight:bold">Assert ELEMENT</span> ` +
                    '"' +
                    (constrainTextLength(action?.element?.value || "") || action.element?.label || "") +
                    '"' +
                    ` <span style="font-weight:bold">${
                        ASSERTION_TYPES[action?.assertion?.type as AssertionType]
                    }</span>`
            } else {
                default_description =
                    `<span style="font-weight:bold">Assert TEXT OF ELEMENT</span> ` +
                    '"' +
                    constrainTextLength(action?.element?.value || "") +
                    '"' +
                    ` <span style="font-weight:bold">${
                        ASSERTION_TYPES[action?.assertion?.type as AssertionType]
                    }</span> ` +
                    '"' +
                    constrainTextLength((action?.assertion?.expected_value || "") as string) +
                    '"'
            }

            if (isCheckbox) {
                default_description = `<span style="font-weight:bold">Assert CHECKBOX</span>  ${
                    action.element?.label ? '"' + action.element?.label + '"' : ""
                }  ${
                    action.assertion?.expected_value
                        ? ` <span style="font-weight:bold">${CheckOptions.CHECKED}</span> `
                        : ` <span style="font-weight:bold">${CheckOptions.NOT_CHECKED}</span> `
                }`
            }

            if (isRadio) {
                default_description = `<span style="font-weight:bold">Assert RADIO</span>  ${
                    action.element?.label ? '"' + action.element?.label + '"' : ""
                }  ${
                    action.assertion?.expected_value
                        ? ` <span style="font-weight:bold">${RadioOptions.SELECTED}</span> `
                        : ` <span style="font-weight:bold">${RadioOptions.NOT_SELECTED}</span> `
                }`
            }

            return default_description
        } else {
            let default_description =
                `<span style="font-weight:bold">${capitalize(action.command)}</span>` +
                ` <span style="font-weight:bold">${
                    inclusiveElements.includes(action.element?.tag as string) ? action.element?.tag : " ELEMENT"
                }</span> ` +
                '"' +
                (constrainTextLength(action?.element?.value || "") ||
                    action.element?.label ||
                    "" ||
                    constrainTextLength(action.locators[0]?.target || "")) +
                '"'

            if (action.value) {
                let actionValue = constrainTextLength(action.value)
                let comparedKey = actionValue.substring(0, 6)
                if (comparedKey === "${KEY_") {
                    let resultActionValue = actionValue.replace(comparedKey, "Keys.")
                    resultActionValue = resultActionValue.substring(0, resultActionValue.length - 1)

                    default_description =
                        `<span style="font-weight:bold">${action.command.toUpperCase()}</span>` +
                        ` <span style="font-weight:bold">${
                            inclusiveElements.includes(action.element?.tag as string) ? action.element?.tag : " ELEMENT"
                        }</span> ` +
                        '"' +
                        constrainTextLength(action.element?.label || "") +
                        '"' +
                        ` <span style="font-weight:bold">with</span> ` +
                        '"' +
                        resultActionValue +
                        '"'
                } else {
                    if (action.element?.type === "password") {
                        actionValue = "*".repeat(action.value.length)
                    }
                    if (action.command === "type" && action.element?.label && actionValue && action.element?.tag) {
                        default_description =
                            `<span style="font-weight:bold">${capitalize(action.command)}</span> ` +
                            '"' +
                            actionValue +
                            '"' +
                            ` <span style="font-weight:bold">into ${action.element?.tag}</span> ` +
                            '"' +
                            action.element?.label +
                            '"'
                    } else {
                        default_description =
                            `<span style="font-weight:bold">${capitalize(action.command)}</span>` +
                            ` <span style="font-weight:bold">${
                                inclusiveElements.includes(action.element?.tag as string)
                                    ? action.element?.tag
                                    : " ELEMENT"
                            }</span> ` +
                            '"' +
                            actionValue +
                            '"'
                    }
                }
            }
            if (isCheckbox || isRadio) {
                default_description =
                    `<span style="font-weight:bold">${action.command.toUpperCase()}</span>` +
                    ` <span style="font-weight:bold">${(action.element?.type || "").toUpperCase()}</span> ` +
                    '"' +
                    constrainTextLength(action.element?.label || "") +
                    '"'
            }

            return default_description
        }
    }

    return action.command
}
