import React from "react"
import { Project } from "../projects/project"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { appSelector } from "../store/slices/app-slice"
import { useGetTestCasesByProjectIdQuery } from "../store/api"
import { SearchInput } from "../components/SearchInput"
import ListViewPage from "../components/ListViewPage"
import { useDebounce, useQueryParam } from "../hooks"
import LinearProgress from "@mui/material/LinearProgress"

function getSelectedProjectId(selectedProject: Project | undefined): string {
    if (selectedProject && selectedProject.id) {
        return selectedProject.id
    }
    return ""
}

export const TestCasesPage: React.FunctionComponent = () => {
    const { selectedProject } = useSelector(appSelector)
    const selectedProjectId = getSelectedProjectId(selectedProject)
    const { value: querySearch, setValue: setSearch } = useQueryParam("search", "")
    const { value: search, isWaiting } = useDebounce(querySearch)
    const [page, setPage] = React.useState(1)
    const [size, setSize] = React.useState(25)
    const {
        data: testCases,
        isLoading,
        isUninitialized,
        error: resError,
    } = useGetTestCasesByProjectIdQuery(
        {
            projectId: selectedProjectId,
            search,
            page,
            size,
        },
        { skip: !selectedProjectId, refetchOnMountOrArgChange: true },
    )

    const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setSearch(event.target.value)
        setPage(1)
    }

    return (
        <>
            {isWaiting && (
                <LinearProgress sx={{ position: "absolute", top: 0, left: 0, zIndex: 1000, width: "100vw" }} />
            )}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <Box sx={{ minWidth: "20rem", pl: 1 }}>
                    <SearchInput
                        label="Search Test Summary..."
                        isLoading={isUninitialized || isWaiting}
                        disabled={isUninitialized || isLoading}
                        value={querySearch}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <ListViewPage
                componentName="TestCasesPage"
                isLoading={isUninitialized || isLoading}
                error={resError}
                testCases={testCases}
                search={search}
                size={size}
                setPage={setPage}
                page={page}
                headers={["Test Summary", "Created By", "Last Update", "Test Sequence"]}
                setSize={setSize}
            />
        </>
    )
}
