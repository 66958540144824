import { TableBody, TableCell, TableRow, Skeleton, SxProps, Theme } from "@mui/material"
import { v4 as keyGen } from "uuid"

interface IProps {
    rowStyle: SxProps<Theme>
    componentName: string
}
const width = (base: number, add: number) => Math.floor(Math.random() * base) + add

const TestDetailsTableLoader = ({ rowStyle, componentName }: IProps) => {
    const getTableCellsWidith = () => {
        switch (componentName) {
            case "TestCasesPage":
                return [200, 180, 30, 30]
            case "UpdateProjectPage":
                return [160, 160, 50]
            case "TestPlanResultsListPage":
                return [110, 50, 150, 185, 130]
            case "TestPlanResultSummaryPage":
                return [50, 80, 170, 90]
        }
    }
    return (
        <>
            <TableBody>
                {Array.from({ length: 20 }).map((_, index) => (
                    <TableRow sx={rowStyle} key={keyGen()}>
                        <TableCell>
                            <Skeleton width={width(201, 100)} />
                        </TableCell>
                        {getTableCellsWidith()?.map((width: number) => (
                            <TableCell key={keyGen()}>
                                <Skeleton width={width} />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </>
    )
}

export default TestDetailsTableLoader
