import { TableBody, TableCell, TableRow, Skeleton, SxProps, Theme } from "@mui/material"

interface IProps {
    [key: string]: SxProps<Theme>
}
const width = (base: number, add: number) => Math.floor(Math.random() * base) + add

const SkeletonLoader = ({ planCaseRowStyle }: IProps) => {
    return (
        <>
            <TableBody>
                {Array.from({ length: 25 }).map((_, index) => (
                    <TableRow sx={planCaseRowStyle} key={index}>
                        <TableCell>
                            <Skeleton width={width(201, 100)} />
                        </TableCell>
                        <TableCell>
                            <Skeleton width={width(80, 20)} />
                        </TableCell>
                        <TableCell>
                            <Skeleton width={width(120, 20)} />
                        </TableCell>
                        <TableCell>
                            <Skeleton width={80} />
                        </TableCell>
                        <TableCell>
                            <Skeleton width={70} />
                        </TableCell>
                        <TableCell>
                            <Skeleton width={70} />
                        </TableCell>
                        <TableCell>
                            <Skeleton width={70} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </>
    )
}

export default SkeletonLoader
