import { Box, Theme } from "@mui/material"
import { SxProps } from "@mui/system"
import { useState } from "react"
import { SideMenuItem } from "./SideMenuItem"
import { useLocation } from "react-router-dom"
import SignOut from "../../src/components/sign-out/SignOut"
import Avatar from "@mui/material/Avatar"
import RoutePaths from "../constants/Routes"
import { AppLink } from "./AppLink"
import logo from "../images/logo.png"
import { CheckSquareOffset, ListChecks, Notepad, Gear } from "@phosphor-icons/react"
import { useAuth0 } from "@auth0/auth0-react"
import { stringAvatar } from "../utils/string"

export const SideBar = () => {
    const [showSignOutModal, setSignOutModal] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user, isAuthenticated } = useAuth0()

    const openModal = () => {
        setSignOutModal(true)
    }

    const closeModal = () => {
        setSignOutModal(false)
    }

    const logoStyle: SxProps<Theme> = {
        width: "100px",
        height: "74px",
        "& a": {
            textDecoration: "none",
            fontSize: 0,
            display: "block",
        },
        "& img": {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            width: "60px",
            height: "60px",
            display: "block",
        },
    }

    const containerStyle: SxProps<Theme> = {
        backgroundColor: "#fff",
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderRightColor: "trustiinGrey.300",
        flexBasis: "100px",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        width: 100,
        height: "100vh",
        top: 0,
        zIndex: 1001,
    }

    const avatarStyle: SxProps<Theme> = {
        backgroundColor: "#81BD41",
    }

    const listStyle = {
        padding: 0,
        margin: 0,
    }

    const location = useLocation()

    const { pathname } = location

    const splitPathname = pathname.split("/")
    const defaultSideBarItem = splitPathname[1] === ""

    return (
        <>
            {isAuthenticated ? (
                <Box sx={containerStyle}>
                    <Box sx={logoStyle}>
                        <AppLink to="/">
                            <img src={logo} alt="Trustiin Logo" />
                        </AppLink>
                    </Box>
                    <ul style={listStyle}>
                        <SideMenuItem
                            matchPath={
                                splitPathname[2] === "test-cases" ||
                                splitPathname[2] === "test-details" ||
                                defaultSideBarItem
                            }
                            text="Tests"
                            route={RoutePaths.TEST_CASES}
                            icon={<CheckSquareOffset size={28} weight="thin" />}
                        />
                    </ul>

                    <ul style={listStyle}>
                        <SideMenuItem
                            matchPath={splitPathname[2] === "plans"}
                            text="Plans"
                            route={RoutePaths.PLANS}
                            icon={<Notepad size={28} weight="thin" />}
                        />
                    </ul>

                    <ul style={listStyle}>
                        <SideMenuItem
                            matchPath={splitPathname[2] === "results"}
                            text="Results"
                            route={RoutePaths.RESULTS}
                            icon={<ListChecks size={28} weight="thin" />}
                        />
                    </ul>

                    <ul style={listStyle}>
                        <SideMenuItem
                            matchPath={splitPathname[2] === "settings"}
                            text="Settings"
                            route={RoutePaths.SETTINGS}
                            icon={<Gear size={28} weight="thin" />}
                        />
                    </ul>
                    <div style={{ display: "flex", flexGrow: "5", flexDirection: "column-reverse" }}>
                        <ul style={{ padding: "0px" }}>
                            <Avatar sx={avatarStyle} onClick={openModal}>
                                {stringAvatar(user?.email || "")}
                            </Avatar>
                        </ul>
                        {isAuthenticated && showSignOutModal && <SignOut closeModal={closeModal} />}
                    </div>
                </Box>
            ) : (
                <></>
            )}
        </>
    )
}
