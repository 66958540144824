import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { appSelector, clearPopupError } from "../store/slices/app-slice"
import { useAppDispatch } from "../store/store"

export const PopupError: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const { popupError, selectedProject } = useSelector(appSelector)
    const hasError = popupError.trim().length > 0
    const appInitialized = selectedProject !== undefined // TODO: Use a more explicit app state variable

    function closeError() {
        if (appInitialized) dispatch(clearPopupError())
    }

    return (
        <>
            {hasError && (
                <Dialog open={hasError} onClose={closeError} aria-describedby="error-description">
                    <DialogContent>
                        <Typography id="error-description">{popupError}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeError} disabled={!appInitialized} color="error">
                            Dismiss
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
