import "./index.css"
import * as React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { store } from "./store/store"

import { Auth0Provider } from "@auth0/auth0-react"

/* eslint-disable no-restricted-globals */
// Monkey patch pushState and replaceState functions to emit a custom locationchange event
history.pushState = ((func: typeof history.pushState) =>
    function pushState(this: unknown, ...args: Parameters<typeof history.pushState>) {
        const originalReturn = func.apply(this, args)

        window.dispatchEvent(new Event("locationchange"))

        return originalReturn
    })(history.pushState)

history.replaceState = ((func: typeof history.replaceState) =>
    function replaceState(this: unknown, ...args: Parameters<typeof history.replaceState>) {
        const originalReturn = func.apply(this, args)

        window.dispatchEvent(new Event("locationchange"))

        return originalReturn
    })(history.replaceState)
/* eslint-enable no-restricted-globals */

window.addEventListener("popstate", () => {
    window.dispatchEvent(new Event("locationchange"))
})
const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_BASE_API } = process.env

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Auth0Provider
                domain={REACT_APP_AUTH0_DOMAIN as string}
                clientId={REACT_APP_AUTH0_CLIENT_ID as string}
                authorizationParams={{
                    redirect_uri: `${window.location.origin}`,
                    audience: `${REACT_APP_BASE_API}`,
                }}
            >
                <App />
            </Auth0Provider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
