import { SxProps, Theme, Typography } from "@mui/material"
import React from "react"

export interface PageTitleProps {
    text: string
    center?: boolean
}

export const PageTitle: React.FunctionComponent<PageTitleProps> = (props) => {
    let style: SxProps<Theme> = {
        mb: 3,
        fontWeight: 700,
    }

    if (props.center) {
        style.textAlign = "center"
    }

    return (
        <Typography component="h1" variant="h5" sx={style}>
            {props.text}
        </Typography>
    )
}
