/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik, Form, Field, ErrorMessage } from "formik"
import {
    TextField,
    Button,
    Box,
    Typography,
    Link,
    Grid,
    Card,
    CardContent,
    Container,
    CssBaseline,
    Snackbar,
    Alert,
} from "@mui/material"
import { useHistory } from "react-router-dom"
import RoutePaths from "../../constants/Routes"
import trustiinLogo from "../../images/trustiinLogo.svg"
import { useUserSignupMutation } from "../../store/api"
import { styles } from "./authStyles"
import { useState, useEffect } from "react"

export default function SignUp() {
    const history = useHistory()
    const message = "An invitation link has been sent to your email, follow it to complete the sign up process."
    const invalidCodeMessage = "Invalid invitation code"

    const initialValues = {
        email: "",
        invitationCode: "",
    }
    const [serverError, setServerError] = useState("")

    const validateForm = (values: { email: string; invitationCode: string }) => {
        const errors: any = {}

        if (!values.email) {
            errors.email = "Email is required"
        } else if (!isValidEmail(values.email)) {
            errors.email = "Invalid email address"
        }

        if (!values.invitationCode) {
            errors.invitationCode = "Invitation code is required"
        } else if (!isValidInvitationCode(values.invitationCode)) {
            errors.invitationCode = invalidCodeMessage
        }

        return errors
    }

    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const isValidInvitationCode = (code: string) => {
        return /^org_[A-Za-z0-9]{16}$/.test(code)
    }

    const [userSignup, { isLoading, isSuccess, isError, error }] = useUserSignupMutation()

    useEffect(() => {
        if (error) {
            setServerError((error as any).data.message)
        }
    }, [error])

    const handleSubmit = async (
        values: { email: string; invitationCode: string },
        { setSubmitting, resetForm }: any,
    ) => {
        const requestBody = {
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
            email: values.email,
            invitationCode: values.invitationCode,
        }

        try {
            await userSignup(requestBody)
        } catch (error) {
            console.error("An error occurred:", error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Grid container sx={{ justifyContent: "center", m: "auto" }}>
            <Snackbar
                open={isSuccess}
                autoHideDuration={500000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="success">{message}</Alert>
            </Snackbar>

            <Card sx={styles.root}>
                <Box sx={styles.cardMedia}>
                    <Box sx={styles.logoContainer}>
                        <img alt="TRUSTiiN" width="250" src={trustiinLogo} title="TRUSTiiN" />
                    </Box>
                    <Typography style={styles.loginText}>Sign up</Typography>
                </Box>
                <CardContent>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box sx={styles.paper}>
                            <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
                                {(formData) => (
                                    <Form style={styles.form}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            size="small"
                                            sx={styles.inputs}
                                            onFocus={() => setServerError("")}
                                        />
                                        <ErrorMessage name="email" component="div" className="error-message" />

                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="invitationCode"
                                            label="Invitation Code"
                                            name="invitationCode"
                                            size="small"
                                            sx={styles.inputs}
                                            onFocus={() => setServerError("")}
                                        />
                                        <ErrorMessage name="invitationCode" component="div" className="error-message" />

                                        {isLoading && <Box sx={styles.loader} />}

                                        <Box sx={{ textAlign: "center", mt: 2 }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                sx={styles.submit}
                                                disabled={!formData.isValid}
                                            >
                                                Sign up
                                            </Button>
                                        </Box>

                                        <Box mt={2}>
                                            <Grid container>
                                                <Typography mr={2}>Already have an account?</Typography>
                                                <Link
                                                    href="#"
                                                    variant="body2"
                                                    sx={styles.footerLinks}
                                                    onClick={() => {
                                                        history.push(RoutePaths.LOGIN)
                                                    }}
                                                >
                                                    Sign In
                                                </Link>
                                            </Grid>
                                        </Box>

                                        {isError && error && serverError && (
                                            <Box mt={2}>
                                                <Typography sx={{ color: "red" }}>
                                                    {(error as any).status === 404
                                                        ? "Invalid invitation Code"
                                                        : serverError}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Container>
                </CardContent>
            </Card>
        </Grid>
    )
}
