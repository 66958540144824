import React, { useEffect } from "react"
import { IconButton, Menu, MenuItem, Checkbox, Typography, Box, SxProps, Theme } from "@mui/material"
import { MoreVert, DisabledByDefault } from "@mui/icons-material"
import { Browser, SkippedBrowsersOBj } from "../../constants/browserEnum"
import { useUpdateSkippedBrowsersMutation } from "../../store/api"
import { BrowserIcon } from "../../components/BrowserIcon"

export interface TestCaseMenuProps {
    initialBrowsers: Browser[]
    testCaseId: string
    planId: string
    planBrowsers: Browser[]
    setAllSkippedBrowsers: React.Dispatch<React.SetStateAction<SkippedBrowsersOBj>>
}

export const TestCaseMenu: React.FC<TestCaseMenuProps> = ({
    initialBrowsers,
    testCaseId,
    planId,
    planBrowsers,
    setAllSkippedBrowsers,
}) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
    const [skippedBrowsers, setSkippedBrowsers] = React.useState<Browser[]>(initialBrowsers)

    useEffect(() => {
        if (initialBrowsers.length) {
            setSkippedBrowsers(initialBrowsers)
        }
    }, [initialBrowsers])

    useEffect(() => {
        if (testCaseId) {
            setAllSkippedBrowsers((prev) => ({ ...prev, [testCaseId]: skippedBrowsers }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skippedBrowsers, testCaseId])

    const [updateSkippedBrowsers] = useUpdateSkippedBrowsersMutation()

    const toggleBrowser = (browser: Browser) => {
        const index: number = skippedBrowsers.indexOf(browser)
        if (index === -1) {
            setSkippedBrowsers([...skippedBrowsers, browser])
        } else {
            setSkippedBrowsers([...skippedBrowsers.slice(0, index), ...skippedBrowsers.slice(index + 1)])
        }
    }

    const toggleAll = () => {
        if (skippedBrowsers.length === 0) {
            setSkippedBrowsers(planBrowsers)
        } else {
            setSkippedBrowsers([])
        }
    }

    return (
        <>
            <Box sx={{ display: "flex", width: "145px" }}>
                {skippedBrowsers
                    .filter((browser) => planBrowsers.includes(browser))
                    .map((browser) => (
                        <Box sx={browserIconStyle} key={browser}>
                            <BrowserIcon icon={browser}></BrowserIcon>
                        </Box>
                    ))}
            </Box>
            <IconButton
                onClick={(e) => {
                    setAnchor(e.currentTarget)
                }}
            >
                <MoreVert />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => {
                    updateSkippedBrowsers({ testCaseId, planId, browsers: skippedBrowsers })
                    setAnchor(null)
                }}
                MenuListProps={{ dense: true }}
            >
                <Typography variant="subtitle2" sx={{ padding: "0 20px" }}>
                    Skip browsers
                </Typography>
                <MenuItem onClick={() => toggleAll()}>
                    <Checkbox
                        checked={
                            skippedBrowsers.filter((browser) => planBrowsers.includes(browser)).length ===
                            planBrowsers.length
                        }
                        checkedIcon={<DisabledByDefault />}
                        indeterminate={
                            skippedBrowsers.filter((browser) => planBrowsers.includes(browser)).length !==
                                planBrowsers.length &&
                            skippedBrowsers.filter((browser) => planBrowsers.includes(browser)).length !== 0
                        }
                        onChange={() => toggleAll()}
                        color={"error"}
                        sx={{ padding: "4px 2px" }}
                    />
                    All
                </MenuItem>
                {planBrowsers.map((browser) => (
                    <MenuItem key={browser} onClick={() => toggleBrowser(browser)} sx={{ textTransform: "capitalize" }}>
                        <Checkbox
                            checked={skippedBrowsers.includes(browser)}
                            onChange={() => toggleBrowser(browser)}
                            checkedIcon={<DisabledByDefault />}
                            color={"error"}
                            sx={{ padding: "1px 2px" }}
                        />
                        {browser}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

const browserIconStyle: SxProps<Theme> = {
    display: "flex",
    border: "2px solid",
    borderColor: "trustiinGrey.300",
    borderRadius: "4px",
    backgroundColor: "primary.contrastText",
    padding: "3px",
    height: "30px",
    width: "30px",
    margin: "1px",
}
