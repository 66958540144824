/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import {
    SxProps,
    Theme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Button,
    Typography,
    CircularProgress as Spinner,
    Skeleton,
} from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Grid from "@mui/material/Grid"
import DateRangeIcon from "@mui/icons-material/DateRange"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined"
import { CreatePlanModal } from "./UpsertPlanModal"
import { SearchInput } from "../components/SearchInput"
import { useSelector } from "react-redux"
import { appSelector } from "../store/slices/app-slice"
import { useGetPlanCasesByProjectIdQuery, useGetTenantMetadataQuery } from "../store/api"
import { history } from "../App"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import { Project } from "../projects/project"
import { getShortSchedule, PlanCase } from "./plan-case"
import { useDebounce, useQueryParam } from "../hooks"
import BrowserIcon from "../components/BrowserIcon"
import { getItemFromLocalStorage } from "../utils/accessLocalStorage"
import { TestRunInterface } from "../../src/test-results/test-result"
import RoutePaths from "../constants/Routes"
import SkeletonLoader from "../components/SkeltonTableLoader"
import NoPlansContent from "../components/NoPlansFound"
import { date } from "../utils"
import { CustomSwitch } from "../components/CustomSwitch"
import LinearProgress from "@mui/material/LinearProgress"
import Tooltip from "@mui/material/Tooltip"

function getSelectedProjectId(selectedProject: Project | undefined): string {
    if (selectedProject && selectedProject.id) {
        return selectedProject.id
    }
    return ""
}
interface PlanCasesPageProps {
    executeRun: (plan: PlanCase, value: boolean) => void
    handleAbortPlanCase: (id: string) => void
    startingRuns: string[]
}

export const PlanCasesPage = ({ executeRun, handleAbortPlanCase, startingRuns }: PlanCasesPageProps) => {
    const [open, setOpen] = React.useState(false)
    const { value: querySearch, setValue: setSearch } = useQueryParam("search", "")
    const { value: search, isWaiting } = useDebounce(querySearch)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const { selectedProject } = useSelector(appSelector)
    const selectedProjectId = getSelectedProjectId(selectedProject)
    const handleClick = (id: number) => {
        history.push(`${RoutePaths.PLANS}/${id}`)
    }
    const [page, setPage] = React.useState(1)
    const [size, setSize] = React.useState(25)

    const {
        data: planCases,
        isUninitialized,
        isLoading: loadingPlanCases,
        refetch: refetchPlanCases,
    } = useGetPlanCasesByProjectIdQuery(
        { projectId: selectedProjectId, search, page, size },
        { skip: !selectedProjectId, refetchOnMountOrArgChange: true },
    )
    const { data: tenantMetadata } = useGetTenantMetadataQuery({}, { refetchOnMountOrArgChange: true })

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage + 1)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSize(parseInt(event.target.value, 10))
        setPage(1)
    }
    const totalSize = planCases ? planCases.totalSize : 0

    const testRuns: TestRunInterface[] = JSON.parse(getItemFromLocalStorage("testRuns") || "[]")

    return (
        <>
            {isWaiting && (
                <LinearProgress sx={{ position: "absolute", top: 0, left: 0, zIndex: 1000, width: "100vw" }} />
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ minWidth: "20rem", px: 2, pb: 1 }}>
                    <SearchInput
                        label="Search Plan Name..."
                        isLoading={isWaiting}
                        value={querySearch}
                        onChange={(event: { target: { value: string } }) => {
                            setSearch(event.target.value)
                            setPage(1)
                        }}
                    />
                </Box>
                <Button
                    onClick={handleOpen}
                    variant="outlined"
                    sx={{ bgcolor: "common.white", marginBottom: "17px" }}
                    startIcon={<DateRangeIcon />}
                >
                    new plan
                </Button>
            </Box>
            <TableContainer sx={{ minWidth: 1000, paddingBottom: 5, marginBottom: "2rem" }}>
                <Table sx={planCaseTableStyle} className="planTableStyle">
                    <TableHead>
                        <TableRow sx={planCaseHeaderStyle}>
                            <TableCell>Plan Name</TableCell>
                            <TableCell>Browsers</TableCell>
                            <TableCell>Schedule</TableCell>
                            <TableCell>Environment</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Last Update</TableCell>
                            <TableCell>Enabled</TableCell>
                        </TableRow>
                    </TableHead>
                    {isUninitialized || loadingPlanCases ? (
                        <SkeletonLoader planCaseRowStyle={planCaseRowStyle} />
                    ) : (
                        <>
                            <TableBody>
                                {!planCases || planCases?.content.length === 0 ? (
                                    <TableRow sx={planCaseRowStyle} className="noRowStyle">
                                        <TableCell align="center" colSpan={12} sx={{ paddingBottom: "11px" }}>
                                            <Grid container justifyContent="center" spacing={0.5}>
                                                <Grid item>
                                                    {search.trim().length === 0 ? (
                                                        <NoPlansContent handleOpen={handleOpen} />
                                                    ) : (
                                                        <Typography variant="body2">
                                                            No test plans were found for the search "
                                                            <Box
                                                                component="span"
                                                                fontWeight="fontWeightBold"
                                                                color="primary.main"
                                                            >
                                                                {search}
                                                            </Box>
                                                            "
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    planCases.content.map((planCase) => {
                                        const isPlanRunning = testRuns.some(
                                            (run) => run.planCaseDetailsId === String(planCase?.id),
                                        )

                                        return (
                                            <TableRow
                                                key={planCase.id}
                                                sx={planCaseRowStyle}
                                                onClick={() => handleClick(planCase.id)}
                                                onKeyPress={(e) => e.key === "Enter" && handleClick(planCase.id)}
                                            >
                                                <TableCell title={planCase.name}>{planCase.name}</TableCell>
                                                <TableCell>
                                                    <Box sx={browserListStyle}>
                                                        {planCase?.browsers?.map((browser: string) => (
                                                            <Tooltip
                                                                key={browser}
                                                                title={
                                                                    !tenantMetadata?.allowedBrowsers.includes(browser)
                                                                        ? "Upgrade your plan to unlock this feature."
                                                                        : ""
                                                                }
                                                            >
                                                                <Box>
                                                                    <BrowserIcon
                                                                        icon={browser}
                                                                        key={browser}
                                                                        disabled={
                                                                            !tenantMetadata?.allowedBrowsers.includes(
                                                                                browser,
                                                                            )
                                                                        }
                                                                        sx={{ height: "20px" }}
                                                                    />
                                                                </Box>
                                                            </Tooltip>
                                                        ))}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{getShortSchedule(planCase.schedule)}</TableCell>
                                                <TableCell>{planCase.environment.name}</TableCell>

                                                <TableCell>{planCase.creator}</TableCell>
                                                <TableCell>{date.stringify(planCase.lastEditTimestamp)}</TableCell>
                                                <TableCell sx={{ lineHeight: "0" }}>
                                                    <CustomSwitch checked={planCase.enabled} />
                                                </TableCell>
                                                {/* Intensionally commented this out because it might be required in the near feature */}

                                                {/* <TableCell sx={{ lineHeight: "0" }}>
                                            {isPlanRunning ? (
                                                <>
                                                    {abortingRuns.includes(String(planCase.id)) ? (
                                                        <Spinner
                                                            size={"1rem"}
                                                            style={{ marginRight: "10px" }}
                                                            sx={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    ) : (
                                                        <PauseCircleOutlineOutlinedIcon
                                                            sx={{ color: "red" }}
                                                            onClick={(event) => {
                                                                event.stopPropagation()
                                                                handleAbortPlanCase(String(planCase.id))
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {startingRuns.includes(String(planCase?.id)) ? (
                                                        <Spinner size={"1rem"} style={{ marginRight: "10px" }} />
                                                    ) : (
                                                        <PlayCircleOutlineIcon
                                                            sx={{
                                                                color: !planCase.enabled
                                                                    ? "trustiinGrey.600"
                                                                    : "primary.main",
                                                            }}
                                                            onClick={(event) => {
                                                                event.stopPropagation()
                                                                executeRun(planCase, isPlanRunning)
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </TableCell> */}
                                            </TableRow>
                                        )
                                    })
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={12}
                                        count={totalSize}
                                        rowsPerPage={size}
                                        page={page - 1}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </>
                    )}
                </Table>
            </TableContainer>
            <CreatePlanModal {...{ open, handleClose, plans: planCases?.content || [] }} />
        </>
    )
}

const browserListStyle: SxProps<Theme> = {
    display: "flex",
    height: "20px",
    "& > *": {
        marginRight: "5px",
    },
}

const planCaseTableStyle: SxProps<Theme> = {
    borderCollapse: "separate",
    borderSpacing: "0 .5rem",
    "& .MuiTableFooter-root .MuiTableCell-root": {
        borderBottom: "none",
    },
    "&.planTableStyle": {
        tableLayout: "fixed",
    },
}

const planCaseBorderRadius = 5
const planCaseRowStyle: SxProps<Theme> = {
    borderWidth: 1,
    bgcolor: "common.white",
    "& td": {
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "trustiinGrey.300",
        borderLeftWidth: 0,
        borderRightWidth: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    "& :first-of-type": {
        borderTopLeftRadius: planCaseBorderRadius,
        borderBottomLeftRadius: planCaseBorderRadius,
        borderLeftWidth: 1,
    },

    "& :last-of-type": {
        borderTopRightRadius: planCaseBorderRadius,
        borderBottomRightRadius: planCaseBorderRadius,
        borderRightWidth: 1,
        overflow: "inherit",
    },
    "&:hover": {
        bgcolor: "white!important",
        cursor: "pointer",
        "& td": {
            borderColor: "primary.main",
        },
    },
    "&.noRowStyle:hover": {
        cursor: "default",
        "& td": {
            borderColor: "trustiinGrey.300",
        },
    },
}

const planCaseHeaderStyle: SxProps<Theme> = {
    "& th": {
        border: "none",
        py: 0,
    },
    "& :nth-of-type(1)": {
        width: "20%",
    },
    "& :nth-of-type(2)": {
        width: "9%",
    },
    "& :nth-of-type(3)": {
        width: "15%",
    },
    "& :nth-of-type(4)": {
        width: "12%",
    },
    "& :nth-of-type(5)": {
        width: "20%",
    },
    "& :nth-of-type(6)": {
        width: "20%",
    },
    "& :nth-of-type(7)": {
        width: "10%",
    },
}
